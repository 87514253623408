/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  NativeSelect,
  Pagination,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useForm } from "@mantine/form";
import {
  handleAddCoupons,
  handleDeleteCoupons,
  handleEditCoupons,
  handleGetCoupons,
  handleGetProduct,
  handleGetRegion,
} from "../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../utils/common/common";
import {
  IconFileExport,
  IconPencil,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { DatePickerInput } from "@mantine/dates";
import { CSVLink } from "react-csv";

function Coupon() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();
  const [product, setProduct] = useState([]);
  const [region, setRegion] = useState([]);

  // For form validation
  const form = useForm({
    initialValues: {
      productId: "1",
      regionId: "1",
      label: "",
      percentage: "",
      validFrom: new Date(),
      validTo: new Date(),
    },
    validate: {
      label: (value) =>
        value.length < 2 ? "Coupon must have at least 2 letters" : null,
    },
  });

  // For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: "1",
      regionId: "1",
      label: "",
      percentage: "",
      validFrom: new Date(),
      validTo: new Date(),
    },
    validate: {
      label: (value) =>
        value.length < 2 ? "Coupon must have at least 2 letters" : null,
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setLoadingData(true);
        const response = await handleGetCoupons();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setLoadingData(false);
          setData(datas);
        }
      }

      const response1 = await handleGetRegion();
      if (response1.status === 200) {
        var data = response1.data.data;
        var clean = data.map((data) => ({
          value: data.id.toString(),
          label: data.label.toString(),
        }));
        setRegion(clean);
      }

      const response2 = await handleGetProduct();
      if (response2.status === 200) {
        var data = response2.data.data;
        var clean = data.map((data) => ({
          value: data.id.toString(),
          label: data.label.toString(),
        }));
        setProduct(clean);
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={row.label}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{row.product.label}</td>
      <td>{row.percentage}</td>
      <td>{row.label}</td>
      <td>{row.validFrom}</td>
      <td>{row.validTo}</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <IconPencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add function
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddCoupons(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Coupon added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle open edit modal
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    formEdit.setFieldValue("value", datas.id);
    formEdit.setFieldValue("label", datas.label);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Handle update data function
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditCoupons(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Coupon updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the coupon <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteCoupons(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Coupon deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Coupons" />
      </Skeleton>
      <Grid>
        <Grid.Col md={12} lg={12}>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <TextInput
                  variant="filled"
                  placeholder="Search by any field"
                  mb="md"
                  size="xs"
                  value={search}
                  icon={<IconSearch size={14} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Group spacing="xs">
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    mr={10}
                    onClick={() =>
                      setVariables({
                        ...variables,
                        addDrawer: true,
                      })
                    }
                    leftIcon={<IconPlus size={12} />}
                  >
                    Add Coupon
                  </Button>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "product", key: "product.label" },
                      { label: "region", key: "region.label" },
                      { label: "coupon", key: "label" },
                      { label: "percentage", key: "percentage" },
                      { label: "valid from", key: "validFrom" },
                      { label: "valid to", key: "validTo" },
                    ]}
                    filename="coupons.csv"
                  >
                    <Button
                      variant="outline"
                      color="zevcore"
                      size="xs"
                      leftIcon={<IconFileExport size={12} />}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Group>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Product</th>
                      <th>Coupon(%)</th>
                      <th>Coupon Code</th>
                      <th>Valid From</th>
                      <th>Valid To</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      setData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Skeleton>
        </Grid.Col>
      </Grid>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add Coupon"}
        size="xl"
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Product"
                    withAsterisk
                    data={product}
                    value={form.values.productId}
                    placeholder="Select Product"
                    {...form.getInputProps("productId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Region"
                    withAsterisk
                    data={region}
                    value={form.values.regionId}
                    placeholder="Select Region"
                    {...form.getInputProps("regionId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Coupon"
                    withAsterisk
                    value={form.values.label}
                    placeholder="Coupon"
                    {...form.getInputProps("label")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Coupon Percentage"
                    withAsterisk
                    value={form.values.percentage}
                    placeholder="Coupon Percentage"
                    {...form.getInputProps("percentage")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    label="Valid From"
                    withAsterisk
                    value={form.values.validFrom}
                    placeholder="Valid From"
                    {...form.getInputProps("validFrom")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    label="Valid To"
                    withAsterisk
                    value={form.values.validTo}
                    placeholder="Valid To"
                    {...form.getInputProps("validTo")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="#A61E4D"
                  >
                    Add Coupon
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Coupon Details"}
        size="xl"
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="Select Product"
                  withAsterisk
                  data={product}
                  value={formEdit.values.productId}
                  placeholder="Select Product"
                  {...formEdit.getInputProps("productId")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="Select Region"
                  withAsterisk
                  data={region}
                  value={formEdit.values.regionId}
                  placeholder="Select Region"
                  {...formEdit.getInputProps("regionId")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Coupon"
                  withAsterisk
                  value={formEdit.values.label}
                  placeholder="Coupon"
                  {...formEdit.getInputProps("label")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Coupon Percentage"
                  withAsterisk
                  value={formEdit.values.percentage}
                  placeholder="Coupon Percentage"
                  {...formEdit.getInputProps("percentage")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <DatePickerInput
                  label="Valid From"
                  withAsterisk
                  value={formEdit.values.validFrom}
                  placeholder="Valid From"
                  {...formEdit.getInputProps("validFrom")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <DatePickerInput
                  label="Valid To"
                  withAsterisk
                  value={formEdit.values.validTo}
                  placeholder="Valid To"
                  {...formEdit.getInputProps("validTo")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="#A61E4D"
                >
                  Update Coupon
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}
    </>
  );
}

export default Coupon;
