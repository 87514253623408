/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Card,
  Grid,
  Group,
  Loader,
  NativeSelect,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
} from "@mantine/core";
import { handleGetDashboard } from "../../../utils/helpers/apiFunctions";
import { dataSlice } from "../../../utils/common/common";
import {
  IconEdit,
  IconPackages,
  IconPencil,
  IconPhoto,
  IconTrash,
} from "@tabler/icons-react";
import { IMAGE_URL_CONSTANTS } from "../../../utils/constants/constant";

function Dashboard() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
  });
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalCategory, setTotalCategory] = useState(0);
  const [totalType, setTotalType] = useState(0);
  const [refreshTable, setRefreshTable] = useState(Date.now());

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setLoadingData(true);
        const response = await handleGetDashboard();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data[0],
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data[0],
            page: 1,
            total: 10,
          });
          setTotalProduct(response.data.data[1]);
          setTotalCategory(response.data.data[2]);
          setTotalType(response.data.data[3]);
          setLoadingData(false);
          setData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.featuredImage !== "" ? (
            <Avatar
              color="#A61E4D"
              src={IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + row.featuredImage}
              radius={30}
              size={50}
            />
          ) : (
            <Avatar color="#A61E4D" radius={30} size={50} />
          )}
          <div>
            <Text fz="sm" fw={500}>
              <Anchor color="#A61E4D" component="button" size="sm">
                {row.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              Brand: {row.brand.label}
            </Text>
            <Text c="dimmed" fz="xs">
              Category: {row.category.label}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.sku}</td>
      <td>{row.category.label}</td>
      <td>{row.flavour !== null ? row.flavour.label : "--"}</td>
      <td>{row.variant.label}</td>
      <td>--</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
    </tr>
  ));

  return (
    <>
      <Skeleton mt={20} radius="md" visible={variables.skeletonLoading}>
        <Grid>
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flexDirection: "column", marginTop: 10 }}>
                  <Text fw={600} size="sm">
                    Total Products
                  </Text>
                  <Text size="xl">{totalProduct}</Text>
                </div>
                <Avatar color="pink" radius="xl" size={70}>
                  <IconPackages size={36} />
                </Avatar>
              </div>
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flexDirection: "column", marginTop: 10 }}>
                  <Text fw={600} size="sm">
                    Total Probiotics
                  </Text>
                  <Text size="xl">{totalCategory}</Text>
                </div>
                <Avatar color="blue" radius="xl" size={70}>
                  <IconPackages size={36} />
                </Avatar>
              </div>
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flexDirection: "column", marginTop: 10 }}>
                  <Text fw={600} size="sm">
                    Total Vitamins
                  </Text>
                  <Text size="xl">{totalType}</Text>
                </div>
                <Avatar color="green" radius="xl" size={70}>
                  <IconPackages size={36} />
                </Avatar>
              </div>
            </Card>
          </Grid.Col>
        </Grid>
      </Skeleton>
      <Skeleton mt={10} radius="md" visible={variables.skeletonLoading}>
        <Grid>
          <Grid.Col md={12} lg={12}>
            <Card shadow="xs" padding="sm" radius="md">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <Text my={10} size={16} fw={600}>
                  Recently added product
                </Text>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Product</th>
                      <th>SKU</th>
                      <th>Category</th>
                      <th>Flavour</th>
                      <th>Variant</th>
                      <th>Created By</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      setData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid>
      </Skeleton>
    </>
  );
}

export default Dashboard;
