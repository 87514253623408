/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Mark,
  Modal,
  NativeSelect,
  Pagination,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useForm } from "@mantine/form";
import {
  handleAddProductTrackingDetails,
  handleDeleteProductTrackingDetails,
  handleEditProductBatch,
  handleEditProductTrackingDetails,
  handleGetDistributors,
  handleGetManufacturers,
  handleGetOneProductBatch,
  handleGetOneProductTrackingDetails,
  handleGetProduct,
  handleGetProductBatch,
  handleGetProductTrackingDetails,
  handleGetRegion,
} from "../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../utils/common/common";
import {
  IconClock,
  IconFileExport,
  IconListNumbers,
  IconPencil,
  IconPlane,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

function TrackingDetails() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const params = useParams();
  const ref = useRef();
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [product, setProduct] = useState([]);
  const [batch, setBatch] = useState([]);
  const [region, setRegion] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [productName, setProductName] = useState("");
  const [search, setSearch] = useState("");
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // For edit form data validation
  const formEditBatch = useForm({
    initialValues: {
      value: "",
      productId: "",
      regionId: "",
      idStartFrom: "",
      noOfProducts: "",
      inWordDate: new Date(),
      inWordTime: "",
      manufactureDate: new Date(),
      expireDate: new Date(),
    },
  });

  // For form validation
  const form = useForm({
    initialValues: {
      productId: "",
      regionId: "",
      batchId: params.id,
      regionIdStart: "",
      startDate: new Date(),
      startTime: "",
      startTemperature: "",
      regionIdEnd: "",
      endDate: new Date(),
      endTime: "",
      endTemperature: "",
    },
    validate: {
      productId: (value) => (value.length < 1 ? "Product is required" : null),
    },
  });

  // For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: "",
      regionId: "",
      batchId: params.id,
      regionIdStart: "",
      startDate: new Date(),
      startTime: "",
      startTemperature: "",
      regionIdEnd: "",
      endDate: new Date(),
      endTime: "",
      endTemperature: "",
    },
  });

  const formSearch = useForm({
    initialValues: {
      productId: "",
    },
  });

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          skeletonLoading: true,
          submitLoading: true,
        });
        const response = await handleGetOneProductBatch(params.id);
        // On response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.product_tracking_details.reverse(),
            skeletonLoading: false,
            submitLoading: false,
          });
          const datas = dataSlice({
            data: response.data.product_tracking_details,
            page: 1,
            total: 10,
          });
          setData(datas);
          const dataValue = response.data;
          formEditBatch.setFieldValue("value", dataValue.id);
          formEditBatch.setFieldValue(
            "productId",
            dataValue.productId.toString()
          );
          setProductName(dataValue.product.label);
          formEditBatch.setFieldValue(
            "regionId",
            dataValue.regionId.toString()
          );
          formEditBatch.setFieldValue("idStartFrom", dataValue.idStartFrom);
          formEditBatch.setFieldValue("noOfProducts", dataValue.noOfProducts);
          // formEditBatch.setFieldValue(
          //   "inWordDate",
          //   dataValue.inWordDate.toLocaleDateString("en-UK")
          // );
          // formEditBatch.setFieldValue("inWordTime", dataValue.inWordTime);
          // formEditBatch.setFieldValue(
          //   "manufactureDate",
          //   dataValue.manufactureDate
          // );
          // formEditBatch.setFieldValue("expireDate", dataValue.expireDate);
        }

        const response1 = await handleGetProduct();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setProduct(clean);
        }

        const response2 = await handleGetProductBatch();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.id.toString(),
          }));
          setBatch(clean);
        }

        const response3 = await handleGetRegion();
        if (response3.status === 200) {
          var data = response3.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setRegion(clean);
        }

        const response4 = await handleGetManufacturers();
        if (response4.status === 200) {
          var data = response4.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setManufacturer(clean);
        }

        const response5 = await handleGetDistributors();
        if (response5.status === 200) {
          var data = response5.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setDistributors(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>

      <td>{row.batchId}</td>
      <td>{row.manufacturer.label}</td>
      <td>{new Date(row.startDate).toLocaleDateString("en-UK")}</td>
      <td>{tConvert(row.startTime)}</td>
      <td>{row.startTemperature}</td>
      <td>{row.distributor.label}</td>
      <td>{new Date(row.endDate).toLocaleDateString("en-UK")}</td>
      <td>{tConvert(row.endTime)}</td>
      <td>{row.endTemperature}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <IconPencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add function
  const handleCreateProductTrackingDetails = async (e) => {
    alert(e);
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddProductTrackingDetails(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Tracking details created successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle open edit modal
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    formEdit.setFieldValue("value", datas.id);
    formEdit.setFieldValue("productId", datas.productId);
    formEdit.setFieldValue("batchId", datas.batchId);
    formEdit.setFieldValue("regionIdStart", datas.manufacturerID.toString());
    // formEdit.setFieldValue("startDate", datas.startDate);
    formEdit.setFieldValue("startTime", datas.startTime);
    formEdit.setFieldValue("startTemperature", datas.startTemperature);
    formEdit.setFieldValue("regionIdEnd", datas.distributedByID.toString());
    formEdit.setFieldValue("endDate", datas.endDate);
    formEdit.setFieldValue("endTime", datas.endTime);
    formEdit.setFieldValue("endTemperature", datas.endTemperature);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Handle update data function
  const handleUpdateProductBatch = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProductBatch(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Batch updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle update data function
  const handleUpdateProductTrackingDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProductTrackingDetails(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Tracking details updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the tracking details{" "}
          <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProductTrackingDetails(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Tracking details deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Tracking Details" />
      </Skeleton>
      <Grid>
        <Grid.Col md={12} lg={12}>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <div style={{ padding: "10px" }}>
                <form
                  onSubmit={formEditBatch.onSubmit((values) =>
                    handleUpdateProductBatch(values)
                  )}
                >
                  <Grid>
                    <Grid.Col md={4} lg={4}>
                      <Select
                        label="Select Product"
                        placeholder="Select Product"
                        data={product}
                        searchable
                        value={formEditBatch.values.productId}
                        clearable
                        {...formEditBatch.getInputProps("productId")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TextInput
                        label="Starts From"
                        withAsterisk
                        value={formEditBatch.values.idStartFrom}
                        placeholder="Starts From"
                        {...formEditBatch.getInputProps("idStartFrom")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TextInput
                        label="Total Products"
                        withAsterisk
                        value={formEditBatch.values.noOfProducts}
                        placeholder="Total Products"
                        {...formEditBatch.getInputProps("noOfProducts")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="In Word Date"
                        placeholder="In Word Date"
                        value={formEditBatch.values.inWordDate}
                        {...formEditBatch.getInputProps("inWordDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TimeInput
                        label="In Word Time"
                        ref={ref}
                        rightSection={
                          <ActionIcon onClick={() => ref.current.showPicker()}>
                            <IconClock size="1rem" stroke={1.5} />
                          </ActionIcon>
                        }
                        value={formEditBatch.values.inWordTime}
                        {...formEditBatch.getInputProps("inWordTime")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="Manufacture Date"
                        placeholder="Manufacture Date"
                        value={formEditBatch.values.manufactureDate}
                        {...formEditBatch.getInputProps("manufactureDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="Expire Date"
                        placeholder="Expire Date"
                        value={formEditBatch.values.expireDate}
                        {...formEditBatch.getInputProps("expireDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="right">
                        <Button
                          radius="sm"
                          type="submit"
                          size="sm"
                          color="#A61E4D"
                        >
                          Update Product Batch
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </form>
              </div>
            </Card>
          </Skeleton>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <Text size={16} mb={5}>
                {productName} with list of batch numbers.
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                <TextInput
                  variant="filled"
                  placeholder="Search by any field"
                  mb="md"
                  size="xs"
                  value={search}
                  icon={<IconSearch size={14} />}
                  onChange={async (e) => {
                    // On change search after the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Group spacing="xs">
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    mr={10}
                    onClick={() =>
                      setVariables({
                        ...variables,
                        addDrawer: true,
                      })
                    }
                    leftIcon={<IconPlus size={12} />}
                  >
                    Add Tracking Details
                  </Button>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "product", key: "product.label" },
                      { label: "batch Id ", key: "batchId" },
                      { label: "region id start", key: "regionIdStart" },
                      { label: "start date", key: "startDate" },
                      { label: "start time", key: "startTime" },
                      {
                        label: "start temperature",
                        key: "startTemperature",
                      },
                      { label: "region id end", key: "regionIdEnd" },
                      { label: "end date", key: "endDate" },
                      { label: "end time", key: "endTime" },
                      { label: "end temperature", key: "endTemperature" },
                    ]}
                    filename="tracking_details.csv"
                  >
                    <Button
                      variant="outline"
                      color="zevcore"
                      size="xs"
                      leftIcon={<IconFileExport size={12} />}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Group>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Batch ID</th>
                      <th>Start Region</th>
                      <th>Start Date</th>
                      <th>Start Time</th>
                      <th>Start Temperature</th>
                      <th>End Region</th>
                      <th>End Date</th>
                      <th>End Time</th>
                      <th>End Temperature</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {variables.submitLoading === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={12}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={12}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      setData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Skeleton>
        </Grid.Col>
      </Grid>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={`Enter tracking details of ${productName}`}
        size="70%"
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={form.onSubmit((values) =>
                handleCreateProductTrackingDetails(values)
              )}
            >
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Start From"
                    placeholder="Start From"
                    data={manufacturer}
                    searchable
                    value={form.values.regionIdStart}
                    clearable
                    {...form.getInputProps("regionIdStart")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    dropdownType="modal"
                    label="Start Date"
                    placeholder="Start Date"
                    value={form.values.startDate}
                    mx="auto"
                    maw={400}
                    {...form.getInputProps("startDate")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TimeInput
                    label="Start Time"
                    ref={startTimeRef}
                    rightSection={
                      <ActionIcon
                        onClick={() => startTimeRef.current.showPicker()}
                      >
                        <IconClock size="1rem" stroke={1.5} />
                      </ActionIcon>
                    }
                    value={form.values.startTime}
                    maw={400}
                    mx="auto"
                    {...form.getInputProps("startTime")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Start Temperature"
                    withAsterisk
                    value={form.values.startTemperature}
                    placeholder="Start Temperature"
                    {...form.getInputProps("startTemperature")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Ends At"
                    placeholder="Ends At"
                    data={distributors}
                    searchable
                    value={form.values.regionIdEnd}
                    clearable
                    {...form.getInputProps("regionIdEnd")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    dropdownType="modal"
                    label="End Date"
                    placeholder="End Date"
                    value={form.values.endDate}
                    mx="auto"
                    maw={400}
                    {...form.getInputProps("endDate")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TimeInput
                    label="End Time"
                    ref={endTimeRef}
                    rightSection={
                      <ActionIcon
                        onClick={() => endTimeRef.current.showPicker()}
                      >
                        <IconClock size="1rem" stroke={1.5} />
                      </ActionIcon>
                    }
                    value={form.values.endTime}
                    maw={400}
                    mx="auto"
                    {...form.getInputProps("endTime")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="End Temperature"
                    withAsterisk
                    value={form.values.endTemperature}
                    placeholder="End Temperature"
                    {...form.getInputProps("endTemperature")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button radius="sm" type="submit" size="sm" color="#A61E4D">
                      Add Tracking Details
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={`Update tracking details of ${productName}`}
        size="70%"
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div style={{ padding: "10px" }}>
          <form
            onSubmit={formEdit.onSubmit((values) =>
              handleUpdateProductTrackingDetails(values)
            )}
          >
            <Grid>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="Start From"
                  placeholder="Start From"
                  data={manufacturer}
                  searchable
                  value={formEdit.values.regionIdStart}
                  clearable
                  {...formEdit.getInputProps("regionIdStart")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <DatePickerInput
                  dropdownType="modal"
                  label="Start Date"
                  withAsterisk
                  value={formEdit.values.startDate}
                  placeholder="Start Date"
                  {...formEdit.getInputProps("startDate")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TimeInput
                  label="Start Time"
                  ref={startTimeRef}
                  rightSection={
                    <ActionIcon
                      onClick={() => startTimeRef.current.showPicker()}
                    >
                      <IconClock size="1rem" stroke={1.5} />
                    </ActionIcon>
                  }
                  value={formEdit.values.startTime}
                  {...formEdit.getInputProps("startTime")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Start Temperature"
                  withAsterisk
                  value={formEdit.values.startTemperature}
                  placeholder="Start Temperature"
                  {...formEdit.getInputProps("startTemperature")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="End At"
                  placeholder="End At"
                  data={distributors}
                  searchable
                  value={formEdit.values.regionIdEnd}
                  clearable
                  {...formEdit.getInputProps("regionIdEnd")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <DatePickerInput
                  dropdownType="modal"
                  label="End Date"
                  placeholder="End Date"
                  value={formEdit.values.endDate}
                  {...formEdit.getInputProps("inWordDate")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TimeInput
                  label="End Time"
                  ref={endTimeRef}
                  rightSection={
                    <ActionIcon onClick={() => endTimeRef.current.showPicker()}>
                      <IconClock size="1rem" stroke={1.5} />
                    </ActionIcon>
                  }
                  value={formEdit.values.endTime}
                  {...formEdit.getInputProps("endTime")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="End Temperature"
                  withAsterisk
                  value={formEdit.values.endTemperature}
                  placeholder="End Temperature"
                  {...formEdit.getInputProps("endTemperature")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Group position="right">
                  <Button radius="sm" type="submit" size="sm" color="#A61E4D">
                    Update Tracking Details
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}
    </>
  );
}

export default TrackingDetails;
