/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Modal,
  Radio,
  Skeleton,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddSupplementFacts,
  handleGetRegion,
  handleSearchSupplementFacts,
  handleEditSupplementFacts,
  handleDuplicateSupplementFacts,
  handleGetOneSupplementFacts,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { useParams } from "react-router-dom";
// Text editor imports starts
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
// Text editor imports ends

function SupplementFacts() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [regionValue, setRegionValue] = useState("1");
  const [activeTab, setActiveTab] = useState("subtle");
  const [defaultValue, setDefaultValue] = useState("1");
  const [updatedAt, setUpdatedAt] = useState();

  // For form validation
  const form = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      servingSize: "",
      servingContainer: "",
      amountServing: "",
      starValue: "",
      plusValue: "",
    },
    validate: {
      servingSize: (value) =>
        value.length < 1 ? "Serving size is required" : null,
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      servingSize: "",
      servingContainer: "",
      amountServing: "",
      starValue: "",
      plusValue: "",
    },
    validate: {
      servingSize: (value) =>
        value.length < 1 ? "Serving size is required" : null,
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: "",
      regionIdTo: "",
    },
  });

  // Editor for description
  const [starDescription, setStarDescription] = useState();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Star Value Description" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("starValue", html);
      formEdit.setFieldValue("starValue", html);
      setStarDescription(html);
    },
  });

  // Editor for key benefits
  const [plusDescription, setPlusDescription] = useState();
  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Plus Description" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html1 = editor.getHTML();
      form.setFieldValue("plusValue", html1);
      formEdit.setFieldValue("plusValue", html1);
      setPlusDescription(html1);
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneSupplementFacts(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
            });
            if (
              response.data.data !== "" &&
              response.data.data !== null &&
              typeof response.data.data !== "undefined"
            ) {
              setCount(1);
              setUpdatedAt(response.data.data.updatedAt);
              formEdit.setFieldValue("value", response.data.data.id);
              formEdit.setFieldValue(
                "regionId",
                response.data.data.regionId.toString()
              );
              if (
                response.data.data.servingSize !== null &&
                response.data.data.servingSize !== "" &&
                typeof response.data.data.servingSize !== "undefined"
              ) {
                formEdit.setFieldValue(
                  "servingSize",
                  response.data.data.servingSize
                );
              } else {
                formEdit.setFieldValue("servingSize", "");
              }
              if (
                response.data.data.servingContainer !== null &&
                response.data.data.servingContainer !== "" &&
                typeof response.data.data.servingContainer !== "undefined"
              ) {
                formEdit.setFieldValue(
                  "servingContainer",
                  response.data.data.servingContainer
                );
              } else {
                formEdit.setFieldValue("servingContainer", "");
              }
              if (
                response.data.data.amountServing !== null &&
                response.data.data.amountServing !== "" &&
                typeof response.data.data.amountServing !== "undefined"
              ) {
                formEdit.setFieldValue(
                  "amountServing",
                  response.data.data.amountServing
                );
              } else {
                formEdit.setFieldValue("amountServing", "");
              }
              if (
                response.data.data.starValue !== null &&
                response.data.data.starValue !== "" &&
                typeof response.data.data.starValue !== "undefined"
              ) {
                editor?.commands.setContent(response.data.data.starValue);
                formEdit.setFieldValue(
                  "starValue",
                  response.data.data.starValue
                );
              } else {
                formEdit.setFieldValue("starValue", "");
              }
              if (
                response.data.data.plusValue !== null &&
                response.data.data.plusValue !== "" &&
                typeof response.data.data.plusValue !== "undefined"
              ) {
                editor1?.commands.setContent(response.data.data.plusValue);
                formEdit.setFieldValue(
                  "plusValue",
                  response.data.data.plusValue
                );
              } else {
                formEdit.setFieldValue("plusValue", "");
              }
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
              skeletonLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [editor, editor1]);

  // Handle add function
  const handleCreateSupplementFacts = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddSupplementFacts(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Supplement facts created successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleUpdateSupplementFacts = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditSupplementFacts(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Supplement facts updated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search supplements facts by regionId & productId function
  const handleFetchRegionSupplementFacts = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    setActiveTab("filled");
    const response = await handleSearchSupplementFacts(req);
    if (response.status === 200) {
      if (
        response.data.data[0] !== null &&
        response.data.data[0] !== "" &&
        typeof response.data.data[0] !== "undefined"
      ) {
        setCount(1);
        formEdit.setFieldValue("value", response.data.data[0].id);
        formEdit.setFieldValue(
          "regionId",
          response.data.data[0].regionId.toString()
        );
        setVariables({ ...variables, submitLoading: false });
        if (
          response.data.data[0].servingSize !== null &&
          response.data.data[0].servingSize !== "" &&
          typeof response.data.data[0].servingSize !== "undefined"
        ) {
          formEdit.setFieldValue(
            "servingSize",
            response.data.data[0].servingSize
          );
        } else {
          formEdit.setFieldValue("servingSize", "");
        }
        if (
          response.data.data[0].servingContainer !== null &&
          response.data.data[0].servingContainer !== "" &&
          typeof response.data.data[0].servingContainer !== "undefined"
        ) {
          formEdit.setFieldValue(
            "servingContainer",
            response.data.data[0].servingContainer
          );
        } else {
          formEdit.setFieldValue("servingContainer", "");
        }
        if (
          response.data.data[0].amountServing !== null &&
          response.data.data[0].amountServing !== "" &&
          typeof response.data.data[0].amountServing !== "undefined"
        ) {
          formEdit.setFieldValue(
            "amountServing",
            response.data.data[0].amountServing
          );
        } else {
          formEdit.setFieldValue("amountServing", "");
        }
        if (
          response.data.data[0].starValue !== null &&
          response.data.data[0].starValue !== "" &&
          typeof response.data.data[0].starValue !== "undefined"
        ) {
          editor?.commands.setContent(response.data.data[0].starValue);
          formEdit.setFieldValue("starValue", response.data.data[0].starValue);
        } else {
          formEdit.setFieldValue("starValue", "");
        }
        if (
          response.data.data[0].plusValue !== null &&
          response.data.data[0].plusValue !== "" &&
          typeof response.data.data[0].plusValue !== "undefined"
        ) {
          editor1?.commands.setContent(response.data.data[0].plusValue);
          formEdit.setFieldValue("plusValue", response.data.data[0].plusValue);
        } else {
          formEdit.setFieldValue("plusValue", "");
        }
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle duplicate supplements facts function
  const handleConfirmDuplicateSupplementFacts = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateSupplementFacts(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Supplement facts duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() => handleFetchRegionSupplementFacts(item.value)}
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel value={defaultValue} pt="xs">
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formEdit.onSubmit((values) =>
                      handleUpdateSupplementFacts(values)
                    )}
                  >
                    <Grid>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Serving Size"
                          value={formEdit.values.servingSize}
                          placeholder="Serving Size"
                          {...formEdit.getInputProps("servingSize")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Serving Container"
                          value={formEdit.values.servingContainer}
                          placeholder="Serving Container"
                          {...formEdit.getInputProps("servingContainer")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Amount Serving"
                          value={formEdit.values.amountServing}
                          placeholder="Amount Serving"
                          {...formEdit.getInputProps("amountServing")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Star Description</Text>
                        <RichTextEditor editor={editor}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={starDescription}
                            mb={20}
                            {...formEdit.getInputProps("starValue")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Plus Description </Text>
                        <RichTextEditor editor={editor1}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={plusDescription}
                            mb={20}
                            {...formEdit.getInputProps("plusValue")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="left">
                          <Badge color="#FCC2D7">
                            Last updated on:&nbsp;
                            {new Date(updatedAt).toLocaleString("en-UK")}
                          </Badge>
                        </Group>
                        <Group position="right">
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            type="submit"
                            size="sm"
                            color="#A61E4D"
                          >
                            Update Supplement Facts
                          </Button>
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            size="sm"
                            color="#A61E4D"
                            onClick={() =>
                              setVariables({
                                ...variables,
                                confirmDrawer: true,
                              })
                            }
                          >
                            Duplicate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={form.onSubmit((values) =>
                  handleCreateSupplementFacts(values)
                )}
              >
                <Grid>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      label="Serving Size"
                      value={form.values.servingSize}
                      placeholder="Serving Size"
                      {...form.getInputProps("servingSize")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      label="Serving Container"
                      value={form.values.servingContainer}
                      placeholder="Serving Container"
                      {...form.getInputProps("servingContainer")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      label="Amount Serving"
                      value={form.values.amountServing}
                      placeholder="Amount Serving"
                      {...form.getInputProps("amountServing")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Star Description</Text>
                    <RichTextEditor editor={editor}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>

                      <RichTextEditor.Content
                        value={starDescription}
                        mb={20}
                        {...form.getInputProps("starValue")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Plus Description </Text>
                    <RichTextEditor editor={editor1}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={plusDescription}
                        mb={20}
                        {...form.getInputProps("plusValue")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        loading={variables.skeletonLoading}
                        disabled={variables.skeletonLoading}
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="#A61E4D"
                      >
                        Add Supplement Facts
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Supplements Facts"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateSupplementFacts(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SupplementFacts;
