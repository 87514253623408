/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Select,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddAmazonSpecification,
  handleGetProductType,
  handleGetRegion,
  handleGetFlavours,
  handleGetOneAmazonSpecification,
  handleEditAmazonSpecification,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { useParams } from "react-router-dom";
function AmazonSpecifications() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [region, setRegion] = useState([]);
  const [productType, setProductType] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("");
  const [flavour, setFlavour] = useState([]);
  const [count, setCount] = useState(0);
  const [updatedAt, setUpdatedAt] = useState();

  // For form validation
  const form = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      // First
      productType: "",
      productIDType: "",
      colorMap: "",
      itemForm: "",
      ppuCountUnitOfMeasure: "",
      countryOfOrigin: "",
      containsLiquidContent: "",
      isTheItemHeatSensitive: "",
      targetGender: "",
      ageRangeDescription: "",
      // First
      // Second
      percentage: "",
      relationalShipType: "",
      variationTheme: "",
      // Second
      // Third
      updateDelete: "",
      productExemptionReason: "",
      // Third
      // Fourth
      specialIngredients: "",
      flavour: "",
      servingQuantityUnit: "",
      carbohydrateUnit: "",
      vitaminMineralUnit: "",
      meltingTemperatureUnit: "",
      dietType: "",
      productBenefits: "",
      materialType: "",
      proteinUnit: "",
      includedComponents: "",
      // Fourth
      // Fifth
      materialFeatures: "",
      calciumPerServingUnitOfMeasure: "",
      vitaminMineralUnit: "",
      flashPointUnitOfMeasure: "",
      fcShelfLifeUnit: "",
      skipOffer: "",
      isTheLiquidProductDoubleSealed: "",
      fatUnit: "",
      containerType: "",
      isExemptFromSupplierDeclaredExternalProductIdentifier: "",
      energyUnit: "",
      minimumAdvertisedPriceDisplay: "",
      // Fifth
      // Sixth
      itemLengthUnitOfMeasure: "",
      itemWidthUnitOfMeasure: "",
      itemHeightUnitOfMeasure: "",
      liquidVolumeUnit: "",
      loadCapacityUnitOfMeasure: "",
      wigLength: "",
      // Sixth
      // Seventh
      batteriesAreIncluded: "",
      isThisProductABatteryOrDoesItUseBatteries: "",
      batteryComposition: "",
      lithiumBatteryEnergyContentUnitOfMeasure: "",
      lithiumBatteryPackaging: "",
      lithiumBatteryWeightUnitOfMeasure: "",
      usage: "",
      allergenInformation: "",
      batteryTypeSize: "",
      // Seventh
      // Eight
      itemCondition: "",
      productTaxCode: "",
      isGiftWrapAvailable: "",
      shippingTemplate: "",
      productExpirationType: "",
      canBeGiftMessaged: "",
      isProductExpiable: "",
      currency: "INR",
      // Eight
      // Ninth
      quantityPriceType: "",
      pricingAction: "",
      // Ninth
    },
    // validate: {
    //   productType: (value) =>
    //     value.length < 1 ? "Product type selection is required" : null,
    // },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneAmazonSpecification(params.id);
        // On Response setting the data to variable
        console.log(response.data.data[2]);
        if (response.status === 200) {
          setVariables({
            ...variables,
            submitLoading: false,
          });
          if (
            response.data.data[2] !== "" &&
            response.data.data[2] !== null &&
            typeof response.data.data[2] !== "undefined"
          ) {
            setCount(1);
            const dataValues = response.data.data[2];
            setUpdatedAt(dataValues.updatedAt);
            form.setFieldValue("value", dataValues.id);
            form.setFieldValue("productId", dataValues.productId);
            form.setFieldValue("regionId", dataValues.regionId);
            if (
              dataValues.productType !== null &&
              dataValues.productType !== "" &&
              typeof dataValues.productType !== "undefined"
            ) {
              form.setFieldValue(
                "productType",
                dataValues.productType.toString()
              );
            } else {
              form.setFieldValue("productType", "");
            }
            if (
              dataValues.productIDType !== null &&
              dataValues.productIDType !== "" &&
              typeof dataValues.productIDType !== "undefined"
            ) {
              form.setFieldValue("productIDType", dataValues.productIDType);
            } else {
              form.setFieldValue("productIDType", "");
            }
            if (
              dataValues.colorMap !== null &&
              dataValues.colorMap !== "" &&
              typeof dataValues.colorMap !== "undefined"
            ) {
              form.setFieldValue("colorMap", dataValues.colorMap);
            } else {
              form.setFieldValue("colorMap", "");
            }
            if (
              dataValues.itemForm !== null &&
              dataValues.itemForm !== "" &&
              typeof dataValues.itemForm !== "undefined"
            ) {
              form.setFieldValue("itemForm", dataValues.itemForm);
            } else {
              form.setFieldValue("itemForm", "");
            }
            if (
              dataValues.ppuCountUnitOfMeasure !== null &&
              dataValues.ppuCountUnitOfMeasure !== "" &&
              typeof dataValues.ppuCountUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "ppuCountUnitOfMeasure",
                dataValues.ppuCountUnitOfMeasure
              );
            } else {
              form.setFieldValue("ppuCountUnitOfMeasure", "");
            }
            if (
              dataValues.countryOfOrigin !== null &&
              dataValues.countryOfOrigin !== "" &&
              typeof dataValues.countryOfOrigin !== "undefined"
            ) {
              form.setFieldValue("countryOfOrigin", dataValues.countryOfOrigin);
            } else {
              form.setFieldValue("countryOfOrigin", "");
            }
            if (
              dataValues.containsLiquidContent !== null &&
              dataValues.containsLiquidContent !== "" &&
              typeof dataValues.containsLiquidContent !== "undefined"
            ) {
              form.setFieldValue(
                "containsLiquidContent",
                dataValues.containsLiquidContent
              );
            } else {
              form.setFieldValue("containsLiquidContent", "");
            }
            if (
              dataValues.isTheItemHeatSensitive !== null &&
              dataValues.isTheItemHeatSensitive !== "" &&
              typeof dataValues.isTheItemHeatSensitive !== "undefined"
            ) {
              form.setFieldValue(
                "isTheItemHeatSensitive",
                dataValues.isTheItemHeatSensitive
              );
            } else {
              form.setFieldValue("isTheItemHeatSensitive", "");
            }
            if (
              dataValues.targetGender !== null &&
              dataValues.targetGender !== "" &&
              typeof dataValues.targetGender !== "undefined"
            ) {
              form.setFieldValue("targetGender", dataValues.targetGender);
            } else {
              form.setFieldValue("targetGender", "");
            }
            if (
              dataValues.ageRangeDescription !== null &&
              dataValues.ageRangeDescription !== "" &&
              typeof dataValues.ageRangeDescription !== "undefined"
            ) {
              form.setFieldValue(
                "ageRangeDescription",
                dataValues.ageRangeDescription
              );
            } else {
              form.setFieldValue("ageRangeDescription", "");
            }
            if (
              dataValues.percentage !== null &&
              dataValues.percentage !== "" &&
              typeof dataValues.percentage !== "undefined"
            ) {
              form.setFieldValue("percentage", dataValues.percentage);
            } else {
              form.setFieldValue("percentage", "");
            }
            if (
              dataValues.relationalShipType !== null &&
              dataValues.relationalShipType !== "" &&
              typeof dataValues.relationalShipType !== "undefined"
            ) {
              form.setFieldValue(
                "relationalShipType",
                dataValues.relationalShipType
              );
            } else {
              form.setFieldValue("relationalShipType", "");
            }
            if (
              dataValues.variationTheme !== null &&
              dataValues.variationTheme !== "" &&
              typeof dataValues.variationTheme !== "undefined"
            ) {
              form.setFieldValue("variationTheme", dataValues.variationTheme);
            } else {
              form.setFieldValue("variationTheme", "");
            }
            if (
              dataValues.updateDelete !== null &&
              dataValues.updateDelete !== "" &&
              typeof dataValues.updateDelete !== "undefined"
            ) {
              form.setFieldValue("updateDelete", dataValues.updateDelete);
            } else {
              form.setFieldValue("updateDelete", "");
            }
            if (
              dataValues.productExemptionReason !== null &&
              dataValues.productExemptionReason !== "" &&
              typeof dataValues.productExemptionReason !== "undefined"
            ) {
              form.setFieldValue(
                "productExemptionReason",
                dataValues.productExemptionReason
              );
            } else {
              form.setFieldValue("productExemptionReason", "");
            }
            if (
              dataValues.specialIngredients !== null &&
              dataValues.specialIngredients !== "" &&
              typeof dataValues.specialIngredients !== "undefined"
            ) {
              form.setFieldValue(
                "specialIngredients",
                dataValues.specialIngredients
              );
            } else {
              form.setFieldValue("specialIngredients", "");
            }
            if (
              dataValues.flavour !== null &&
              dataValues.flavour !== "" &&
              typeof dataValues.flavour !== "undefined"
            ) {
              form.setFieldValue("flavour", dataValues.flavour);
            } else {
              form.setFieldValue("flavour", "");
            }
            if (
              dataValues.servingQuantityUnit !== null &&
              dataValues.servingQuantityUnit !== "" &&
              typeof dataValues.servingQuantityUnit !== "undefined"
            ) {
              form.setFieldValue(
                "servingQuantityUnit",
                dataValues.servingQuantityUnit
              );
            } else {
              form.setFieldValue("servingQuantityUnit", "");
            }
            if (
              dataValues.vitaminMineralUnit !== null &&
              dataValues.vitaminMineralUnit !== "" &&
              typeof dataValues.vitaminMineralUnit !== "undefined"
            ) {
              form.setFieldValue(
                "vitaminMineralUnit",
                dataValues.vitaminMineralUnit
              );
            } else {
              form.setFieldValue("vitaminMineralUnit", "");
            }
            if (
              dataValues.meltingTemperatureUnit !== null &&
              dataValues.meltingTemperatureUnit !== "" &&
              typeof dataValues.meltingTemperatureUnit !== "undefined"
            ) {
              form.setFieldValue(
                "meltingTemperatureUnit",
                dataValues.meltingTemperatureUnit
              );
            } else {
              form.setFieldValue("meltingTemperatureUnit", "");
            }
            if (
              dataValues.dietType !== null &&
              dataValues.dietType !== "" &&
              typeof dataValues.dietType !== "undefined"
            ) {
              form.setFieldValue("dietType", dataValues.dietType);
            } else {
              form.setFieldValue("dietType", "");
            }
            if (
              dataValues.productBenefits !== null &&
              dataValues.productBenefits !== "" &&
              typeof dataValues.productBenefits !== "undefined"
            ) {
              form.setFieldValue("productBenefits", dataValues.productBenefits);
            } else {
              form.setFieldValue("productBenefits", "");
            }
            if (
              dataValues.materialType !== null &&
              dataValues.materialType !== "" &&
              typeof dataValues.materialType !== "undefined"
            ) {
              form.setFieldValue("materialType", dataValues.materialType);
            } else {
              form.setFieldValue("materialType", "");
            }
            if (
              dataValues.proteinUnit !== null &&
              dataValues.proteinUnit !== "" &&
              typeof dataValues.proteinUnit !== "undefined"
            ) {
              form.setFieldValue("proteinUnit", dataValues.proteinUnit);
            } else {
              form.setFieldValue("proteinUnit", "");
            }
            if (
              dataValues.includedComponents !== null &&
              dataValues.includedComponents !== "" &&
              typeof dataValues.includedComponents !== "undefined"
            ) {
              form.setFieldValue(
                "includedComponents",
                dataValues.includedComponents
              );
            } else {
              form.setFieldValue("includedComponents", "");
            }
            if (
              dataValues.materialFeatures !== null &&
              dataValues.materialFeatures !== "" &&
              typeof dataValues.materialFeatures !== "undefined"
            ) {
              form.setFieldValue(
                "materialFeatures",
                dataValues.materialFeatures
              );
            } else {
              form.setFieldValue("materialFeatures", "");
            }
            if (
              dataValues.calciumPerServingUnitOfMeasure !== null &&
              dataValues.calciumPerServingUnitOfMeasure !== "" &&
              typeof dataValues.calciumPerServingUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "calciumPerServingUnitOfMeasure",
                dataValues.calciumPerServingUnitOfMeasure
              );
            } else {
              form.setFieldValue("calciumPerServingUnitOfMeasure", "");
            }
            if (
              dataValues.flashPointUnitOfMeasure !== null &&
              dataValues.flashPointUnitOfMeasure !== "" &&
              typeof dataValues.flashPointUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "flashPointUnitOfMeasure",
                dataValues.flashPointUnitOfMeasure
              );
            } else {
              form.setFieldValue("flashPointUnitOfMeasure", "");
            }
            if (
              dataValues.fcShelfLifeUnit !== null &&
              dataValues.fcShelfLifeUnit !== "" &&
              typeof dataValues.fcShelfLifeUnit !== "undefined"
            ) {
              form.setFieldValue("fcShelfLifeUnit", dataValues.fcShelfLifeUnit);
            } else {
              form.setFieldValue("fcShelfLifeUnit", "");
            }
            if (
              dataValues.skipOffer !== null &&
              dataValues.skipOffer !== "" &&
              typeof dataValues.skipOffer !== "undefined"
            ) {
              form.setFieldValue("skipOffer", dataValues.skipOffer);
            } else {
              form.setFieldValue("skipOffer", "");
            }
            if (
              dataValues.isTheLiquidProductDoubleSealed !== null &&
              dataValues.isTheLiquidProductDoubleSealed !== "" &&
              typeof dataValues.isTheLiquidProductDoubleSealed !== "undefined"
            ) {
              form.setFieldValue(
                "isTheLiquidProductDoubleSealed",
                dataValues.isTheLiquidProductDoubleSealed
              );
            } else {
              form.setFieldValue("isTheLiquidProductDoubleSealed", "");
            }
            if (
              dataValues.fatUnit !== null &&
              dataValues.fatUnit !== "" &&
              typeof dataValues.fatUnit !== "undefined"
            ) {
              form.setFieldValue("fatUnit", dataValues.fatUnit);
            } else {
              form.setFieldValue("fatUnit", "");
            }
            if (
              dataValues.containerType !== null &&
              dataValues.containerType !== "" &&
              typeof dataValues.containerType !== "undefined"
            ) {
              form.setFieldValue("containerType", dataValues.containerType);
            } else {
              form.setFieldValue("containerType", "");
            }
            if (
              dataValues.isExemptFromSupplierDeclaredExternalProductIdentifier !==
                null &&
              dataValues.isExemptFromSupplierDeclaredExternalProductIdentifier !==
                "" &&
              typeof dataValues.isExemptFromSupplierDeclaredExternalProductIdentifier !==
                "undefined"
            ) {
              form.setFieldValue(
                "isExemptFromSupplierDeclaredExternalProductIdentifier",
                dataValues.isExemptFromSupplierDeclaredExternalProductIdentifier
              );
            } else {
              form.setFieldValue(
                "isExemptFromSupplierDeclaredExternalProductIdentifier",
                ""
              );
            }
            if (
              dataValues.energyUnit !== null &&
              dataValues.energyUnit !== "" &&
              typeof dataValues.energyUnit !== "undefined"
            ) {
              form.setFieldValue("energyUnit", dataValues.energyUnit);
            } else {
              form.setFieldValue("energyUnit", "");
            }
            if (
              dataValues.minimumAdvertisedPriceDisplay !== null &&
              dataValues.minimumAdvertisedPriceDisplay !== "" &&
              typeof dataValues.minimumAdvertisedPriceDisplay !== "undefined"
            ) {
              form.setFieldValue(
                "minimumAdvertisedPriceDisplay",
                dataValues.minimumAdvertisedPriceDisplay
              );
            } else {
              form.setFieldValue("minimumAdvertisedPriceDisplay", "");
            }
            if (
              dataValues.itemLengthUnitOfMeasure !== null &&
              dataValues.itemLengthUnitOfMeasure !== "" &&
              typeof dataValues.itemLengthUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "itemLengthUnitOfMeasure",
                dataValues.itemLengthUnitOfMeasure
              );
            } else {
              form.setFieldValue("itemLengthUnitOfMeasure", "");
            }
            if (
              dataValues.itemWidthUnitOfMeasure !== null &&
              dataValues.itemWidthUnitOfMeasure !== "" &&
              typeof dataValues.itemWidthUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "itemWidthUnitOfMeasure",
                dataValues.itemWidthUnitOfMeasure
              );
            } else {
              form.setFieldValue("itemWidthUnitOfMeasure", "");
            }
            if (
              dataValues.itemHeightUnitOfMeasure !== null &&
              dataValues.itemHeightUnitOfMeasure !== "" &&
              typeof dataValues.itemHeightUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "itemHeightUnitOfMeasure",
                dataValues.itemHeightUnitOfMeasure
              );
            } else {
              form.setFieldValue("itemHeightUnitOfMeasure", "");
            }
            if (
              dataValues.liquidVolumeUnit !== null &&
              dataValues.liquidVolumeUnit !== "" &&
              typeof dataValues.liquidVolumeUnit !== "undefined"
            ) {
              form.setFieldValue(
                "liquidVolumeUnit",
                dataValues.liquidVolumeUnit
              );
            } else {
              form.setFieldValue("liquidVolumeUnit", "");
            }
            if (
              dataValues.loadCapacityUnitOfMeasure !== null &&
              dataValues.loadCapacityUnitOfMeasure !== "" &&
              typeof dataValues.loadCapacityUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "loadCapacityUnitOfMeasure",
                dataValues.loadCapacityUnitOfMeasure
              );
            } else {
              form.setFieldValue("loadCapacityUnitOfMeasure", "");
            }
            if (
              dataValues.wigLength !== null &&
              dataValues.wigLength !== "" &&
              typeof dataValues.wigLength !== "undefined"
            ) {
              form.setFieldValue("wigLength", dataValues.wigLength);
            } else {
              form.setFieldValue("wigLength", "");
            }
            if (
              dataValues.batteriesAreIncluded !== null &&
              dataValues.batteriesAreIncluded !== "" &&
              typeof dataValues.batteriesAreIncluded !== "undefined"
            ) {
              form.setFieldValue(
                "batteriesAreIncluded",
                dataValues.batteriesAreIncluded
              );
            } else {
              form.setFieldValue("batteriesAreIncluded", "");
            }
            if (
              dataValues.isThisProductABatteryOrDoesItUseBatteries !== null &&
              dataValues.isThisProductABatteryOrDoesItUseBatteries !== "" &&
              typeof dataValues.isThisProductABatteryOrDoesItUseBatteries !==
                "undefined"
            ) {
              form.setFieldValue(
                "isThisProductABatteryOrDoesItUseBatteries",
                dataValues.isThisProductABatteryOrDoesItUseBatteries
              );
            } else {
              form.setFieldValue(
                "isThisProductABatteryOrDoesItUseBatteries",
                ""
              );
            }
            if (
              dataValues.batteryComposition !== null &&
              dataValues.batteryComposition !== "" &&
              typeof dataValues.batteryComposition !== "undefined"
            ) {
              form.setFieldValue(
                "batteryComposition",
                dataValues.batteryComposition
              );
            } else {
              form.setFieldValue("batteryComposition", "");
            }
            if (
              dataValues.lithiumBatteryEnergyContentUnitOfMeasure !== null &&
              dataValues.lithiumBatteryEnergyContentUnitOfMeasure !== "" &&
              typeof dataValues.lithiumBatteryEnergyContentUnitOfMeasure !==
                "undefined"
            ) {
              form.setFieldValue(
                "lithiumBatteryEnergyContentUnitOfMeasure",
                dataValues.lithiumBatteryEnergyContentUnitOfMeasure
              );
            } else {
              form.setFieldValue(
                "lithiumBatteryEnergyContentUnitOfMeasure",
                ""
              );
            }
            if (
              dataValues.lithiumBatteryPackaging !== null &&
              dataValues.lithiumBatteryPackaging !== "" &&
              typeof dataValues.lithiumBatteryPackaging !== "undefined"
            ) {
              form.setFieldValue(
                "lithiumBatteryPackaging",
                dataValues.lithiumBatteryPackaging
              );
            } else {
              form.setFieldValue("lithiumBatteryPackaging", "");
            }
            if (
              dataValues.lithiumBatteryWeightUnitOfMeasure !== null &&
              dataValues.lithiumBatteryWeightUnitOfMeasure !== "" &&
              typeof dataValues.lithiumBatteryWeightUnitOfMeasure !==
                "undefined"
            ) {
              form.setFieldValue(
                "lithiumBatteryWeightUnitOfMeasure",
                dataValues.lithiumBatteryWeightUnitOfMeasure
              );
            } else {
              form.setFieldValue("lithiumBatteryWeightUnitOfMeasure", "");
            }
            if (
              dataValues.usage !== null &&
              dataValues.usage !== "" &&
              typeof dataValues.usage !== "undefined"
            ) {
              form.setFieldValue("usage", dataValues.usage);
            } else {
              form.setFieldValue("usage", "");
            }
            if (
              dataValues.allergenInformation !== null &&
              dataValues.allergenInformation !== "" &&
              typeof dataValues.allergenInformation !== "undefined"
            ) {
              form.setFieldValue(
                "allergenInformation",
                dataValues.allergenInformation
              );
            } else {
              form.setFieldValue("allergenInformation", "");
            }
            if (
              dataValues.batteryTypeSize !== null &&
              dataValues.batteryTypeSize !== "" &&
              typeof dataValues.batteryTypeSize !== "undefined"
            ) {
              form.setFieldValue("batteryTypeSize", dataValues.batteryTypeSize);
            } else {
              form.setFieldValue("batteryTypeSize", "");
            }
            if (
              dataValues.itemCondition !== null &&
              dataValues.itemCondition !== "" &&
              typeof dataValues.itemCondition !== "undefined"
            ) {
              form.setFieldValue("itemCondition", dataValues.itemCondition);
            } else {
              form.setFieldValue("itemCondition", "");
            }
            if (
              dataValues.productTaxCode !== null &&
              dataValues.productTaxCode !== "" &&
              typeof dataValues.productTaxCode !== "undefined"
            ) {
              form.setFieldValue("productTaxCode", dataValues.productTaxCode);
            } else {
              form.setFieldValue("productTaxCode", "");
            }
            if (
              dataValues.isGiftWrapAvailable !== null &&
              dataValues.isGiftWrapAvailable !== "" &&
              typeof dataValues.isGiftWrapAvailable !== "undefined"
            ) {
              form.setFieldValue(
                "isGiftWrapAvailable",
                dataValues.isGiftWrapAvailable
              );
            } else {
              form.setFieldValue("isGiftWrapAvailable", "");
            }
            if (
              dataValues.shippingTemplate !== null &&
              dataValues.shippingTemplate !== "" &&
              typeof dataValues.shippingTemplate !== "undefined"
            ) {
              form.setFieldValue(
                "shippingTemplate",
                dataValues.shippingTemplate
              );
            } else {
              form.setFieldValue("shippingTemplate", "");
            }
            if (
              dataValues.productExpirationType !== null &&
              dataValues.productExpirationType !== "" &&
              typeof dataValues.productExpirationType !== "undefined"
            ) {
              form.setFieldValue(
                "productExpirationType",
                dataValues.productExpirationType
              );
            } else {
              form.setFieldValue("productExpirationType", "");
            }
            if (
              dataValues.canBeGiftMessaged !== null &&
              dataValues.canBeGiftMessaged !== "" &&
              typeof dataValues.canBeGiftMessaged !== "undefined"
            ) {
              form.setFieldValue(
                "canBeGiftMessaged",
                dataValues.canBeGiftMessaged
              );
            } else {
              form.setFieldValue("canBeGiftMessaged", "");
            }
            if (
              dataValues.isProductExpiable !== null &&
              dataValues.isProductExpiable !== "" &&
              typeof dataValues.isProductExpiable !== "undefined"
            ) {
              form.setFieldValue(
                "isProductExpiable",
                dataValues.isProductExpiable
              );
            } else {
              form.setFieldValue("isProductExpiable", "");
            }
            if (
              dataValues.currency !== null &&
              dataValues.currency !== "" &&
              typeof dataValues.currency !== "undefined"
            ) {
              form.setFieldValue("currency", dataValues.currency);
            } else {
              form.setFieldValue("currency", "");
            }
            if (
              dataValues.quantityPriceType !== null &&
              dataValues.quantityPriceType !== "" &&
              typeof dataValues.quantityPriceType !== "undefined"
            ) {
              form.setFieldValue(
                "quantityPriceType",
                dataValues.quantityPriceType
              );
            } else {
              form.setFieldValue("quantityPriceType", "");
            }
            if (
              dataValues.pricingAction !== null &&
              dataValues.pricingAction !== "" &&
              typeof dataValues.pricingAction !== "undefined"
            ) {
              form.setFieldValue("pricingAction", dataValues.pricingAction);
            } else {
              form.setFieldValue("pricingAction", "");
            }
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }

        const response2 = await handleGetProductType();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setProductType(clean);
        }

        const response3 = await handleGetFlavours();
        if (response3.status === 200) {
          var data = response3.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setFlavour(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Handle add function
  const handleCreateAmazonSpecifications = async (e) => {
    if (e.value == "") {
      setVariables({
        ...variables,
        submitLoading: true,
        skeletonLoading: true,
      });
      const response = await handleAddAmazonSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Amazon specifications updated successfully",
        });
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    } else {
      setVariables({ ...variables, submitLoading: true });
      const response = await handleEditAmazonSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Amazon specifications updated successfully",
        });
        setVariables({ ...variables, submitLoading: true });
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    }
  };

  const handleProductTypeChange = (e) => {
    setSelectedProductType(e);
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={form.onSubmit((values) =>
                handleCreateAmazonSpecifications(values)
              )}
            >
              <Accordion
                transitionDuration={1000}
                variant="separated"
                defaultValue="first"
              >
                {/* Required Information */}
                <Accordion.Item value="first">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Required Information
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Product Type"
                          value={form.values.productType}
                          data={productType}
                          searchable
                          clearable
                          onSearchChange={handleProductTypeChange}
                          placeholder="Product Type"
                          {...form.getInputProps("productType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Product Id Type Nutritional"
                            value={form.values.ProductIDType}
                            searchable
                            clearable
                            data={["GTIN", "EAN", "UPC", "ASIN"]}
                            placeholder="Product Id Type Nutritional"
                            {...form.getInputProps("ProductIDType")}
                          />
                        ) : (
                          <Select
                            label="Product Id Type Nutritional"
                            value={form.values.ProductIDType}
                            searchable
                            clearable
                            data={["GTIN", "EAN", "PZN", "GCID", "UPC", "ASIN"]}
                            placeholder="Product Id Type Nutritional"
                            {...form.getInputProps("ProductIDType")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Color Map"
                          value={form.values.colorMap}
                          searchable
                          clearable
                          data={[
                            "Beige",
                            "Black",
                            "Blue",
                            "Brown",
                            "Clear",
                            "Gold",
                            "Green",
                            "Grey",
                            "Ivory",
                            "Multi",
                            "Orange",
                            "Pink",
                            "Purple",
                            "Red",
                            "Silver",
                            "White",
                            "Yellow",
                          ]}
                          placeholder="Color Map"
                          {...form.getInputProps("colorMap")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Item Form"
                          value={form.values.itemForm}
                          searchable
                          clearable
                          data={[
                            "Soft Gel",
                            "Granule",
                            "Aerosol",
                            "Powder",
                            "Capsule",
                            "Gel",
                            "Cream",
                            "Chewable",
                            "Wafer",
                            "Tablet",
                            "Gummy",
                            "Bar",
                            "Flake",
                            "Caplet",
                            "Liquid",
                          ]}
                          placeholder="Item Form"
                          {...form.getInputProps("itemForm")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="PPU Count Unit Of Measure"
                          value={form.values.ppuCountUnitOfMeasure}
                          searchable
                          clearable
                          data={[
                            "Count",
                            "Grams",
                            "Metre's",
                            "milliliter's",
                            "Square Metre",
                          ]}
                          placeholder="PPU Count Unit Of Measure"
                          {...form.getInputProps("ppuCountUnitOfMeasure")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Country Of Origin"
                          value={form.values.countryOfOrigin}
                          searchable
                          clearable
                          data={region}
                          placeholder="Country Of Origin"
                          {...form.getInputProps("countryOfOrigin")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Contains Liquid Content?"
                          value={form.values.containsLiquidContent}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Contains Liquid Content?"
                          {...form.getInputProps("containsLiquidContent")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is The Item Heat Sensitive?"
                          value={form.values.isTheItemHeatSensitive}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is The Item Heat Sensitive?"
                          {...form.getInputProps("isTheItemHeatSensitive")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Target Gender"
                          value={form.values.targetGender}
                          searchable
                          clearable
                          data={["Male", "Female", "Unisex"]}
                          placeholder="Target Gender"
                          {...form.getInputProps("targetGender")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Age Range Description"
                          value={form.values.ageRangeDescription}
                          searchable
                          clearable
                          data={["Adult", "Infant", "Toddler", "Child", "Teen"]}
                          placeholder="Age Range Description"
                          {...form.getInputProps("ageRangeDescription")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Variation Information */}
                <Accordion.Item value="second">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Variation Information
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Parent Age"
                          value={form.values.percentage}
                          data={["Parent", "Child"]}
                          placeholder="Parent Age"
                          {...form.getInputProps("percentage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Relational Ship Type"
                            value={form.values.relationalShipType}
                            data={[
                              "Color",
                              "Color Flavor",
                              "colorname-numberofitems",
                              "Colour & Scent",
                              "Flavor",
                              "flavorname-numberofitems",
                              "FlavorName-SizeName",
                              "Flavor-Size",
                              "itemform",
                              "itemform-sizename",
                              "itemweight",
                              "model",
                              "model-sizename",
                              "numberofitems",
                              "Scent",
                              "size",
                              "Size",
                              "Size-Color",
                              "sizename-colorname-numberofitems",
                              "sizename-numberofitems",
                              "Size-Scent",
                              "size-unitcount",
                              "stylename",
                            ]}
                            placeholder="Relational Ship Type"
                            {...form.getInputProps("relationalShipType")}
                          />
                        ) : (
                          <Select
                            label="Relational Ship Type"
                            value={form.values.relationalShipType}
                            data={[
                              "Flavor",
                              "Size",
                              "size",
                              "size-unitcount",
                              "Scent",
                              "model-sizename",
                              "model",
                              "itemweight",
                              "Size-Scent",
                              "Flavor-Size",
                              "Color",
                              "color-size",
                              "Size-Color",
                              "Colour & Scent",
                              "colorflavor",
                            ]}
                            placeholder="Relational Ship Type"
                            {...form.getInputProps("relationalShipType")}
                          />
                        )}
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Basic Information */}
                <Accordion.Item value="third">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Basic Information
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Update Delete"
                          value={form.values.updateDelete}
                          data={["Update", "Partial Update", "Delete"]}
                          placeholder="Update Delete"
                          {...form.getInputProps("updateDelete")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Product Exemption Reason"
                          value={form.values.productExemptionReason}
                          data={[
                            "Manufacture on Demand",
                            "Plan Item",
                            "Refurbished",
                            "Custom Product Bundle",
                            "Replacement Part",
                            "Pre-Order",
                          ]}
                          placeholder="Product Exemption Reason"
                          {...form.getInputProps("productExemptionReason")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Discovery */}
                <Accordion.Item value="fourth">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Discovery Information
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Special Ingredients"
                          value={form.values.specialIngredients}
                          data={[
                            "Taurine",
                            "Astaxanthin",
                            "Royal Jelly",
                            "Dimethylethanolamine",
                            "Thyroid Extract",
                            "Creatine",
                            "Docosahexaenoic acid",
                            "Lipase",
                            "Fish Oil",
                            "7-Ketodehydroepiandrosterone",
                            "Acetylcarnitin",
                            "Lipoic Acid",
                            "Liver Extract",
                            "5-Hydroxytryptophan",
                            "L-Leucine",
                            "Psyllium",
                            "L-Citrulline",
                            "Medium Chain Triglycerides Oil",
                            "Lutein",
                            "Lycopene",
                            "Bromelain",
                            "Conjugated Linoleic Acid",
                            "Adrenal Extract",
                            "Glandular Extract",
                            "L-Lysine",
                            "L-Arginine",
                            "Amino Acid",
                            "Glucosamine",
                            "Hyaluronic Acid",
                            "Lecithin",
                            "Coenzyme Q",
                            "Carotenoids",
                            "Branched-Chain Amino Acid",
                            "Beta Carotene",
                            "Resveratrol",
                            "L-Carnitine",
                            "Krill Oil",
                            "Probiotic",
                            "Black Currant Oil",
                            "Lactase",
                            "Chondroitin",
                            "Flax Seed Oil",
                            "Black Seed Oil",
                            "L-Glutamine",
                            "Collagen",
                            "Acidophilus",
                            "Beta-Alanine",
                            "Methylsulfonylmethane",
                            "Spleen Extract",
                            "Eicosapentaenoic acid",
                            "Prebiotic",
                            "Charcoal",
                          ]}
                          searchable
                          clearable
                          placeholder="Additional Product Attribute Name"
                          {...form.getInputProps("specialIngredients")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Flavour"
                          value={form.values.flavour}
                          searchable
                          clearable
                          data={flavour}
                          placeholder="Flavour"
                          {...form.getInputProps("flavour")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Serving Quantity Unit"
                          value={form.values.servingQuantityUnit}
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          searchable
                          clearable
                          placeholder="Serving Quantity Unit"
                          {...form.getInputProps("servingQuantityUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Carbohydrate Unit"
                          value={form.values.carbohydrateUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Carbohydrate Unit"
                          {...form.getInputProps("carbohydrateUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin & Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Vitamin & Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Melting Temperature Unit"
                          value={form.values.meltingTemperatureUnit}
                          searchable
                          clearable
                          data={[
                            "Degree FahrenHeit",
                            "Kelvin",
                            "Degree Celsius",
                          ]}
                          placeholder="Melting Temperature Unit"
                          {...form.getInputProps("meltingTemperatureUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Diet Type"
                          value={form.values.dietType}
                          searchable
                          clearable
                          data={["Vegetarian", "Non Vegetarian"]}
                          placeholder="Diet Type"
                          {...form.getInputProps("dietType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Product Benefits"
                          value={form.values.productBenefits}
                          searchable
                          clearable
                          data={[
                            "Anti-Aging",
                            "Energy Management",
                            "Skin Hydrating",
                            "Muscle Growth",
                            "Cholesterol Management",
                            "Stomach Health Support",
                            "Liver Health Support",
                            "Weight Loss Support",
                            "Acne Treatment",
                            "Digestive Health Support",
                            "Metabolism Management",
                            "Stress Relief",
                            "Eye Health Support",
                            "Hair Growth",
                            "Immune Support",
                            "Sleep Support",
                            "Blood Pressure Management",
                            "Bone & Joint Support",
                            "Dark Spot Treatment",
                            "Detoxing",
                            "Nail Growth",
                            "pH Balance",
                            "Prenatal Support",
                            "Heart Health Support",
                            "Skin Brightening",
                            "Dental Health Support",
                            "Wrinkle Treatment",
                            "Inflammation Management",
                            "Menstruation Support",
                            "Odor Control",
                            "Thyroid Support",
                            "Brain Health Support",
                          ]}
                          placeholder="Product Benefits"
                          {...form.getInputProps("productBenefits")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Material Type Free"
                          value={form.values.materialType}
                          searchable
                          clearable
                          data={[
                            "Sodium Free",
                            "MSG Free",
                            "Magnesium Steerage Free",
                            "Lactose Free",
                            "Yeast Free",
                            "Sugar Free",
                            "Corn Free",
                            "No Artificial Preservatives",
                            "Talc Free",
                            "No Artificial Flavours",
                            "BPA Free",
                            "Gluten Free",
                            "Alcohol Free",
                            "Cholesterol Free",
                            "Peanut Free",
                            "Egg Free",
                            "Shellfish Free",
                            "Titanium Dioxide Free",
                            "Dairy Free",
                            "Fish Free",
                            "Caffeine Free",
                            "Tree Nuts Free",
                            "Iron Free",
                            "No Artificial Sweeteners",
                            "Starch Free",
                            "Nut Free",
                            "Soy Free",
                            "No Artificial Colors",
                          ]}
                          placeholder="Material Type Free"
                          {...form.getInputProps("materialType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Protein Unit"
                          value={form.values.proteinUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Protein Unit"
                          {...form.getInputProps("proteinUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Included Components"
                          value={form.values.includedComponents}
                          searchable
                          clearable
                          data={[
                            "30-Minute Charger",
                            "Bag",
                            "Blade",
                            "Carbineer",
                            "Case",
                            "Charger",
                            "Detector",
                            "Fuse",
                            "Hammer",
                            "Hose",
                            "Light",
                            "One Hour Charger",
                            "Pads",
                            "Pocket Clips",
                            "Quick Connect Fixtures",
                            "Shade protector",
                            "Side Handle",
                            "Surge protection",
                            "Switch",
                          ]}
                          placeholder="Included Components"
                          {...form.getInputProps("includedComponents")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Product Enrichment */}
                <Accordion.Item value="Fifth">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Product Enrichment
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Material Features"
                          value={form.values.materialFeatures}
                          searchable
                          clearable
                          data={[
                            " Certified Organic",
                            "GMO Free",
                            "Vegetarian",
                            "Kosher Certified",
                            "Cruelty Free",
                            "Vegan",
                            "No Artificial Preservatives",
                            "No Artificial Sweeteners",
                            "No Artificial Flavours",
                            "Gluten Free",
                            "Low Carb",
                            "Plant Based",
                            "Low Sodium",
                            "No Artificial Colours",
                          ]}
                          placeholder="Material Features"
                          {...form.getInputProps("materialFeatures")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Calcium Per Serving Unit Of Measure"
                          value={form.values.calciumPerServingUnitOfMeasure}
                          searchable
                          clearable
                          data={["Percent Daily Value FDA", "Milligrams"]}
                          placeholder="Calcium Per Serving Unit Of Measure"
                          {...form.getInputProps(
                            "calciumPerServingUnitOfMeasure"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={["Yet to get"]}
                          placeholder="Vitamin Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Flash Point Unit Of Measure"
                          value={form.values.flashPointUnitOfMeasure}
                          searchable
                          clearable
                          data={[
                            "Degree FahrenHeit",
                            "Kelvin",
                            "Degree Celsius",
                          ]}
                          placeholder="Flash Point Unit Of Measure"
                          {...form.getInputProps("flashPointUnitOfMeasure")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="FC Shelf Life Unit(Days)"
                          value={form.values.fcShelfLifeUnit}
                          placeholder="FC Shelf Life Unit(Days)"
                          {...form.getInputProps("fcShelfLifeUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Skip Offer"
                          value={form.values.skipOffer}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Skip Offer"
                          {...form.getInputProps("skipOffer")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is The Liquid Product Double Sealed?"
                          value={form.values.isTheLiquidProductDoubleSealed}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is The Liquid Product Double Sealed?"
                          {...form.getInputProps(
                            "isTheLiquidProductDoubleSealed"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Fat Unit"
                          value={form.values.fatUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Fat Unit"
                          {...form.getInputProps("fatUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Vitamin Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Vitamin Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Vitamin Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Vitamin Mineral Unit"
                          value={form.values.vitaminMineralUnit}
                          searchable
                          clearable
                          data={[
                            "Pills",
                            "Teaspoons",
                            "Food Bars",
                            "Percentage Daily Value FDA",
                            "Milliliters",
                            "Milligrams",
                            "Bars",
                            "Ampules",
                            "Scoops",
                            "Grams",
                            "Capsules",
                            "Portions",
                          ]}
                          placeholder="Vitamin Mineral Unit"
                          {...form.getInputProps("vitaminMineralUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Container Type"
                          value={form.values.containerType}
                          searchable
                          clearable
                          data={[
                            "Tub",
                            "Bottle",
                            "Bag",
                            "Box",
                            "Canister",
                            "Jar",
                            "Stick",
                            "Pack",
                          ]}
                          placeholder="Container Type"
                          {...form.getInputProps("containerType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is Exempt From Supplier Declared External Product Identifier"
                          value={
                            form.values
                              .isExemptFromSupplierDeclaredExternalProductIdentifier
                          }
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is Exempt From Supplier Declared External Product Identifier"
                          {...form.getInputProps(
                            "isExemptFromSupplierDeclaredExternalProductIdentifier"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Energy Unit"
                          value={form.values.energyUnit}
                          searchable
                          clearable
                          data={[
                            "Milliampere Second (mAs)",
                            "Kilowatt Hours",
                            "Cubic Feet",
                            "Milliamp Hours (mAh)",
                            "Milliampere Hour (mAh)",
                            "Watt Hours",
                            "British Thermal Units (BTUs)",
                            "Cubic Meters",
                            "Joules",
                          ]}
                          placeholder="Energy Unit"
                          {...form.getInputProps("energyUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Minimum Advertised Price Display"
                          value={form.values.minimumAdvertisedPriceDisplay}
                          searchable
                          clearable
                          data={[
                            "Policy 7",
                            "Policy 5",
                            "Policy 3",
                            "Policy 4",
                            "Policy 1",
                            "Policy 2",
                            "Policy 9",
                            "Policy 10",
                            "Policy 6",
                            "Policy 8",
                            "Policy 11",
                          ]}
                          placeholder="Minimum Advertised Price Display"
                          {...form.getInputProps(
                            "minimumAdvertisedPriceDisplay"
                          )}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Dimensions */}
                <Accordion.Item value="sixth">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Dimensions
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Item Length Unit Of Measure"
                            value={form.values.itemLengthUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Length Unit Of Measure"
                            {...form.getInputProps("itemLengthUnitOfMeasure")}
                          />
                        ) : (
                          <Select
                            label="Item Length Unit Of Measure"
                            value={form.values.itemLengthUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Length Unit Of Measure"
                            {...form.getInputProps("itemLengthUnitOfMeasure")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Item Width Unit Of Measure"
                            value={form.values.itemWidthUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Width Unit Of Measure"
                            {...form.getInputProps("itemWidthUnitOfMeasure")}
                          />
                        ) : (
                          <Select
                            label="Item Width Unit Of Measure"
                            value={form.values.itemWidthUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Width Unit Of Measure"
                            {...form.getInputProps("itemWidthUnitOfMeasure")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Item Height Unit Of Measure"
                            value={form.values.itemHeightUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Height Unit Of Measure"
                            {...form.getInputProps("itemHeightUnitOfMeasure")}
                          />
                        ) : (
                          <Select
                            label="Item Height Unit Of Measure"
                            value={form.values.itemHeightUnitOfMeasure}
                            searchable
                            clearable
                            data={[
                              "Feet",
                              "CM",
                              "MM",
                              "DM",
                              "Picometer",
                              "µM",
                              "M",
                              "Miles",
                              "Yards",
                              "Mils",
                              "IN",
                              "Nanometer",
                              "Hundredths-Inches",
                              "Kilometers",
                              "Angstrom",
                            ]}
                            placeholder="Item Height Unit Of Measure"
                            {...form.getInputProps("itemHeightUnitOfMeasure")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Liquid Volume Unit"
                          value={form.values.liquidVolumeUnit}
                          searchable
                          clearable
                          data={[
                            "Centiliters",
                            "Liters",
                            "Quarts",
                            "Fluid Ounces",
                            "Imperial Gallons",
                            "Unknown Modifier",
                            "Milliliter",
                            "Cubic Feet",
                            "Micro Litres",
                            "Deciliters",
                            "Cubic Centimeter",
                            "Cubic Meter",
                            "cubic-yd",
                            "Pints",
                            "Picoliter",
                            "Gallons",
                            "Cubic Inches",
                            "Nano Litres",
                            "Cups",
                          ]}
                          placeholder="Liquid Volume Unit"
                          {...form.getInputProps("liquidVolumeUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Load Capacity Unit Of Measure"
                          value={form.values.loadCapacityUnitOfMeasure}
                          searchable
                          clearable
                          data={[
                            "Pounds",
                            "Kilograms",
                            "Grams",
                            "Hundredths Pounds",
                            "Milligrams",
                            "Tons",
                            "Ounces",
                          ]}
                          placeholder="Load Capacity Unit Of Measure"
                          {...form.getInputProps("loadCapacityUnitOfMeasure")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Wig Length"
                          value={form.values.wigLength}
                          searchable
                          clearable
                          data={[
                            "Large",
                            "Medium",
                            "Small",
                            "X-Large",
                            "X-Small",
                            "XX-Large",
                            "XX-Small",
                            "XXX-Large",
                            "XXX-Small",
                            "XXXX-Large",
                            "XXXX-Small",
                            "XXXXX-Large",
                            "XXXXX-Small",
                          ]}
                          placeholder="Wig Length"
                          {...form.getInputProps("wigLength")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Compliance */}
                <Accordion.Item value="seventh">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Compliance
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Batteries are Included"
                          value={form.values.batteriesAreIncluded}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Batteries are Included"
                          {...form.getInputProps("batteriesAreIncluded")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is this product a battery or does it use batteries?"
                          value={
                            form.values
                              .isThisProductABatteryOrDoesItUseBatteries
                          }
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is this product a battery or does it use batteries?"
                          {...form.getInputProps(
                            "isThisProductABatteryOrDoesItUseBatteries"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Battery composition"
                            value={form.values.batteryComposition}
                            searchable
                            clearable
                            data={[
                              "Polymer",
                              "Silver Oxide",
                              "Silver-Zinc",
                              "Zinc Chloride",
                              "Lithium-Cobalt",
                              "Lithium-Phosphate",
                              "Lead-Acid",
                              "AGM",
                              "Lithium-Thionyl Chloride (Li-SOCL2)",
                              "Manganese",
                              "Lithium Polymer",
                              "Sealed Lead Acid",
                              "Lithium Metal",
                              "NiCAD",
                              "Lithium",
                              "Lithium-Nickel Cobalt Aluminium (NCA)",
                              "Lead Calcium",
                              "	Lithium-Nickel Manganese Cobalt (NMC)",
                              "Aluminum Oxygen",
                              "Mercury Oxide",
                              "Nickel-Iron",
                              "Zinc",
                              "Lithium Ion",
                              "Lithium-Air",
                              "Alkaline",
                              "Lithium Manganese Dioxide",
                              "Nickel Oxyhydroxide",
                              "Zinc Carbon",
                              "NiMh",
                              "Nickel-Zinc",
                              "Lithium-Titanate",
                              "Silver-Calcium",
                              "Zinc-Air",
                              "Lead Acid",
                            ]}
                            placeholder="Battery composition"
                            {...form.getInputProps("batteryComposition")}
                          />
                        ) : (
                          <Select
                            label="Battery composition"
                            value={form.values.batteryComposition}
                            searchable
                            clearable
                            data={[
                              "Polymer",
                              "Silver Oxide",
                              "Silver-Zinc",
                              "Zinc Chloride",
                              "Lithium-Cobalt",
                              "Lithium-Phosphate",
                              "Lead-Acid",
                              "AGM",
                              "Lithium-Thionyl Chloride (Li-SOCL2)",
                              "Manganese",
                              "Lithium Polymer",
                              "Sealed Lead Acid",
                              "Lithium Metal",
                              "NiCAD",
                              "Lithium",
                              "Lithium-Nickel Cobalt Aluminium (NCA)",
                              "Lead Calcium",
                              "	Lithium-Nickel Manganese Cobalt (NMC)",
                              "Aluminum Oxygen",
                              "Mercury Oxide",
                              "Nickel-Iron",
                              "Zinc",
                              "Lithium Ion",
                              "Lithium-Air",
                              "Alkaline",
                              "Lithium Manganese Dioxide",
                              "Nickel Oxyhydroxide",
                              "Zinc Carbon",
                              "NiMh",
                              "Nickel-Zinc",
                              "Lithium-Titanate",
                              "Silver-Calcium",
                              "Zinc-Air",
                              "Lead Acid",
                            ]}
                            placeholder="Battery composition"
                            {...form.getInputProps("batteryComposition")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Lithium Battery Energy Content Unit Of Measure"
                            value={
                              form.values
                                .lithiumBatteryEnergyContentUnitOfMeasure
                            }
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Lithium Battery Energy Content Unit Of Measure"
                            {...form.getInputProps(
                              "lithiumBatteryEnergyContentUnitOfMeasure"
                            )}
                          />
                        ) : (
                          <Select
                            label="Lithium Battery Energy Content Unit Of Measure"
                            value={
                              form.values
                                .lithiumBatteryEnergyContentUnitOfMeasure
                            }
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Lithium Battery Energy Content Unit Of Measure"
                            {...form.getInputProps(
                              "lithiumBatteryEnergyContentUnitOfMeasure"
                            )}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Lithium Battery Packaging"
                          value={form.values.lithiumBatteryPackaging}
                          searchable
                          clearable
                          data={[
                            "batteries_packed_with_equipment",
                            "batteries_contained_in_equipment",
                            "batteries_only",
                          ]}
                          placeholder="Lithium Battery Packaging"
                          {...form.getInputProps("lithiumBatteryPackaging")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Lithium Battery Weight Unit Of Measure"
                            value={
                              form.values.lithiumBatteryWeightUnitOfMeasure
                            }
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Lithium Battery Weight Unit Of Measure"
                            {...form.getInputProps(
                              "lithiumBatteryWeightUnitOfMeasure"
                            )}
                          />
                        ) : (
                          <Select
                            label="Lithium Battery Weight Unit Of Measure"
                            value={
                              form.values.lithiumBatteryWeightUnitOfMeasure
                            }
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Lithium Battery Weight Unit Of Measure"
                            {...form.getInputProps(
                              "lithiumBatteryWeightUnitOfMeasure"
                            )}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Usage"
                          value={form.values.usage}
                          searchable
                          clearable
                          data={["Not Applicable"]}
                          placeholder="Usage"
                          {...form.getInputProps("usage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Allergen Information"
                          value={form.values.allergenInformation}
                          searchable
                          clearable
                          data={["Not Applicable"]}
                          placeholder="Allergen Information"
                          {...form.getInputProps("allergenInformation")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Battery type/size"
                            value={form.values.batteryTypeSize}
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Battery type/size"
                            {...form.getInputProps("batteryTypeSize")}
                          />
                        ) : (
                          <Select
                            label="Battery type/size"
                            value={form.values.batteryTypeSize}
                            searchable
                            clearable
                            data={["Not Applicable"]}
                            placeholder="Battery type/size"
                            {...form.getInputProps("batteryTypeSize")}
                          />
                        )}
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Offer */}
                <Accordion.Item value="eighth">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Offer
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Item Condition"
                            value={form.values.itemCondition}
                            searchable
                            clearable
                            data={["New"]}
                            placeholder="Item Condition"
                            {...form.getInputProps("itemCondition")}
                          />
                        ) : (
                          <Select
                            label="Item Condition"
                            value={form.values.itemCondition}
                            searchable
                            clearable
                            data={[
                              "New OEM",
                              "Collectible - Very Good",
                              "Collectible - Good",
                              "New",
                              "Used - Good",
                              "Club",
                              "Collectible - Acceptable",
                              "new, open_box",
                              "Collectible - Like New",
                              "Used - Like New",
                              "Used - Very Good",
                              "Used - Acceptable",
                            ]}
                            placeholder="Item Condition"
                            {...form.getInputProps("itemCondition")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <Select
                            label="Product Tax Code"
                            value={form.values.productTaxCode}
                            searchable
                            clearable
                            data={[
                              "A_FOOD_CEREALBARS",
                              "A_HPC_SANITARYPRODUCTS",
                              "A_BOOK_MAGAZINE",
                              "A_FOOD_PLAINBISCUIT",
                              "A_FOOD_SPREAD",
                              "A_HPC_CONTRACEPTIVE",
                              "A_FOOD_PLAINCRACKER",
                              "A_HLTH_SMOKINGGUM",
                              "A_OUTDOOR_LAWNCONTROL",
                              "A_CLTH_CHILD",
                              "A_BABY_NAPPIES",
                              "A_HPC_MEDICINE",
                              "A_FOOD_PASTRYCASE",
                              "A_FOOD_NAAN",
                              "A_HPC_DIETARYSUPPL",
                              "A_FOOD_DAIRY",
                              "A_BOOK_ATLAS",
                              "A_FOOD_CANFRUIT",
                              "A_HLTH_VITAMINS",
                              "A_HPC_WALKINGSTICK",
                              "A_FOOD_CAKEDECOR",
                              "A_FOOD_FLOUR",
                              "A_FOOD_PLAINNUT",
                              "A_BOOK_GLOBE",
                              "A_HLTH_PILLCAPSULETABLET",
                              "A_Gen_Standard",
                              "A_FOOD_TEA",
                              "A_FOOD_DRIEDFRUIT",
                              "A_FOOD_MEATCHICKEN",
                              "A_BABY_BIBCLOTH",
                              "A_HLTH_NUTRITIONBAR",
                              "A_HLTH_NUTRITIONDRINK",
                              "A_FOOD_ANIMALFOOD",
                              "A_HLTH_SMOKINGCESSATION",
                              "A_OUTDOOR_SEEDS",
                              "A_BOOKS_GEN",
                              "A_BOOK_ADULT",
                              "A_FOOD_SEASONINGS",
                              "A_FOOD_CHOCEREAL",
                              "A_FOOD_PASTANOODLE",
                              "A_FOOD_PETFOOD",
                              "A_FOOD_ANIMALMED",
                              "A_FOOD_ANIMALVITAMINS",
                              "A_CLTH_PROTECTIVE",
                              "A_OUTDOOR_FERTILIZER",
                              "A_FOOD_COFFEE",
                              "A_FOOD_RICE",
                              "A_HPC_INCONTINENCE",
                              "A_BABY_CARSEAT",
                              "A_BOOK_MAP",
                              "A_FOOD_MISCBEVERAGE",
                              "A_FOOD_SNACK",
                              "A_CLTH_BABY",
                              "A_Gen_Notax",
                              "A_FOOD_OIL",
                              "A_FOOD_GEN",
                              "A_FOOD_SODAJUICE",
                              "A_OUTDOOR_PLANTS",
                              "A_FOOD_NCARBWTR",
                              "A_FOOD_CNDY",
                              "A_FOOD_DESSERT",
                              "A_BOOK_AUDIOBOOK",
                              "A_FOOD_SWEETNER",
                              "A_FOOD_CEREALCHOCBARS",
                              "A_FOOD_VEGETABLE",
                              "A_OUTDOOR_PLANTFOOD",
                            ]}
                            placeholder="Product Tax Code"
                            {...form.getInputProps("productTaxCode")}
                          />
                        ) : (
                          <Select
                            label="Product Tax Code"
                            value={form.values.productTaxCode}
                            searchable
                            clearable
                            data={[
                              "A_GEN_EXEMPT",
                              "A_GEN_JEWELRY",
                              "A_GEN_SUPERSEDURE",
                              "A_GEN_REDUCED",
                              "A_Gen_Standard",
                              "A_GEN_PEAK",
                              "A_GEN_PEAK_CESS12",
                              "A_GEN_PEAK_CESS60",
                            ]}
                            placeholder="Product Tax Code"
                            {...form.getInputProps("productTaxCode")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is Gift Wrap Available?"
                          value={form.values.isGiftWrapAvailable}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is Gift Wrap Available?"
                          {...form.getInputProps("isGiftWrapAvailable")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        {selectedProductType === "Nutritional Supplement" ? (
                          <TextInput
                            label="Shipping-Template"
                            value={form.values.shippingTemplate}
                            placeholder="Shipping-Template"
                            {...form.getInputProps("shippingTemplate")}
                          />
                        ) : (
                          <TextInput
                            label="Shipping-Template"
                            value={form.values.shippingTemplate}
                            placeholder="Shipping-Template"
                            {...form.getInputProps("shippingTemplate")}
                          />
                        )}
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Product Expiration Type"
                          value={form.values.productExpirationType}
                          searchable
                          clearable
                          data={[
                            "Does Not Expire",
                            "Expiration Date Required",
                            "Expiration On Package",
                            "Production Date Required",
                            "Shelf Life",
                          ]}
                          placeholder="Product Expiration Type"
                          {...form.getInputProps("productExpirationType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Can Be Gift Messaged"
                          value={form.values.canBeGiftMessaged}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Can Be Gift Messaged"
                          {...form.getInputProps("canBeGiftMessaged")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is Product Expiable"
                          value={form.values.isProductExpiable}
                          searchable
                          clearable
                          data={["Yes", "No"]}
                          placeholder="Is Product Expiable"
                          {...form.getInputProps("isProductExpiable")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Currency"
                          value={form.values.currency}
                          placeholder="Currency"
                          {...form.getInputProps("currency")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                {/* Other Information */}
                <Accordion.Item value="ninth">
                  <Accordion.Control>
                    <Text align="left" color="#A61E4D">
                      Other Information
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Quantity Price Type"
                          value={form.values.quantityPriceType}
                          searchable
                          clearable
                          data={["Fixed", "Percent"]}
                          placeholder="Quantity Price Type"
                          {...form.getInputProps("quantityPriceType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Pricing Action"
                          value={form.values.pricingAction}
                          placeholder="Pricing Action"
                          {...form.getInputProps("pricingAction")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
              <Grid>
                <Grid.Col md={12} lg={12} mt={20}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Update Amazon Specification Details
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </Card>
      </Skeleton>
    </>
  );
}

export default AmazonSpecifications;
