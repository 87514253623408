/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import { useEffect, useState } from "react";
import { Anchor, Box, Grid, Image, Text } from "@mantine/core";
import { handleGetRegion } from "../../utils/helpers/apiFunctions";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL_CONSTANTS } from "../../utils/constants/constant";
import HeaderResponsive from "../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../components/mainComponents/FooterSocial";

export default function Region() {
  const navigate = useNavigate();
  const [region, setRegion] = useState([]);
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const response = await handleGetRegion();
        if (response.status === 200) {
          setVariables({
            ...variables,
            submitLoading: false,
            skeletonLoading: false,
          });
          var data = response.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleProductDetails = (e) => {
    const regionId = e;
    if (typeof e != "undefined") {
      navigate(`/${regionId}/products`);
    }
  };

  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      <div
        style={{
          width: "50%",
          margin: "auto",
          marginTop: "12%",
        }}
      >
        <Grid
          style={{
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Grid.Col md={12} lg={12}>
            <Text fw={700} ta="center" my={10}>
              Please select the region
            </Text>
          </Grid.Col>
          {region.map((item, index) => (
            <Grid.Col key={index} md={4} lg={4}>
              <div
                style={{ textAlign: "center" }}
                onClick={() => {
                  handleProductDetails(item.value);
                }}
              >
                <Box>
                  <Image
                    maw={200}
                    mx="auto"
                    radius="md"
                    src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                    alt="Random image"
                  />
                  <Anchor color="zevcore" align="center">
                    {item.label}
                  </Anchor>
                </Box>
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </>
  );
}
