/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Select,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddWalmartSpecification,
  handleEditWalmartSpecification,
  handleGetManufacturers,
  handleGetOneWalmartSpecification,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { DatePickerInput } from "@mantine/dates";
import { useParams } from "react-router-dom";
// Text editor imports starts
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
// Text editor imports ends

function WalmartSpecifications() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [manufacture, setManufacture] = useState([]);
  const [updatedAt, setUpdatedAt] = useState();

  // For form validation
  const form = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      // First
      productIdType: "",
      productIdNumber: "",
      label: "",
      productSku: "",
      brandName: "",
      sellingPrice: "",
      shippingWeight: "",
      // First
      // Second
      siteDescription: "",
      mainImageURL: "",
      // Second
      // Third
      additionalImageURL1: "",
      additionalImageURL2: "",
      additionalImageURL3: "",
      additionalImageURL4: "",
      californiaProp65WarningText: "",
      labelImageContain: "",
      labelImageURL: "",
      ingredientStatement: "",
      keyFeature1: "",
      keyFeature2: "",
      keyFeature3: "",
      manufacturerName: "",
      mrsp: "",
      countPerPack: "",
      measureWithUnit: "",
      flavour: "",
      ageGroup: "",
      healthConcerns: "",
      IsControlledSubstance: "",
      drugFactDosage: "",
      drugFactActive: "",
      supplementDosage: "",
      supplementActive: "",
      activeIngredientName: "",
      activeIngredientPercentage: "",
      inActiveIngredient: "",
      formValue: "",
      instruction: "",
      dosageInstruction: "",
      stopUseIndication: "",
      medicineStrength: "",
      nationalDrugCode: "",
      supplementName: "",
      supplementAmount: "",
      supplementPercentageDailyValue: "",
      servingSize: "",
      sugarPerServing: "",
      nutrientContentClaims: "",
      totalFatPerServing: "",
      totalFatPerServingUnit: "Grams",
      totalCarbohydrate: "",
      totalCarbohydrateUnit: "Grams",
      totalProtein: "",
      totalProteinUnit: "Grams",
      activityStage: "",
      caloriesPerServing: "",
      caloriesPerServingUnit: "Calories",
      primaryIngredient: "",
      symptoms: "",
      dietaryMethod: "",
      servingPerContainer: "",
      bodyPart: "",
      color: "",
      size: "",
      gender: "",
      additionalProductAttributeName: "",
      additionalProductAttributeValue: "",
      // Third
      // Fourth
      variantGroupID: "",
      variantAttributeNames: "",
      IsPrimaryVariant: "",
      swatchVariantAttribute: "",
      swatchImageURL: "",
      // Fourth
      // Fifth
      subValue: "",
      restrictionType: "",
      states: "",
      zipCodes: "",
      fulfillmentLagTime: "",
      ppuQuantityOfUnits: "",
      ppuUnitOfMeasure: "",
      containsElectronicComponent: "",
      containedBatteryType: "",
      containsChemicalAerosolOrPesticide: "",
      multiPackQuantity: "",
      shipsInOriginalPackaging: "",
      mustShipAlone: "",
      localDeliveryDepthIn: "",
      localDeliveryWidthIn: "",
      localDeliveryHeightIn: "",
      localDeliveryWeight: "",
      siteStartDate: new Date(),
      siteEndDate: new Date(),
      additionalOfferAttributeName: "",
      additionalOfferAttributeValue: "",
      externalProductIDType: "",
      externalProductID: "",
      productIdUpdate: "",
      skuUpdate: "",
      // Fifth
    },
  });

  // Editor for description
  const [siteDescription, setSiteDescription] = useState();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Site Description" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("siteDescription", html);
      setSiteDescription(html);
    },
  });

  // Editor for description
  const [warningText, setWarningText] = useState("");
  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Warning Text" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("californiaProp65WarningText", html);
      setWarningText(html);
    },
  });

  // Editor for description
  const [ingredientStatement, setIngredientStatement] = useState("");
  const editor2 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Ingredient Statement" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("ingredientStatement", html);
      setIngredientStatement(html);
    },
  });

  // Editor for description
  const [keyFeature1, setKeyFeature1] = useState("");
  const editor3 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Key Feature-1" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyFeature1", html);
      setKeyFeature1(html);
    },
  });

  // Editor for description
  const [keyFeature2, setKeyFeature2] = useState("");
  const editor4 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Key Feature-1" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyFeature2", html);
      setKeyFeature2(html);
    },
  });

  // Editor for description
  const [keyFeature3, setKeyFeature3] = useState("");
  const editor5 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Key Feature-3" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyFeature3", html);
      setKeyFeature3(html);
    },
  });

  // Editor for description
  const [healthConcerns, setHealthConcerns] = useState("");
  const editor6 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Health Concerns" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("healthConcerns", html);
      setHealthConcerns(html);
    },
  });

  // Editor for description
  const [instruction, setInstruction] = useState("");
  const editor7 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "instruction" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("instruction", html);
      setInstruction(html);
    },
  });

  // Editor for description
  const [dosageInstruction, setDosageInstruction] = useState("");
  const editor8 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Dosage Instructions" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("dosageInstruction", html);
      setDosageInstruction(html);
    },
  });

  // Editor for description
  const [stopUseIndication, setStopUseIndication] = useState("");
  const editor9 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Stop Use Indication" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("stopUseIndication", html);
      setStopUseIndication(html);
    },
  });

  // Editor for description
  const [medicineStrength, setMedicineStrength] = useState("");
  const editor10 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Medicine Strength" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("medicineStrength", html);
      setMedicineStrength(html);
    },
  });

  // Editor for description
  const [primaryIngredient, setPrimaryIngredient] = useState("");
  const editor11 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Primary Ingredient" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("primaryIngredient", html);
      setPrimaryIngredient(html);
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneWalmartSpecification(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            submitLoading: false,
          });
          // First block

          const dataProductValues = response.data.data[0];
          const dataProductDetailsValue = response.data.data[1];
          const actualValue = response.data.data[2];
          form.setFieldValue("label", dataProductValues.label);
          form.setFieldValue("brandName", dataProductValues.brand.label);
          form.setFieldValue("flavour", dataProductValues.flavour.label);
          form.setFieldValue("productSku", dataProductValues.sku);
          if (
            dataProductValues.gtin !== null &&
            dataProductValues.gtin !== "" &&
            typeof dataProductValues.gtin !== "undefined"
          ) {
            form.setFieldValue("productIdNumber", dataProductValues.gtin);
          } else {
            form.setFieldValue("productIdNumber", "");
          }
          if (
            dataProductValues.manufacturer !== null &&
            dataProductValues.manufacturer !== "" &&
            typeof dataProductValues.manufacturer !== "undefined"
          ) {
            form.setFieldValue(
              "manufacturerName",
              dataProductValues.manufacturer.label
            );
          } else {
            form.setFieldValue("manufacturerName", "");
          }
          if (
            dataProductValues.mrsp !== null &&
            dataProductValues.mrsp !== "" &&
            typeof dataProductValues.mrsp !== "undefined"
          ) {
            form.setFieldValue("mrsp", dataProductValues.mrsp);
          } else {
            form.setFieldValue("mrsp", "");
          }
          if (
            dataProductValues.flavour !== null &&
            dataProductValues.flavour !== "" &&
            typeof dataProductValues.flavour !== "undefined"
          ) {
            form.setFieldValue("flavour", dataProductValues.flavour.label);
          } else {
            form.setFieldValue("flavour", "");
          }
          if (
            dataProductValues.productDescription !== null &&
            dataProductValues.productDescription !== "" &&
            typeof dataProductValues.productDescription !== "undefined"
          ) {
            editor?.commands.setContent(dataProductValues.productDescription);
            form.setFieldValue(
              "siteDescription",
              dataProductValues.productDescription
            );
          } else {
            form.setFieldValue("siteDescription", "");
          }
          if (
            dataProductValues.featuredImagePath !== null &&
            dataProductValues.featuredImagePath !== "" &&
            typeof dataProductValues.featuredImagePath !== "undefined"
          ) {
            form.setFieldValue(
              "mainImageURL",
              dataProductValues.featuredImagePath
            );
          } else {
            form.setFieldValue("mainImageURL", "");
          }
          if (
            dataProductValues.keyBenefits !== null &&
            dataProductValues.keyBenefits !== "" &&
            typeof dataProductValues.keyBenefits !== "undefined"
          ) {
            editor3?.commands.setContent(dataProductValues.keyBenefits);
            form.setFieldValue("keyFeature1", dataProductValues.keyBenefits);
          } else {
            form.setFieldValue("keyFeature1", "");
          }

          if (
            actualValue !== null &&
            actualValue !== "" &&
            typeof actualValue !== "undefined"
          ) {
            setUpdatedAt(actualValue.updatedAt);
            form.setFieldValue("value", actualValue.id);
            form.setFieldValue("regionId", actualValue.regionId.toString());
            if (
              actualValue.productIdType !== null &&
              actualValue.productIdType !== "" &&
              typeof actualValue.productIdType !== "undefined"
            ) {
              form.setFieldValue("productIdType", actualValue.productIdType);
            } else {
              form.setFieldValue("productIdType", "");
            }

            if (
              actualValue.sellingPrice !== null &&
              actualValue.sellingPrice !== "" &&
              typeof actualValue.sellingPrice !== "undefined"
            ) {
              form.setFieldValue("sellingPrice", actualValue.sellingPrice);
            } else {
              form.setFieldValue("sellingPrice", "");
            }
            if (
              actualValue.shippingWeight !== null &&
              actualValue.shippingWeight !== "" &&
              typeof actualValue.shippingWeight !== "undefined"
            ) {
              form.setFieldValue("shippingWeight", actualValue.shippingWeight);
            } else {
              form.setFieldValue("shippingWeight", "");
            }
            if (
              actualValue.additionalImageURL1 !== null &&
              actualValue.additionalImageURL1 !== "" &&
              typeof actualValue.additionalImageURL1 !== "undefined"
            ) {
              form.setFieldValue(
                "additionalImageURL1",
                actualValue.additionalImageURL1
              );
            } else {
              form.setFieldValue("additionalImageURL1", "");
            }
            if (
              actualValue.additionalImageURL2 !== null &&
              actualValue.additionalImageURL2 !== "" &&
              typeof actualValue.additionalImageURL2 !== "undefined"
            ) {
              form.setFieldValue(
                "additionalImageURL2",
                actualValue.additionalImageURL2
              );
            } else {
              form.setFieldValue("additionalImageURL2", "");
            }
            if (
              actualValue.additionalImageURL3 !== null &&
              actualValue.additionalImageURL3 !== "" &&
              typeof actualValue.additionalImageURL3 !== "undefined"
            ) {
              form.setFieldValue(
                "additionalImageURL3",
                actualValue.additionalImageURL3
              );
            } else {
              form.setFieldValue("additionalImageURL3", "");
            }
            if (
              actualValue.additionalImageURL4 !== null &&
              actualValue.additionalImageURL4 !== "" &&
              typeof actualValue.additionalImageURL4 !== "undefined"
            ) {
              form.setFieldValue(
                "additionalImageURL4",
                actualValue.additionalImageURL4
              );
            } else {
              form.setFieldValue("additionalImageURL4", "");
            }
            if (
              actualValue.californiaProp65WarningText !== null &&
              actualValue.californiaProp65WarningText !== "" &&
              typeof actualValue.californiaProp65WarningText !== "undefined"
            ) {
              editor1?.commands.setContent(
                actualValue.californiaProp65WarningText
              );
              form.setFieldValue(
                "californiaProp65WarningText",
                actualValue.californiaProp65WarningText
              );
            } else {
              form.setFieldValue("californiaProp65WarningText", "");
            }
            if (
              actualValue.labelImageContain !== null &&
              actualValue.labelImageContain !== "" &&
              typeof actualValue.labelImageContain !== "undefined"
            ) {
              form.setFieldValue(
                "labelImageContain",
                actualValue.labelImageContain
              );
            } else {
              form.setFieldValue("labelImageContain", "");
            }
            if (
              actualValue.labelImageURL !== null &&
              actualValue.labelImageURL !== "" &&
              typeof actualValue.labelImageURL !== "undefined"
            ) {
              form.setFieldValue("labelImageURL", actualValue.labelImageURL);
            } else {
              form.setFieldValue("labelImageURL", "");
            }
            if (
              actualValue.ingredientStatement !== null &&
              actualValue.ingredientStatement !== "" &&
              typeof actualValue.ingredientStatement !== "undefined"
            ) {
              form.setFieldValue(
                "ingredientStatement",
                actualValue.ingredientStatement
              );
              editor2?.commands.setContent(actualValue.ingredientStatement);
            } else {
              form.setFieldValue("ingredientStatement", "");
            }
            if (
              actualValue.keyFeature2 !== null &&
              actualValue.keyFeature2 !== "" &&
              typeof actualValue.keyFeature2 !== "undefined"
            ) {
              editor4?.commands.setContent(actualValue.keyFeature2);
              form.setFieldValue("keyFeature2", actualValue.keyFeature2);
            } else {
              form.setFieldValue("keyFeature2", "");
            }
            if (
              actualValue.keyFeature3 !== null &&
              actualValue.keyFeature3 !== "" &&
              typeof actualValue.keyFeature3 !== "undefined"
            ) {
              editor5?.commands.setContent(actualValue.keyFeature3);
              form.setFieldValue("keyFeature3", actualValue.keyFeature3);
            } else {
              form.setFieldValue("keyFeature3", "");
            }
            if (
              actualValue.countPerPack !== null &&
              actualValue.countPerPack !== "" &&
              typeof actualValue.countPerPack !== "undefined"
            ) {
              form.setFieldValue("countPerPack", actualValue.countPerPack);
            } else {
              form.setFieldValue("countPerPack", "");
            }
            if (
              actualValue.measureWithUnit !== null &&
              actualValue.measureWithUnit !== "" &&
              typeof actualValue.measureWithUnit !== "undefined"
            ) {
              form.setFieldValue(
                "measureWithUnit",
                actualValue.measureWithUnit
              );
            } else {
              form.setFieldValue("measureWithUnit", "");
            }

            if (
              actualValue.ageGroup !== null &&
              actualValue.ageGroup !== "" &&
              typeof actualValue.ageGroup !== "undefined"
            ) {
              form.setFieldValue("ageGroup", actualValue.ageGroup);
            } else {
              form.setFieldValue("ageGroup", "");
            }
            if (
              actualValue.healthConcerns !== null &&
              actualValue.healthConcerns !== "" &&
              typeof actualValue.healthConcerns !== "undefined"
            ) {
              editor6?.commands.setContent(actualValue.healthConcerns);
              form.setFieldValue("healthConcerns", actualValue.healthConcerns);
            } else {
              form.setFieldValue("healthConcerns", "");
            }
            if (
              actualValue.IsControlledSubstance !== null &&
              actualValue.IsControlledSubstance !== "" &&
              typeof actualValue.IsControlledSubstance !== "undefined"
            ) {
              form.setFieldValue(
                "IsControlledSubstance",
                actualValue.IsControlledSubstance
              );
            } else {
              form.setFieldValue("IsControlledSubstance", "");
            }
            if (
              actualValue.drugFactDosage !== null &&
              actualValue.drugFactDosage !== "" &&
              typeof actualValue.drugFactDosage !== "undefined"
            ) {
              form.setFieldValue("drugFactDosage", actualValue.drugFactDosage);
            } else {
              form.setFieldValue("drugFactDosage", "");
            }
            if (
              actualValue.drugFactActive !== null &&
              actualValue.drugFactActive !== "" &&
              typeof actualValue.drugFactActive !== "undefined"
            ) {
              form.setFieldValue("drugFactActive", actualValue.drugFactActive);
            } else {
              form.setFieldValue("drugFactActive", "");
            }
            if (
              actualValue.supplementDosage !== null &&
              actualValue.supplementDosage !== "" &&
              typeof actualValue.supplementDosage !== "undefined"
            ) {
              form.setFieldValue(
                "supplementDosage",
                actualValue.supplementDosage
              );
            } else {
              form.setFieldValue("supplementDosage", "");
            }
            if (
              actualValue.supplementActive !== null &&
              actualValue.supplementActive !== "" &&
              typeof actualValue.supplementActive !== "undefined"
            ) {
              form.setFieldValue(
                "supplementActive",
                actualValue.supplementActive
              );
            } else {
              form.setFieldValue("supplementActive", "");
            }
            if (
              actualValue.activeIngredientName !== null &&
              actualValue.activeIngredientName !== "" &&
              typeof actualValue.activeIngredientName !== "undefined"
            ) {
              form.setFieldValue(
                "activeIngredientName",
                actualValue.activeIngredientName
              );
            } else {
              form.setFieldValue("activeIngredientName", "");
            }
            if (
              actualValue.activeIngredientPercentage !== null &&
              actualValue.activeIngredientPercentage !== "" &&
              typeof actualValue.activeIngredientPercentage !== "undefined"
            ) {
              form.setFieldValue(
                "activeIngredientPercentage",
                actualValue.activeIngredientPercentage
              );
            } else {
              form.setFieldValue("activeIngredientPercentage", "");
            }
            if (
              actualValue.inActiveIngredient !== null &&
              actualValue.inActiveIngredient !== "" &&
              typeof actualValue.inActiveIngredient !== "undefined"
            ) {
              form.setFieldValue(
                "inActiveIngredient",
                actualValue.inActiveIngredient
              );
            } else {
              form.setFieldValue("inActiveIngredient", "");
            }
            if (
              actualValue.formValue !== null &&
              actualValue.formValue !== "" &&
              typeof actualValue.formValue !== "undefined"
            ) {
              form.setFieldValue("formValue", actualValue.formValue);
            } else {
              form.setFieldValue("formValue", "");
            }
            if (
              actualValue.instruction !== null &&
              actualValue.instruction !== "" &&
              typeof actualValue.instruction !== "undefined"
            ) {
              editor7?.commands.setContent(actualValue.instruction);
              form.setFieldValue("instruction", actualValue.instruction);
            } else {
              form.setFieldValue("instruction", "");
            }
            if (
              actualValue.dosageInstruction !== null &&
              actualValue.dosageInstruction !== "" &&
              typeof actualValue.dosageInstruction !== "undefined"
            ) {
              editor8?.commands.setContent(actualValue.dosageInstruction);
              form.setFieldValue(
                "dosageInstruction",
                actualValue.dosageInstruction
              );
            } else {
              form.setFieldValue("dosageInstruction", "");
            }
            if (
              actualValue.stopUseIndication !== null &&
              actualValue.stopUseIndication !== "" &&
              typeof actualValue.stopUseIndication !== "undefined"
            ) {
              editor9?.commands.setContent(actualValue.stopUseIndication);
              form.setFieldValue(
                "stopUseIndication",
                actualValue.stopUseIndication
              );
            } else {
              form.setFieldValue("stopUseIndication", "");
            }
            if (
              actualValue.medicineStrength !== null &&
              actualValue.medicineStrength !== "" &&
              typeof actualValue.medicineStrength !== "undefined"
            ) {
              editor10?.commands.setContent(actualValue.medicineStrength);
              form.setFieldValue(
                "medicineStrength",
                actualValue.medicineStrength
              );
            } else {
              form.setFieldValue("medicineStrength", "");
            }
            if (
              actualValue.nationalDrugCode !== null &&
              actualValue.nationalDrugCode !== "" &&
              typeof actualValue.nationalDrugCode !== "undefined"
            ) {
              form.setFieldValue(
                "nationalDrugCode",
                actualValue.nationalDrugCode
              );
            } else {
              form.setFieldValue("nationalDrugCode", "");
            }
            if (
              actualValue.supplementName !== null &&
              actualValue.supplementName !== "" &&
              typeof actualValue.supplementName !== "undefined"
            ) {
              form.setFieldValue("supplementName", actualValue.supplementName);
            } else {
              form.setFieldValue("supplementName", "");
            }
            if (
              actualValue.supplementAmount !== null &&
              actualValue.supplementAmount !== "" &&
              typeof actualValue.supplementAmount !== "undefined"
            ) {
              form.setFieldValue(
                "supplementAmount",
                actualValue.supplementAmount
              );
            } else {
              form.setFieldValue("supplementAmount", "");
            }
            if (
              actualValue.supplementPercentageDailyValue !== null &&
              actualValue.supplementPercentageDailyValue !== "" &&
              typeof actualValue.supplementPercentageDailyValue !== "undefined"
            ) {
              form.setFieldValue(
                "supplementPercentageDailyValue",
                actualValue.supplementPercentageDailyValue
              );
            } else {
              form.setFieldValue("supplementPercentageDailyValue", "");
            }
            if (
              actualValue.servingSize !== null &&
              actualValue.servingSize !== "" &&
              typeof actualValue.servingSize !== "undefined"
            ) {
              form.setFieldValue("servingSize", actualValue.servingSize);
            } else {
              form.setFieldValue("servingSize", "");
            }
            if (
              actualValue.sugarPerServing !== null &&
              actualValue.sugarPerServing !== "" &&
              typeof actualValue.sugarPerServing !== "undefined"
            ) {
              form.setFieldValue(
                "sugarPerServing",
                actualValue.sugarPerServing
              );
            } else {
              form.setFieldValue("sugarPerServing", "");
            }
            if (
              actualValue.nutrientContentClaims !== null &&
              actualValue.nutrientContentClaims !== "" &&
              typeof actualValue.nutrientContentClaims !== "undefined"
            ) {
              form.setFieldValue(
                "nutrientContentClaims",
                actualValue.nutrientContentClaims
              );
            } else {
              form.setFieldValue("nutrientContentClaims", "");
            }
            if (
              actualValue.totalFatPerServing !== null &&
              actualValue.totalFatPerServing !== "" &&
              typeof actualValue.totalFatPerServing !== "undefined"
            ) {
              form.setFieldValue(
                "totalFatPerServing",
                actualValue.totalFatPerServing
              );
            } else {
              form.setFieldValue("totalFatPerServing", "");
            }
            if (
              actualValue.totalFatPerServingUnit !== null &&
              actualValue.totalFatPerServingUnit !== "" &&
              typeof actualValue.totalFatPerServingUnit !== "undefined"
            ) {
              form.setFieldValue(
                "totalFatPerServingUnit",
                actualValue.totalFatPerServingUnit
              );
            } else {
              form.setFieldValue("totalFatPerServingUnit", "Grams");
            }
            if (
              actualValue.totalCarbohydrate !== null &&
              actualValue.totalCarbohydrate !== "" &&
              typeof actualValue.totalCarbohydrate !== "undefined"
            ) {
              form.setFieldValue(
                "totalCarbohydrate",
                actualValue.totalCarbohydrate
              );
            } else {
              form.setFieldValue("totalCarbohydrate", "");
            }
            if (
              actualValue.totalCarbohydrateUnit !== null &&
              actualValue.totalCarbohydrateUnit !== "" &&
              typeof actualValue.totalCarbohydrateUnit !== "undefined"
            ) {
              form.setFieldValue(
                "totalCarbohydrateUnit",
                actualValue.totalCarbohydrateUnit
              );
            } else {
              form.setFieldValue("totalCarbohydrateUnit", "Grams");
            }
            if (
              actualValue.totalProtein !== null &&
              actualValue.totalProtein !== "" &&
              typeof actualValue.totalProtein !== "undefined"
            ) {
              form.setFieldValue("totalProtein", actualValue.totalProtein);
            } else {
              form.setFieldValue("totalProtein", "");
            }
            if (
              actualValue.totalProteinUnit !== null &&
              actualValue.totalProteinUnit !== "" &&
              typeof actualValue.totalProteinUnit !== "undefined"
            ) {
              form.setFieldValue(
                "totalProteinUnit",
                actualValue.totalProteinUnit
              );
            } else {
              form.setFieldValue("totalProteinUnit", "Grams");
            }
            if (
              actualValue.activityStage !== null &&
              actualValue.activityStage !== "" &&
              typeof actualValue.activityStage !== "undefined"
            ) {
              form.setFieldValue("activityStage", actualValue.activityStage);
            } else {
              form.setFieldValue("activityStage", "");
            }
            if (
              actualValue.caloriesPerServing !== null &&
              actualValue.caloriesPerServing !== "" &&
              typeof actualValue.caloriesPerServing !== "undefined"
            ) {
              form.setFieldValue(
                "caloriesPerServing",
                actualValue.caloriesPerServing
              );
            } else {
              form.setFieldValue("caloriesPerServing", "");
            }
            if (
              actualValue.caloriesPerServingUnit !== null &&
              actualValue.caloriesPerServingUnit !== "" &&
              typeof actualValue.caloriesPerServingUnit !== "undefined"
            ) {
              form.setFieldValue(
                "caloriesPerServingUnit",
                actualValue.caloriesPerServingUnit
              );
            } else {
              form.setFieldValue("caloriesPerServingUnit", "Grams");
            }
            if (
              actualValue.primaryIngredient !== null &&
              actualValue.primaryIngredient !== "" &&
              typeof actualValue.primaryIngredient !== "undefined"
            ) {
              editor11?.commands.setContent(actualValue.primaryIngredient);
              form.setFieldValue(
                "primaryIngredient",
                actualValue.primaryIngredient
              );
            } else {
              form.setFieldValue("primaryIngredient", "");
            }
            if (
              actualValue.symptoms !== null &&
              actualValue.symptoms !== "" &&
              typeof actualValue.symptoms !== "undefined"
            ) {
              form.setFieldValue("symptoms", actualValue.symptoms);
            } else {
              form.setFieldValue("symptoms", "");
            }
            if (
              actualValue.dietaryMethod !== null &&
              actualValue.dietaryMethod !== "" &&
              typeof actualValue.dietaryMethod !== "undefined"
            ) {
              form.setFieldValue("dietaryMethod", actualValue.dietaryMethod);
            } else {
              form.setFieldValue("dietaryMethod", "");
            }
            if (
              actualValue.servingPerContainer !== null &&
              actualValue.servingPerContainer !== "" &&
              typeof actualValue.servingPerContainer !== "undefined"
            ) {
              form.setFieldValue(
                "servingPerContainer",
                actualValue.servingPerContainer
              );
            } else {
              form.setFieldValue("servingPerContainer", "");
            }
            if (
              actualValue.bodyPart !== null &&
              actualValue.bodyPart !== "" &&
              typeof actualValue.bodyPart !== "undefined"
            ) {
              form.setFieldValue("bodyPart", actualValue.bodyPart);
            } else {
              form.setFieldValue("bodyPart", "");
            }
            if (
              actualValue.color !== null &&
              actualValue.color !== "" &&
              typeof actualValue.color !== "undefined"
            ) {
              form.setFieldValue("color", actualValue.color);
            } else {
              form.setFieldValue("color", "");
            }
            if (
              actualValue.size !== null &&
              actualValue.size !== "" &&
              typeof actualValue.size !== "undefined"
            ) {
              form.setFieldValue("size", actualValue.size);
            } else {
              form.setFieldValue("size", "");
            }
            if (
              actualValue.gender !== null &&
              actualValue.gender !== "" &&
              typeof actualValue.gender !== "undefined"
            ) {
              form.setFieldValue("gender", actualValue.gender);
            } else {
              form.setFieldValue("gender", "");
            }
            if (
              actualValue.additionalProductAttributeName !== null &&
              actualValue.additionalProductAttributeName !== "" &&
              typeof actualValue.additionalProductAttributeName !== "undefined"
            ) {
              form.setFieldValue(
                "additionalProductAttributeName",
                actualValue.additionalProductAttributeName
              );
            } else {
              form.setFieldValue("additionalProductAttributeName", "");
            }
            if (
              actualValue.additionalProductAttributeValue !== null &&
              actualValue.additionalProductAttributeValue !== "" &&
              typeof actualValue.additionalProductAttributeValue !== "undefined"
            ) {
              form.setFieldValue(
                "additionalProductAttributeValue",
                actualValue.additionalProductAttributeValue
              );
            } else {
              form.setFieldValue("additionalProductAttributeValue", "");
            }
            // Third block
            // Fourth block
            if (
              actualValue.variantGroupID !== null &&
              actualValue.variantGroupID !== "" &&
              typeof actualValue.variantGroupID !== "undefined"
            ) {
              form.setFieldValue("variantGroupID", actualValue.variantGroupID);
            } else {
              form.setFieldValue("variantGroupID", "");
            }
            if (
              actualValue.variantAttributeNames !== null &&
              actualValue.variantAttributeNames !== "" &&
              typeof actualValue.variantAttributeNames !== "undefined"
            ) {
              form.setFieldValue(
                "variantAttributeNames",
                actualValue.variantAttributeNames
              );
            } else {
              form.setFieldValue("variantAttributeNames", "");
            }
            if (
              actualValue.IsPrimaryVariant !== null &&
              actualValue.IsPrimaryVariant !== "" &&
              typeof actualValue.IsPrimaryVariant !== "undefined"
            ) {
              form.setFieldValue(
                "IsPrimaryVariant",
                actualValue.IsPrimaryVariant
              );
            } else {
              form.setFieldValue("IsPrimaryVariant", "");
            }
            if (
              actualValue.swatchVariantAttribute !== null &&
              actualValue.swatchVariantAttribute !== "" &&
              typeof actualValue.swatchVariantAttribute !== "undefined"
            ) {
              form.setFieldValue(
                "swatchVariantAttribute",
                actualValue.swatchVariantAttribute
              );
            } else {
              form.setFieldValue("swatchVariantAttribute", "");
            }
            if (
              actualValue.swatchImageURL !== null &&
              actualValue.swatchImageURL !== "" &&
              typeof actualValue.swatchImageURL !== "undefined"
            ) {
              form.setFieldValue("swatchImageURL", actualValue.swatchImageURL);
            } else {
              form.setFieldValue("swatchImageURL", "");
            }
            const subActualValue = actualValue.product_walmart2s[0];
            form.setFieldValue("subValue", subActualValue.id.toString());
            form.setFieldValue(
              "walmartId",
              subActualValue.walmartId.toString()
            );
            if (
              subActualValue.restrictionType !== null &&
              subActualValue.restrictionType !== "" &&
              typeof subActualValue.restrictionType !== "undefined"
            ) {
              form.setFieldValue(
                "restrictionType",
                subActualValue.restrictionType
              );
            } else {
              form.setFieldValue("restrictionType", "");
            }
            if (
              subActualValue.states !== null &&
              subActualValue.states !== "" &&
              typeof subActualValue.states !== "undefined"
            ) {
              form.setFieldValue("states", subActualValue.states);
            } else {
              form.setFieldValue("states", "");
            }
            if (
              subActualValue.zipCodes !== null &&
              subActualValue.zipCodes !== "" &&
              typeof subActualValue.zipCodes !== "undefined"
            ) {
              form.setFieldValue("zipCodes", subActualValue.zipCodes);
            } else {
              form.setFieldValue("zipCodes", "");
            }
            if (
              subActualValue.fulfillmentLagTime !== null &&
              subActualValue.fulfillmentLagTime !== "" &&
              typeof subActualValue.fulfillmentLagTime !== "undefined"
            ) {
              form.setFieldValue(
                "fulfillmentLagTime",
                subActualValue.fulfillmentLagTime
              );
            } else {
              form.setFieldValue("fulfillmentLagTime", "");
            }
            if (
              subActualValue.ppuQuantityOfUnits !== null &&
              subActualValue.ppuQuantityOfUnits !== "" &&
              typeof subActualValue.ppuQuantityOfUnits !== "undefined"
            ) {
              form.setFieldValue(
                "ppuQuantityOfUnits",
                subActualValue.ppuQuantityOfUnits
              );
            } else {
              form.setFieldValue("ppuQuantityOfUnits", "");
            }
            if (
              subActualValue.ppuUnitOfMeasure !== null &&
              subActualValue.ppuUnitOfMeasure !== "" &&
              typeof subActualValue.ppuUnitOfMeasure !== "undefined"
            ) {
              form.setFieldValue(
                "ppuUnitOfMeasure",
                subActualValue.ppuUnitOfMeasure
              );
            } else {
              form.setFieldValue("ppuUnitOfMeasure", "");
            }
            if (
              subActualValue.containsElectronicComponent !== null &&
              subActualValue.containsElectronicComponent !== "" &&
              typeof subActualValue.containsElectronicComponent !== "undefined"
            ) {
              form.setFieldValue(
                "containsElectronicComponent",
                subActualValue.containsElectronicComponent
              );
            } else {
              form.setFieldValue("containsElectronicComponent", "");
            }
            if (
              subActualValue.containedBatteryType !== null &&
              subActualValue.containedBatteryType !== "" &&
              typeof subActualValue.containedBatteryType !== "undefined"
            ) {
              form.setFieldValue(
                "containedBatteryType",
                subActualValue.containedBatteryType
              );
            } else {
              form.setFieldValue("containedBatteryType", "");
            }
            if (
              subActualValue.containsChemicalAerosolOrPesticide !== null &&
              subActualValue.containsChemicalAerosolOrPesticide !== "" &&
              typeof subActualValue.containsChemicalAerosolOrPesticide !==
                "undefined"
            ) {
              form.setFieldValue(
                "containsChemicalAerosolOrPesticide",
                subActualValue.containsChemicalAerosolOrPesticide
              );
            } else {
              form.setFieldValue("containsChemicalAerosolOrPesticide", "");
            }
            if (
              subActualValue.multiPackQuantity !== null &&
              subActualValue.multiPackQuantity !== "" &&
              typeof subActualValue.multiPackQuantity !== "undefined"
            ) {
              form.setFieldValue(
                "multiPackQuantity",
                subActualValue.multiPackQuantity
              );
            } else {
              form.setFieldValue("multiPackQuantity", "");
            }
            if (
              subActualValue.shipsInOriginalPackaging !== null &&
              subActualValue.shipsInOriginalPackaging !== "" &&
              typeof subActualValue.shipsInOriginalPackaging !== "undefined"
            ) {
              form.setFieldValue(
                "shipsInOriginalPackaging",
                subActualValue.shipsInOriginalPackaging
              );
            } else {
              form.setFieldValue("shipsInOriginalPackaging", "");
            }
            if (
              subActualValue.mustShipAlone !== null &&
              subActualValue.mustShipAlone !== "" &&
              typeof subActualValue.mustShipAlone !== "undefined"
            ) {
              form.setFieldValue("mustShipAlone", subActualValue.mustShipAlone);
            } else {
              form.setFieldValue("mustShipAlone", "");
            }
            if (
              subActualValue.localDeliveryDepthIn !== null &&
              subActualValue.localDeliveryDepthIn !== "" &&
              typeof subActualValue.localDeliveryDepthIn !== "undefined"
            ) {
              form.setFieldValue(
                "localDeliveryDepthIn",
                subActualValue.localDeliveryDepthIn
              );
            } else {
              form.setFieldValue("localDeliveryDepthIn", "");
            }
            if (
              subActualValue.localDeliveryWidthIn !== null &&
              subActualValue.localDeliveryWidthIn !== "" &&
              typeof subActualValue.localDeliveryWidthIn !== "undefined"
            ) {
              form.setFieldValue(
                "localDeliveryWidthIn",
                subActualValue.localDeliveryWidthIn
              );
            } else {
              form.setFieldValue("localDeliveryWidthIn", "");
            }
            if (
              subActualValue.localDeliveryHeightIn !== null &&
              subActualValue.localDeliveryHeightIn !== "" &&
              typeof subActualValue.localDeliveryHeightIn !== "undefined"
            ) {
              form.setFieldValue(
                "localDeliveryHeightIn",
                subActualValue.localDeliveryHeightIn
              );
            } else {
              form.setFieldValue("localDeliveryHeightIn", "");
            }
            if (
              subActualValue.localDeliveryWeight !== null &&
              subActualValue.localDeliveryWeight !== "" &&
              typeof subActualValue.localDeliveryWeight !== "undefined"
            ) {
              form.setFieldValue(
                "localDeliveryWeight",
                subActualValue.localDeliveryWeight
              );
            } else {
              form.setFieldValue("localDeliveryWeight", "");
            }
            if (
              subActualValue.siteStartDate !== null &&
              subActualValue.siteStartDate !== "" &&
              typeof subActualValue.siteStartDate !== "undefined"
            ) {
              form.setFieldValue("siteStartDate", "");
            } else {
              form.setFieldValue("siteStartDate", "");
            }
            if (
              subActualValue.siteEndDate !== null &&
              subActualValue.siteEndDate !== "" &&
              typeof subActualValue.siteEndDate !== "undefined"
            ) {
              form.setFieldValue("siteEndDate", "");
            } else {
              form.setFieldValue("siteEndDate", "");
            }
            if (
              subActualValue.additionalOfferAttributeName !== null &&
              subActualValue.additionalOfferAttributeName !== "" &&
              typeof subActualValue.additionalOfferAttributeName !== "undefined"
            ) {
              form.setFieldValue(
                "additionalOfferAttributeName",
                subActualValue.additionalOfferAttributeName
              );
            } else {
              form.setFieldValue("additionalOfferAttributeName", "");
            }
            if (
              subActualValue.additionalOfferAttributeValue !== null &&
              subActualValue.additionalOfferAttributeValue !== "" &&
              typeof subActualValue.additionalOfferAttributeValue !==
                "undefined"
            ) {
              form.setFieldValue(
                "additionalOfferAttributeValue",
                subActualValue.additionalOfferAttributeValue
              );
            } else {
              form.setFieldValue("additionalOfferAttributeValue", "");
            }
            if (
              subActualValue.externalProductIDType !== null &&
              subActualValue.externalProductIDType !== "" &&
              typeof subActualValue.externalProductIDType !== "undefined"
            ) {
              form.setFieldValue(
                "externalProductIDType",
                subActualValue.externalProductIDType
              );
            } else {
              form.setFieldValue("externalProductIDType", "");
            }
            if (
              subActualValue.externalProductID !== null &&
              subActualValue.externalProductID !== "" &&
              typeof subActualValue.externalProductID !== "undefined"
            ) {
              form.setFieldValue(
                "externalProductID",
                subActualValue.externalProductID
              );
            } else {
              form.setFieldValue("externalProductID", "");
            }
            if (
              subActualValue.productIdUpdate !== null &&
              subActualValue.productIdUpdate !== "" &&
              typeof subActualValue.productIdUpdate !== "undefined"
            ) {
              form.setFieldValue(
                "productIdUpdate",
                subActualValue.productIdUpdate
              );
            } else {
              form.setFieldValue("productIdUpdate", "");
            }
            if (
              subActualValue.skuUpdate !== null &&
              subActualValue.skuUpdate !== "" &&
              typeof subActualValue.skuUpdate !== "undefined"
            ) {
              form.setFieldValue("skuUpdate", subActualValue.skuUpdate);
            } else {
              form.setFieldValue("skuUpdate", "");
            }
            // Fifth block
          }
        } else {
          setVariables({
            ...variables,
            submitLoading: false,
          });
        }

        const response2 = await handleGetManufacturers();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setManufacture(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [
    editor,
    editor1,
    editor2,
    editor3,
    editor4,
    editor5,
    editor6,
    editor7,
    editor8,
    editor9,
    editor10,
    editor11,
  ]);

  // Handle add function
  const handleAddWalmartSpecifications = async (e) => {
    if (e.value == "") {
      setVariables({ ...variables, submitLoading: true });
      const response = await handleAddWalmartSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Walmart specifications added successfully",
        });
        setVariables({ ...variables, submitLoading: true });
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    } else {
      setVariables({ ...variables, submitLoading: true });
      const response = await handleEditWalmartSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Walmart specifications updated successfully",
        });
        setVariables({ ...variables, submitLoading: true });
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={form.onSubmit((values) =>
                handleAddWalmartSpecifications(values)
              )}
            >
              <Accordion
                transitionDuration={1000}
                variant="separated"
                defaultValue="first"
              >
                <Accordion.Item value="first">
                  <Accordion.Control>
                    <Text align="left" color="blue">
                      Required to sell on Walmart.com
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Product Type ID"
                          value={form.values.productIdType}
                          data={["EAN", "GTIN", "ISBN", "UPC"]}
                          placeholder="Product Type ID"
                          {...form.getInputProps("productIdType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Product ID"
                          value={form.values.productIdNumber}
                          disabled
                          placeholder="Product ID"
                          {...form.getInputProps("productIdNumber")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Product Name"
                          disabled
                          value={form.values.label}
                          placeholder="Product Name"
                          {...form.getInputProps("label")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Product SKU"
                          disabled
                          value={form.values.productSku}
                          placeholder="Product SKU"
                          {...form.getInputProps("productSku")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Brand Name"
                          disabled
                          value={form.values.brandName}
                          placeholder="Brand Name"
                          {...form.getInputProps("brandName")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Selling Price"
                          value={form.values.sellingPrice}
                          placeholder="Selling Price"
                          {...form.getInputProps("sellingPrice")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Shipping Weight(lbs)"
                          value={form.values.shippingWeight}
                          placeholder="Shipping Weight"
                          {...form.getInputProps("shippingWeight")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="second">
                  <Accordion.Control>
                    <Text color="green" align="left">
                      Required for the item to be visible on Walmart.com
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Site Description</Text>
                        <RichTextEditor editor={editor}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={siteDescription}
                            mb={20}
                            {...form.getInputProps("starValue")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <TextInput
                          label="Main Image URL"
                          value={form.values.mainImageURL}
                          disabled
                          placeholder="Main Image URL"
                          {...form.getInputProps("mainImageURL")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="third">
                  <Accordion.Control>
                    <Text color="orange" align="left">
                      Recommended to improve search and browse on Walmart.com
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Additional Image URL-1"
                          value={form.values.additionalImageURL1}
                          placeholder="Additional Image URL"
                          {...form.getInputProps("additionalImageURL1")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Additional Image URL-2"
                          value={form.values.additionalImageURL2}
                          placeholder="Additional Image URL"
                          {...form.getInputProps("additionalImageURL2")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Additional Image URL-3"
                          value={form.values.additionalImageURL3}
                          placeholder="Additional Image URL"
                          {...form.getInputProps("additionalImageURL3")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Additional Image URL-4"
                          value={form.values.additionalImageURL4}
                          placeholder="Additional Image URL"
                          {...form.getInputProps("additionalImageURL4")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>California Prop 65 Warning Text</Text>
                        <RichTextEditor editor={editor1}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={warningText}
                            mb={20}
                            {...form.getInputProps(
                              "californiaProp65WarningText"
                            )}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Select Label Image Contain"
                          value={form.values.labelImageContain}
                          data={[
                            "Drug",
                            "Ingredient List",
                            "No Label",
                            "Nutrition Facts",
                            "Supplement Facts",
                          ]}
                          placeholder="Label Image Contain"
                          {...form.getInputProps("labelImageContain")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Label Image URL"
                          value={form.values.labelImageURL}
                          placeholder="Label Image URL"
                          {...form.getInputProps("labelImageURL")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Ingredient Statement</Text>
                        <RichTextEditor editor={editor2}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={editor2}
                            mb={20}
                            {...form.getInputProps("ingredientStatement")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Key Features-1</Text>
                        <RichTextEditor editor={editor3}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={keyFeature1}
                            mb={20}
                            {...form.getInputProps("keyFeature1")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Key Features-2</Text>
                        <RichTextEditor editor={editor4}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={keyFeature2}
                            mb={20}
                            {...form.getInputProps("keyFeature2")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Key Features-3</Text>
                        <RichTextEditor editor={editor5}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={keyFeature3}
                            mb={20}
                            {...form.getInputProps("keyFeature3")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Manufacturer Name"
                          value={form.values.manufacturerName}
                          disabled
                          data={manufacture}
                          placeholder="Manufacturer Name"
                          {...form.getInputProps("manufacturerName")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="MSRP"
                          value={form.values.mrsp}
                          disabled
                          placeholder="MSRP"
                          {...form.getInputProps("mrsp")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Count Per Pack"
                          value={form.values.countPerPack}
                          placeholder="Count Per Pack"
                          {...form.getInputProps("countPerPack")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Minimum Days of"
                          value={form.values.measureWithUnit}
                          placeholder="Measure With Unit"
                          {...form.getInputProps("measureWithUnit")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Flavour"
                          disabled
                          value={form.values.flavour}
                          placeholder="Flavour"
                          {...form.getInputProps("flavour")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Age Group"
                          value={form.values.ageGroup}
                          data={[
                            "Adult",
                            "Child",
                            "Infant",
                            "New Born",
                            "Preemie",
                            "Senior",
                            "Teen",
                            "Toddler",
                            "Tween",
                          ]}
                          placeholder="Age Group"
                          {...form.getInputProps("ageGroup")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Health Concerns</Text>
                        <RichTextEditor editor={editor6}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={healthConcerns}
                            mb={20}
                            {...form.getInputProps("healthConcerns")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is Controlled Substance?"
                          value={form.values.IsControlledSubstance}
                          placeholder="Is Controlled Substance?"
                          data={["Yes", "No"]}
                          {...form.getInputProps("IsControlledSubstance")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Drug Facts Dosage / Instructions Image"
                          value={form.values.drugFactDosage}
                          placeholder="Drug Facts Dosage / Instructions Image"
                          {...form.getInputProps("drugFactDosage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Drug Facts Active / Inactive Ingredients Image"
                          value={form.values.drugFactActive}
                          placeholder="Drug Facts Active / Inactive Ingredients Image"
                          {...form.getInputProps("drugFactActive")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Supplement Dosage / Instructions Image"
                          value={form.values.supplementDosage}
                          placeholder="Supplement Dosage / Instructions Image"
                          {...form.getInputProps("supplementDosage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Supplement Active / Inactive Ingredients Image"
                          value={form.values.supplementActive}
                          placeholder="Supplement Active / Inactive Ingredients Image"
                          {...form.getInputProps("supplementActive")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Active Ingredient Name"
                          value={form.values.activeIngredientName}
                          placeholder="Active Ingredient Name"
                          {...form.getInputProps("activeIngredientName")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Active Ingredient Percentage"
                          value={form.values.activeIngredientPercentage}
                          placeholder="Active Ingredient Percentage"
                          {...form.getInputProps("activeIngredientPercentage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Inactive Ingredient Percentage"
                          value={form.values.inActiveIngredient}
                          placeholder="Inactive Ingredient Percentage"
                          {...form.getInputProps("inActiveIngredient")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Form"
                          value={form.values.formValue}
                          placeholder="Form"
                          {...form.getInputProps("formValue")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Instructions</Text>
                        <RichTextEditor editor={editor7}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={instruction}
                            mb={20}
                            {...form.getInputProps("instruction")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Dosage Instructions</Text>
                        <RichTextEditor editor={editor8}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={warningText}
                            mb={20}
                            {...form.getInputProps("starValue")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Stop Use Indications (+)</Text>
                        <RichTextEditor editor={editor9}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={stopUseIndication}
                            mb={20}
                            {...form.getInputProps("stopUseIndication")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Medicine Strength</Text>
                        <RichTextEditor editor={editor10}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={medicineStrength}
                            mb={20}
                            {...form.getInputProps("medicineStrength")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="National Drug Code"
                          value={form.values.nationalDrugCode}
                          placeholder="National Drug Code"
                          {...form.getInputProps("nationalDrugCode")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Supplement Name"
                          value={form.values.supplementName}
                          placeholder="Supplement Name"
                          {...form.getInputProps("supplementName")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Supplement Amount"
                          value={form.values.supplementAmount}
                          placeholder="Supplement Amount"
                          {...form.getInputProps("supplementAmount")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Supplement Percentage Daily Value"
                          value={form.values.supplementPercentageDailyValue}
                          placeholder="Supplement Percentage Daily Value"
                          {...form.getInputProps(
                            "supplementPercentageDailyValue"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Serving Size"
                          value={form.values.servingSize}
                          placeholder="Supplement Percentage Daily Value"
                          {...form.getInputProps("servingSize")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Sugar per Serving"
                          value={form.values.sugarPerServing}
                          placeholder="Sugar per Serving"
                          {...form.getInputProps("sugarPerServing")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Nutrient Content Claims (+)"
                          value={form.values.nutrientContentClaims}
                          placeholder="Nutrient Content Claims (+)"
                          {...form.getInputProps("nutrientContentClaims")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Total Fat Per Serving"
                          value={form.values.totalFatPerServing}
                          placeholder="Nutrient Content Claims (+)"
                          {...form.getInputProps("totalFatPerServing")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Total Carbohydrate"
                          value={form.values.totalCarbohydrate}
                          placeholder="Total Carbohydrate"
                          {...form.getInputProps("totalCarbohydrate")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Total Protein"
                          value={form.values.totalProtein}
                          placeholder="Total Protein"
                          {...form.getInputProps("totalProtein")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Activity Stage"
                          value={form.values.activityStage}
                          data={[
                            "Intra Workout",
                            "Post Workout",
                            "Pre Workout",
                          ]}
                          placeholder="Activity Stage"
                          {...form.getInputProps("activityStage")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Calories Per Serving(In Calories)"
                          value={form.values.caloriesPerServing}
                          placeholder="Calories Per Serving"
                          {...form.getInputProps("caloriesPerServing")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Primary Ingredient</Text>
                        <RichTextEditor editor={editor11}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={primaryIngredient}
                            mb={20}
                            {...form.getInputProps("primaryIngredient")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Symptoms"
                          value={form.values.symptoms}
                          placeholder="Symptoms"
                          {...form.getInputProps("symptoms")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Dietary Method"
                          value={form.values.dietaryMethod}
                          placeholder="Dietary Method"
                          {...form.getInputProps("dietaryMethod")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Serving Per Container"
                          value={form.values.servingPerContainer}
                          placeholder="Serving Per Container"
                          {...form.getInputProps("servingPerContainer")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Body Part"
                          value={form.values.bodyPart}
                          placeholder="Body Part"
                          {...form.getInputProps("bodyPart")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Color"
                          value={form.values.color}
                          placeholder="Color"
                          {...form.getInputProps("color")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Size"
                          value={form.values.size}
                          placeholder="Size"
                          {...form.getInputProps("size")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Gender"
                          value={form.values.gender}
                          data={["Male", "Female", "Unisex"]}
                          placeholder="Gender"
                          {...form.getInputProps("gender")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Additional Product Attribute Name"
                          value={form.values.additionalProductAttributeName}
                          placeholder="Additional Product Attribute Name"
                          {...form.getInputProps(
                            "additionalProductAttributeName"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Additional Product Attribute Value"
                          value={form.values.additionalProductAttributeValue}
                          placeholder="Additional Product Attribute Value"
                          {...form.getInputProps(
                            "additionalProductAttributeValue"
                          )}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="fourth">
                  <Accordion.Control>
                    <Text color="blue" align="left">
                      Recommended to create a variant experience on Walmart.com
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Variant Group ID"
                          value={form.values.variantGroupID}
                          placeholder="Variant Group ID"
                          {...form.getInputProps("variantGroupID")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Variant Attribute Names"
                          value={form.values.variantAttributeNames}
                          data={[
                            "Color",
                            "Count Per Pack",
                            "Flavour",
                            "Medicine Strength",
                            "Size",
                          ]}
                          placeholder="Variant Attribute Names"
                          {...form.getInputProps("variantAttributeNames")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Is Primary Variant"
                          value={form.values.IsPrimaryVariant}
                          data={["Yes", "No"]}
                          placeholder="Is Primary Variant"
                          {...form.getInputProps("IsPrimaryVariant")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Swatch Variant Attribute"
                          value={form.values.swatchVariantAttribute}
                          data={[
                            "Color",
                            "Count Per Pack",
                            "Flavour",
                            "Medicine Strength",
                            "Size",
                          ]}
                          placeholder="Swatch Variant Attribute"
                          {...form.getInputProps("swatchVariantAttribute")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Swatch Image URL"
                          value={form.values.swatchImageURL}
                          placeholder="Swatch Image URL"
                          {...form.getInputProps("swatchImageURL")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="Fifth">
                  <Accordion.Control>
                    <Text color="gray" align="left">
                      Optional Data
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Divider />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Restriction Type"
                          value={form.values.restrictionType}
                          data={["Commercial", "Illegal For Sale", "None"]}
                          placeholder="Restriction Type"
                          {...form.getInputProps("restrictionType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="States"
                          value={form.values.states}
                          placeholder="States"
                          {...form.getInputProps("states")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Zip Codes"
                          value={form.values.zipCodes}
                          placeholder="Zip Codes"
                          {...form.getInputProps("zipCodes")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Fulfillment Lag Time"
                          value={form.values.fulfillmentLagTime}
                          placeholder="Fulfillment Lag Time"
                          {...form.getInputProps("fulfillmentLagTime")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="PPU Quantity Of Units"
                          value={form.values.ppuQuantityOfUnits}
                          placeholder="PPU Quantity Of Units"
                          {...form.getInputProps("ppuQuantityOfUnits")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="PPU Unit Of Measure"
                          value={form.values.ppuUnitOfMeasure}
                          data={[
                            "Cubic Foot",
                            "Each",
                            "Fluid Ounce",
                            "Foot",
                            "Inch",
                            "Ounce",
                            "Pound",
                            "Square Foot",
                            "Yard",
                          ]}
                          placeholder="PPU Unit Of Measure"
                          {...form.getInputProps("ppuUnitOfMeasure")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Contains Electronic Component"
                          value={form.values.containsElectronicComponent}
                          data={["Yes", "No"]}
                          placeholder="Contains Electronic Component"
                          {...form.getInputProps("containsElectronicComponent")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Contained Battery Type"
                          value={form.values.containedBatteryType}
                          data={[
                            "Alkaline",
                            "Carbon Zinc",
                            "Does Not Contain A Battery",
                            "Lead Acid",
                            "Lead Acid(Non-Spillable)",
                            "Lithium Ion",
                            "Lithium Primary",
                            "Magnesium",
                            "Mercury",
                            "Multiple Types",
                            "Nickel Cadmium",
                            "Nickel Metal Hydride",
                            "Other",
                            "Silver",
                            "Thermal",
                          ]}
                          placeholder="Contained Battery Type"
                          {...form.getInputProps("containedBatteryType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Contains Chemical Aerosol Or Pesticide"
                          value={form.values.containsChemicalAerosolOrPesticide}
                          data={["Yes", "No"]}
                          placeholder="Contains Chemical Aerosol Or Pesticide"
                          {...form.getInputProps(
                            "containsChemicalAerosolOrPesticide"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Multi Pack Quantity"
                          value={form.values.multiPackQuantity}
                          placeholder="Multi Pack Quantity"
                          {...form.getInputProps("multiPackQuantity")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Ships In Original Packaging"
                          value={form.values.shipsInOriginalPackaging}
                          data={["Yes", "No"]}
                          placeholder="Ships In Original Packaging"
                          {...form.getInputProps("shipsInOriginalPackaging")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Must Ship Alone?"
                          value={form.values.mustShipAlone}
                          data={["Yes", "No"]}
                          placeholder="Must Ship Alone?"
                          {...form.getInputProps("mustShipAlone")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Local Delivery Depth In"
                          value={form.values.localDeliveryDepthIn}
                          placeholder="Local Delivery Depth In"
                          {...form.getInputProps("localDeliveryDepthIn")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Local Delivery Width In"
                          value={form.values.localDeliveryWidthIn}
                          placeholder="Local Delivery Width In"
                          {...form.getInputProps("localDeliveryWidthIn")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Local Delivery Height In"
                          value={form.values.localDeliveryHeightIn}
                          placeholder="Local Delivery Height In"
                          {...form.getInputProps("localDeliveryHeightIn")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Local Delivery Weight"
                          value={form.values.localDeliveryWeight}
                          placeholder="Local Delivery Weight"
                          {...form.getInputProps("localDeliveryWeight")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <DatePickerInput
                          label="Site Start Date"
                          value={form.values.siteStartDate}
                          placeholder="Site Start Date"
                          {...form.getInputProps("siteStartDate")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <DatePickerInput
                          label="Site End Date"
                          value={form.values.siteEndDate}
                          placeholder="Site End Date"
                          {...form.getInputProps("siteEndDate")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Additional Offer Attribute Name"
                          value={form.values.additionalOfferAttributeName}
                          placeholder="Additional Offer Attribute Name"
                          {...form.getInputProps(
                            "additionalOfferAttributeName"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="Additional Offer Attribute Value"
                          value={form.values.additionalOfferAttributeValue}
                          placeholder="Additional Offer Attribute Value"
                          {...form.getInputProps(
                            "additionalOfferAttributeValue"
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="External Product ID Type"
                          value={form.values.externalProductIDType}
                          data={["ASIN"]}
                          placeholder="External Product ID Type"
                          {...form.getInputProps("externalProductIDType")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <TextInput
                          label="External Product ID"
                          value={form.values.externalProductID}
                          placeholder="External Product ID"
                          {...form.getInputProps("externalProductID")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Product ID Update"
                          value={form.values.productIdUpdate}
                          data={["Yes", "No"]}
                          placeholder="Product ID Update"
                          {...form.getInputProps("productIdUpdate")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="SKU Update"
                          value={form.values.skuUpdate}
                          data={["Yes", "No"]}
                          placeholder="SKU Update"
                          {...form.getInputProps("skuUpdate")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
              <Grid>
                <Grid.Col md={12} lg={12} mt={20}>
                  <Group position="right">
                    <Button radius="sm" type="submit" size="sm" color="#A61E4D">
                      Update Walmart Specification Details
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </Card>
      </Skeleton>
    </>
  );
}

export default WalmartSpecifications;
