/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import { useState } from "react";
import { Card, Grid, Text } from "@mantine/core";
import AboutBanner from "../../assets/images/about_banner.jpg";
import AboutBanner1 from "../../assets/images/about_banner1.png";
import HeaderResponsive from "../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../components/mainComponents/FooterSocial";

export default function About() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      {/* Header ends */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* Banner Section */}

        <div
          style={{
            width: "100%",
            height: "auto",
            position: "relative",
            backgroundColor: "darkgray",
          }}
        >
          <img
            src={AboutBanner1}
            alt="Picture of the author"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>

        {/* Banner Section */}
        {/* Second Section */}
        <Card>
          <Grid>
            <Grid.Col md={2} lg={2}></Grid.Col>
            <Grid.Col md={8} lg={8}>
              <Text color="#545454" size={14} lineClamp={5}>
                Activira is a health and wellness online store on a path to help
                people lead a healthy and Happy life every day. This guides on
                how we treat and serve our customers and how we treat the people
                that make us happy and helps our company become great. SynBioPro
                has carefully chosen and invested in products formulated with
                premium quality clinically validated, certified ingredients
                based on scientific research. SynBioPro Nutra&apos;s product
                range stretches from top selling men&apos;s and women&apos;s
                multivitamins and dietary supplements. The Probiotics range
                includes products for Digestive health (Seniors, Adult and Kids)
                Respiratory care, Feminine care, weight Management, skin care,
                Cognitive health, Immunity and, Oral health. The Vitamin Range
                of supplements are in the form of Gummies that come in a variety
                of flavors cater to Mens Health, Womens Health, Prenatal care,
                Cosmetic care ( Hair, skin and Nails, Acne), Melatonin, Vitamin
                B12, Vitamin C,D3, Mens and Womens range for 50+ Adults.
                SynBioPro&apos;s products will shortly be available on-line in
                Canada, USA, India and the Middle East, with plans to launch in
                up to additional innovative supplements in another 10 countries
                in the next five years. We strongly believe that health followed
                by happiness is the most important thing in all of our lives.
                All of SynBioPro Nutra&apos;s formulations are approved by
                Health Canada &quot;As we say, we have one chance at life, why
                waste it on sleep
              </Text>
            </Grid.Col>
            <Grid.Col md={2} lg={2}></Grid.Col>
          </Grid>
        </Card>
        {/* Second Section */}
        {/* Third Section */}
        <div
          style={{
            width: "100%",
            height: "400px",
            position: "relative",
            backgroundColor: "darkgray",
            backgroundSize: "cover",
          }}
        >
          <img
            src={AboutBanner}
            alt="Picture of the author"
            style={{ width: "100%", height: "400px", objectFit: "cover" }}
          />
        </div>
        {/* Third Section */}
      </div>
      <FooterSocial />
    </>
  );
}
