/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Modal,
  Radio,
  Select,
  Skeleton,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddDosageDetails,
  handleGetRegion,
  handleGetDeliveryFormats,
  handleSearchDosageDetails,
  handleEditDosageDetails,
  handleDuplicateDosageDetails,
  handleGetOneDosageDetails,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
// Text editor imports starts
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
import { useParams } from "react-router-dom";
// Text editor imports ends

function DosageDetails() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [defaultValue, setDefaultValue] = useState("1");
  const [updatedAt, setUpdatedAt] = useState();

  // For form validation
  const form = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      deliveryFormatID: "",
      dosageTitle: "",
      directions: "",
      durationStatement: "",
      riskInformation: "",
      cautionWarnings: "",
      allergenStatement: "",
      contraindications: "",
      otherStatement: "",
      knowAdverse: "",
      recommendedStorage: "",
    },
    validate: {
      dosageTitle: (value) =>
        value.length < 1 ? "Dosage title is required" : null,
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      deliveryFormatID: "",
      dosageTitle: "",
      directions: "",
      durationStatement: "",
      riskInformation: "",
      cautionWarnings: "",
      allergenStatement: "",
      contraindications: "",
      otherStatement: "",
      knowAdverse: "",
      recommendedStorage: "",
    },
    validate: {
      dosageTitle: (value) =>
        value.length < 1 ? "Dosage title is required" : null,
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: defaultValue,
      regionIdTo: "",
    },
  });

  // Editor for directions
  const [directions, setDirections] = useState("");
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Directions" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("directions", html);
      formEdit.setFieldValue("directions", html);
      setDirections(html);
    },
  });

  // Editor for duration statement
  const [durationStatement, setDurationStatement] = useState();
  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Duration Statement" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("durationStatement", html);
      formEdit.setFieldValue("durationStatement", html);
      setDurationStatement(html);
    },
  });

  // Editor for risk information
  const [riskInformation, setRiskInformation] = useState();
  const editor2 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Risk Information" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("riskInformation", html);
      formEdit.setFieldValue("riskInformation", html);
      setRiskInformation(html);
    },
  });

  // Editor for caution warnings
  const [cautionWarnings, setCautionWarnings] = useState();
  const editor3 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Caution Warnings" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("cautionWarnings", html);
      formEdit.setFieldValue("cautionWarnings", html);
      setCautionWarnings(html);
    },
  });

  // Editor for allergen statement
  const [allergenStatement, setAllergenStatement] = useState();
  const editor4 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Allergen Statement" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("allergenStatement", html);
      formEdit.setFieldValue("allergenStatement", html);
      setAllergenStatement(html);
    },
  });

  // Editor for contraindications
  const [contraindications, setContraindications] = useState();
  const editor5 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Contraindications" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("contraindications", html);
      formEdit.setFieldValue("contraindications", html);
      setContraindications(html);
    },
  });

  // Editor for other statements
  const [otherStatement, setOtherStatement] = useState();
  const editor6 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Other Statement" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("otherStatement", html);
      formEdit.setFieldValue("otherStatement", html);
      setOtherStatement(html);
    },
  });

  // Editor for know adverse
  const [knowAdverse, setKnowAdverse] = useState();
  const editor7 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Know Adverse" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("knowAdverse", html);
      formEdit.setFieldValue("knowAdverse", html);
      setKnowAdverse(html);
    },
  });

  // Editor for recommended storage
  const [recommendedStorage, setRecommendedStorage] = useState();
  const editor8 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Recommended Storage" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("recommendedStorage", html);
      formEdit.setFieldValue("recommendedStorage", html);
      setRecommendedStorage(html);
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneDosageDetails(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
            });
            if (response.data.data.length > 0) {
              setCount(response.data.data.length);
              setUpdatedAt(response.data.data[0].updatedAt);
              const dataValue = response.data.data[0];
              formEdit.setFieldValue("value", dataValue.id);
              formEdit.setFieldValue("regionId", dataValue.regionId.toString());

              if (
                dataValue.deliveryFormatID !== null &&
                dataValue.deliveryFormatID !== "" &&
                typeof dataValue.deliveryFormatID !== "undefined"
              ) {
                formEdit.setFieldValue(
                  "deliveryFormatID",
                  dataValue.deliveryFormatID.toString()
                );
              } else {
                formEdit.setFieldValue("deliveryFormatID", "");
              }
              if (
                dataValue.dosageTitle !== null &&
                dataValue.dosageTitle !== "" &&
                typeof dataValue.dosageTitle !== "undefined"
              ) {
                formEdit.setFieldValue("dosageTitle", dataValue.dosageTitle);
              } else {
                formEdit.setFieldValue("dosageTitle", "");
              }
              if (
                dataValue.directions !== null &&
                dataValue.directions !== "" &&
                typeof dataValue.directions !== "undefined"
              ) {
                editor?.commands.setContent(dataValue.directions);
                formEdit.setFieldValue("directions", dataValue.directions);
              } else {
                formEdit.setFieldValue("directions", "");
              }
              if (
                dataValue.durationStatement !== null &&
                dataValue.durationStatement !== "" &&
                typeof dataValue.durationStatement !== "undefined"
              ) {
                editor1?.commands.setContent(dataValue.durationStatement);
                formEdit.setFieldValue(
                  "durationStatement",
                  dataValue.durationStatement
                );
              } else {
                formEdit.setFieldValue("durationStatement", "");
              }
              if (
                dataValue.riskInformation !== null &&
                dataValue.riskInformation !== "" &&
                typeof dataValue.riskInformation !== "undefined"
              ) {
                editor2?.commands.setContent(dataValue.riskInformation);
                formEdit.setFieldValue(
                  "riskInformation",
                  dataValue.riskInformation
                );
              } else {
                formEdit.setFieldValue("riskInformation", "");
              }
              if (
                dataValue.cautionWarnings !== null &&
                dataValue.cautionWarnings !== "" &&
                typeof dataValue.cautionWarnings !== "undefined"
              ) {
                editor3?.commands.setContent(dataValue.cautionWarnings);
                formEdit.setFieldValue(
                  "cautionWarnings",
                  dataValue.cautionWarnings
                );
              } else {
                formEdit.setFieldValue("cautionWarnings", "");
              }
              if (
                dataValue.allergenStatement !== null &&
                dataValue.allergenStatement !== "" &&
                typeof dataValue.allergenStatement !== "undefined"
              ) {
                editor4?.commands.setContent(dataValue.allergenStatement);
                formEdit.setFieldValue(
                  "allergenStatement",
                  dataValue.allergenStatement
                );
              } else {
                formEdit.setFieldValue("allergenStatement", "");
              }
              if (
                dataValue.contraindications !== null &&
                dataValue.contraindications !== "" &&
                typeof dataValue.contraindications !== "undefined"
              ) {
                editor5?.commands.setContent(dataValue.contraindications);
                formEdit.setFieldValue(
                  "contraindications",
                  dataValue.contraindications
                );
              } else {
                formEdit.setFieldValue("contraindications", "");
              }
              if (
                dataValue.otherStatement !== null &&
                dataValue.otherStatement !== "" &&
                typeof dataValue.otherStatement !== "undefined"
              ) {
                editor6?.commands.setContent(dataValue.otherStatement);
                formEdit.setFieldValue(
                  "otherStatement",
                  dataValue.otherStatement
                );
              } else {
                formEdit.setFieldValue("otherStatement", "");
              }
              if (
                dataValue.knowAdverse !== null &&
                dataValue.knowAdverse !== "" &&
                typeof dataValue.knowAdverse !== "undefined"
              ) {
                editor7?.commands.setContent(dataValue.knowAdverse);
                formEdit.setFieldValue("knowAdverse", dataValue.knowAdverse);
              } else {
                formEdit.setFieldValue("knowAdverse", "");
              }
              if (
                dataValue.recommendedStorage !== null &&
                dataValue.recommendedStorage !== "" &&
                typeof dataValue.recommendedStorage !== "undefined"
              ) {
                editor8?.commands.setContent(dataValue.recommendedStorage);
                formEdit.setFieldValue(
                  "recommendedStorage",
                  dataValue.recommendedStorage
                );
              } else {
                formEdit.setFieldValue("recommendedStorage", "");
              }
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }

        const response2 = await handleGetDeliveryFormats();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setDosage(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [
    editor,
    editor1,
    editor3,
    editor4,
    editor5,
    editor5,
    editor6,
    editor7,
    editor8,
  ]);

  // Handle add function
  const handleCreateDosageDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddDosageDetails(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Dosage details created successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleUpdateDosageDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditDosageDetails(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Dosage details updated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search dosage details by regionId & productId function
  const handleFetchRegionDosageDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    const response = await handleSearchDosageDetails(req);
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        setCount(response.data.data.length);
        setCount(response.data.data.length);
        formEdit.setFieldValue("value", response.data.data[0].id);
        formEdit.setFieldValue(
          "regionId",
          response.data.data[0].regionId.toString()
        );
        setVariables({ ...variables, submitLoading: false });
        if (
          response.data.data[0].deliveryFormatID !== null &&
          response.data.data[0].deliveryFormatID !== "" &&
          typeof response.data.data[0].deliveryFormatID !== "undefined"
        ) {
          formEdit.setFieldValue(
            "deliveryFormatID",
            response.data.data[0].deliveryFormatID.toString()
          );
        } else {
          formEdit.setFieldValue("deliveryFormatID", "");
        }
        if (
          response.data.data[0].dosageTitle !== null &&
          response.data.data[0].dosageTitle !== "" &&
          typeof response.data.data[0].dosageTitle !== "undefined"
        ) {
          formEdit.setFieldValue(
            "dosageTitle",
            response.data.data[0].dosageTitle
          );
        } else {
          formEdit.setFieldValue("dosageTitle", "");
        }
        if (
          response.data.data[0].directions !== null &&
          response.data.data[0].directions !== "" &&
          typeof response.data.data[0].directions !== "undefined"
        ) {
          editor?.commands.setContent(response.data.data[0].directions);
          formEdit.setFieldValue(
            "directions",
            response.data.data[0].directions
          );
        } else {
          formEdit.setFieldValue("directions", "");
        }
        if (
          response.data.data[0].durationStatement !== null &&
          response.data.data[0].durationStatement !== "" &&
          typeof response.data.data[0].durationStatement !== "undefined"
        ) {
          editor1?.commands.setContent(response.data.data[0].durationStatement);
          formEdit.setFieldValue(
            "durationStatement",
            response.data.data[0].durationStatement
          );
        } else {
          formEdit.setFieldValue("durationStatement", "");
        }
        if (
          response.data.data[0].riskInformation !== null &&
          response.data.data[0].riskInformation !== "" &&
          typeof response.data.data[0].riskInformation !== "undefined"
        ) {
          editor2?.commands.setContent(response.data.data[0].riskInformation);
          formEdit.setFieldValue(
            "riskInformation",
            response.data.data[0].riskInformation
          );
        } else {
          formEdit.setFieldValue("riskInformation", "");
        }
        if (
          response.data.data[0].cautionWarnings !== null &&
          response.data.data[0].cautionWarnings !== "" &&
          typeof response.data.data[0].cautionWarnings !== "undefined"
        ) {
          editor3?.commands.setContent(response.data.data[0].cautionWarnings);
          formEdit.setFieldValue(
            "cautionWarnings",
            response.data.data[0].cautionWarnings
          );
        } else {
          formEdit.setFieldValue("cautionWarnings", "");
        }
        if (
          response.data.data[0].allergenStatement !== null &&
          response.data.data[0].allergenStatement !== "" &&
          typeof response.data.data[0].allergenStatement !== "undefined"
        ) {
          editor4?.commands.setContent(response.data.data[0].allergenStatement);
          formEdit.setFieldValue(
            "allergenStatement",
            response.data.data[0].allergenStatement
          );
        } else {
          formEdit.setFieldValue("allergenStatement", "");
        }
        if (
          response.data.data[0].contraindications !== null &&
          response.data.data[0].contraindications !== "" &&
          typeof response.data.data[0].contraindications !== "undefined"
        ) {
          editor5?.commands.setContent(response.data.data[0].contraindications);
          formEdit.setFieldValue(
            "contraindications",
            response.data.data[0].contraindications
          );
        } else {
          formEdit.setFieldValue("contraindications", "");
        }
        if (
          response.data.data[0].otherStatement !== null &&
          response.data.data[0].otherStatement !== "" &&
          typeof response.data.data[0].otherStatement !== "undefined"
        ) {
          editor6?.commands.setContent(response.data.data[0].otherStatement);
          formEdit.setFieldValue(
            "otherStatement",
            response.data.data[0].otherStatement
          );
        } else {
          formEdit.setFieldValue("otherStatement", "");
        }
        if (
          response.data.data[0].knowAdverse !== null &&
          response.data.data[0].knowAdverse !== "" &&
          typeof response.data.data[0].knowAdverse !== "undefined"
        ) {
          editor7?.commands.setContent(response.data.data[0].knowAdverse);
          formEdit.setFieldValue(
            "knowAdverse",
            response.data.data[0].knowAdverse
          );
        } else {
          formEdit.setFieldValue("knowAdverse", "");
        }
        if (
          response.data.data[0].recommendedStorage !== null &&
          response.data.data[0].recommendedStorage !== "" &&
          typeof response.data.data[0].recommendedStorage !== "undefined"
        ) {
          editor8?.commands.setContent(
            response.data.data[0].recommendedStorage
          );
          formEdit.setFieldValue(
            "recommendedStorage",
            response.data.data[0].recommendedStorage
          );
        } else {
          formEdit.setFieldValue("recommendedStorage", "");
        }
        setDirections(response.data.data[0].directions);
        setDurationStatement(response.data.data[0].durationStatement);
        setRiskInformation(response.data.data[0].riskInformation);
        setCautionWarnings(response.data.data[0].cautionWarnings);
        setAllergenStatement(response.data.data[0].allergenStatement);
        setContraindications(response.data.data[0].contraindications);
        setOtherStatement(response.data.data[0].otherStatement);
        setKnowAdverse(response.data.data[0].knowAdverse);
        setRecommendedStorage(response.data.data[0].recommendedStorage);
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle duplicate dosage details function
  const handleConfirmDuplicateDosageDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateDosageDetails(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Dosage details duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() => handleFetchRegionDosageDetails(item.value)}
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel value={defaultValue} pt="xs">
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formEdit.onSubmit((values) =>
                      handleUpdateDosageDetails(values)
                    )}
                  >
                    <Grid>
                      <Grid.Col md={6} lg={6}>
                        <Select
                          label="Delivery Format"
                          placeholder="Select Delivery Format"
                          searchable
                          data={dosage}
                          value={formEdit.values.deliveryFormatID}
                          {...formEdit.getInputProps("deliveryFormatID")}
                        />
                      </Grid.Col>
                      <Grid.Col md={6} lg={6}>
                        <TextInput
                          label="Dosage Details Title"
                          value={formEdit.values.dosageTitle}
                          placeholder="Dosage Details Title"
                          {...formEdit.getInputProps("dosageTitle")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Directions</Text>
                        <RichTextEditor editor={editor}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={directions}
                            mb={20}
                            {...formEdit.getInputProps("directions")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Duration Statement </Text>
                        <RichTextEditor editor={editor1}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={durationStatement}
                            mb={20}
                            {...formEdit.getInputProps("durationStatement")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Risk Information</Text>
                        <RichTextEditor editor={editor2}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={riskInformation}
                            mb={20}
                            {...formEdit.getInputProps("riskInformation")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Caution Warnings</Text>
                        <RichTextEditor editor={editor3}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={cautionWarnings}
                            mb={20}
                            {...formEdit.getInputProps("cautionWarnings")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Allergen Statement</Text>
                        <RichTextEditor editor={editor4}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={allergenStatement}
                            mb={20}
                            {...formEdit.getInputProps("allergenStatement")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Contraindications</Text>
                        <RichTextEditor editor={editor5}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={contraindications}
                            mb={20}
                            {...formEdit.getInputProps("contraindications")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Other Statement</Text>
                        <RichTextEditor editor={editor6}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={otherStatement}
                            mb={20}
                            {...formEdit.getInputProps("otherStatement")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Know Adverse</Text>
                        <RichTextEditor editor={editor7}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={knowAdverse}
                            mb={20}
                            {...formEdit.getInputProps("knowAdverse")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Recommended Storage</Text>
                        <RichTextEditor editor={editor8}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={recommendedStorage}
                            mb={20}
                            {...formEdit.getInputProps("recommendedStorage")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="left">
                          <Badge color="#FCC2D7">
                            Last updated on:&nbsp;
                            {new Date(updatedAt).toLocaleString("en-UK")}
                          </Badge>
                        </Group>
                        <Group position="right">
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            type="submit"
                            size="sm"
                            color="#A61E4D"
                          >
                            Update Dosage Details
                          </Button>
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            size="sm"
                            color="#A61E4D"
                            onClick={() =>
                              setVariables({
                                ...variables,
                                confirmDrawer: true,
                              })
                            }
                          >
                            Duplicate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={form.onSubmit((values) =>
                  handleCreateDosageDetails(values)
                )}
              >
                <Grid>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="Delivery Format"
                      placeholder="Select Delivery Format"
                      searchable
                      data={dosage}
                      value={form.values.deliveryFormatID}
                      {...form.getInputProps("deliveryFormatID")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <TextInput
                      label="Dosage Details Title"
                      value={form.values.dosageTitle}
                      placeholder="Dosage Details Title"
                      {...form.getInputProps("dosageTitle")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Directions</Text>
                    <RichTextEditor editor={editor}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>

                      <RichTextEditor.Content
                        value={directions}
                        mb={20}
                        {...form.getInputProps("directions")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Duration Statement </Text>
                    <RichTextEditor editor={editor1}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={durationStatement}
                        mb={20}
                        {...form.getInputProps("durationStatement")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Risk Information</Text>
                    <RichTextEditor editor={editor2}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={riskInformation}
                        mb={20}
                        {...form.getInputProps("riskInformation")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Caution Warnings</Text>
                    <RichTextEditor editor={editor3}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={cautionWarnings}
                        mb={20}
                        {...form.getInputProps("cautionWarnings")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Allergen Statement</Text>
                    <RichTextEditor editor={editor4}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={allergenStatement}
                        mb={20}
                        {...form.getInputProps("allergenStatement")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Contraindications</Text>
                    <RichTextEditor editor={editor5}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={contraindications}
                        mb={20}
                        {...form.getInputProps("contraindications")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Other Statement</Text>
                    <RichTextEditor editor={editor6}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={otherStatement}
                        mb={20}
                        {...form.getInputProps("otherStatement")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Know Adverse</Text>
                    <RichTextEditor editor={editor7}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={knowAdverse}
                        mb={20}
                        {...form.getInputProps("knowAdverse")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Recommended Storage</Text>
                    <RichTextEditor editor={editor8}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={recommendedStorage}
                        mb={20}
                        {...form.getInputProps("recommendedStorage")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        loading={variables.skeletonLoading}
                        disabled={variables.skeletonLoading}
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="#A61E4D"
                      >
                        Add Dosage Details
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Dosage Details"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateDosageDetails(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DosageDetails;
