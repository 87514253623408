/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import { useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Container,
  Grid,
  Group,
  Skeleton,
  Text,
} from "@mantine/core";
import { handleGetSkuProductWebsite } from "../../../utils/helpers/apiFunctions";
import renderHTML from "react-render-html";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
  IconClick,
  IconShare,
} from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import Banner from "../../../assets/images/page.png";
import HeaderResponsive from "../../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../../components/mainComponents/FooterSocial";

export default function ProductBatch() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
  });
  const params = useParams();
  const [imageArray, setImageArray] = useState([]);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  // Fetching data from products table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const req = {
          sku: params.sku,
          regionId: params.regionId,
        };
        const response = await handleGetSkuProductWebsite(req);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            // data: response.data.data.reverse(),
            skeletonLoading: false,
            submitLoading: false,
            data: response.data.data[0],
          });
          if (response.data.data[0].product_images.length > 0) {
            const imageObject = response.data.data[0].product_images[0].image;
            const objectArray = imageObject.split(",");
            setImageArray(objectArray);
            setImage(response.data.data[0].featuredImage);
            setDescription(response.data.data[0].productDescription);
          }
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Fetching data from products table
  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Skeleton visible={variables.skeletonLoading}>
          {/* Banner Section */}
          <div
            style={{
              width: "100%",
              height: "auto",
              position: "relative",
              backgroundColor: "darkgray",
            }}
          >
            <img
              src={Banner}
              alt="Picture of the author"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          {/* Banner Section */}
          {/* Description section */}
          <Container fluid my={10}>
            <Grid>
              <Grid.Col md={9} lg={9}>
                <Card withBorder>
                  <Card>
                    <Group position="center">
                      <Text size={25} color="black">
                        {variables.data.label}
                      </Text>
                    </Group>
                    <Group>
                      <Text size={14} color="#545454">
                        {renderHTML(description)}
                      </Text>
                    </Group>
                  </Card>

                  <Card>
                    <Group position="left">
                      <Text size={25} color="black">
                        Key Benefits
                      </Text>
                    </Group>
                    <Group>
                      <Text size={14} color="#545454">
                        {renderHTML(description)}
                      </Text>
                    </Group>
                    <Group my={10} position="left">
                      <Button
                        type="submit"
                        size="sm"
                        variant="outline"
                        radius="sm"
                        color="pink"
                        leftIcon={<IconClick />}
                      >
                        Get Started
                      </Button>
                      <Button
                        type="submit"
                        size="sm"
                        variant="outline"
                        radius="sm"
                        color="pink"
                        leftIcon={<IconShare />}
                      >
                        Share
                      </Button>
                    </Group>
                  </Card>
                </Card>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Card withBorder>
                  <Text fw={700}>We Are Social</Text>
                  <Text size={12}>Connect with us for more insight</Text>
                  <Group spacing={0} position="left" noWrap>
                    <Text size={12}>Follow Us:</Text>
                    <ActionIcon>
                      <IconBrandFacebook size="1rem" />
                    </ActionIcon>
                    <ActionIcon>
                      <IconBrandInstagram size="1.05rem" />
                    </ActionIcon>
                    <ActionIcon>
                      <IconBrandYoutube size="1.05rem" />
                    </ActionIcon>
                    <ActionIcon>
                      <IconBrandTwitter size="1.05rem" />
                    </ActionIcon>
                  </Group>
                </Card>
              </Grid.Col>
            </Grid>
          </Container>
          {/* Description section */}
        </Skeleton>
      </div>
      <FooterSocial />
    </>
  );
}
