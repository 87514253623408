/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import axios from "axios";
import { URL_CONSTANTS } from "../constants/constant";

// FETCH CREDENTIALS

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

// FETCH CREDENTIALS

// AUTH API

// For handle login
export const handleLogin = async (request) => {
  try {
    // URL for login
    const response = await axios.post(URL_CONSTANTS.AUTH_LOGIN_URL, request);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleRegistration = async (request) => {
  try {
    // URL for login
    const response = await axios.post(
      URL_CONSTANTS.AUTH_REGISTRATION_URL,
      request
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// AUTH API

// DASHBOARD API

export const handleGetDashboard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DASHBOARD_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// DASHBOARD API

// BRAND API

// For handle get brand data list
export const handleGetBrand = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.BRAND_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add brand data list
export const handleAddBrand = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.BRAND_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit brand data list
export const handleEditBrand = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.BRAND_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit brand data list
export const handleDeleteBrand = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.BRAND_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// BRAND API

// CALORIES API

// For handle get calories data list
export const handleGetCalories = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CALORIES_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add calories data list
export const handleAddCalories = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CALORIES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit calories data list
export const handleEditCalories = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CALORIES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit calories data list
export const handleDeleteCalories = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CALORIES_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CALORIES API

// CATEGORY TYPES API

// For handle get category type data list
export const handleGetCategoryType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CATEGORY_TYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add category type data list
export const handleAddCategoryType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category type data list
export const handleEditCategoryType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CATEGORY_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category type data list
export const handleDeleteCategoryType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CATEGORY_TYPE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CATEGORY TYPES API

// CATEGORY API

// For handle get category data list
export const handleGetCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CATEGORY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add category data list
export const handleAddCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category data list
export const handleEditCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category data list
export const handleDeleteCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CATEGORY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CATEGORY API

// CERTIFICATES API

// For handle get certificates data list
export const handleGetCertificates = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CERTIFICATE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add certificates data list
export const handleAddCertificates = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CERTIFICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add certificates data list
export const handleUploadCertificateImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CERTIFICATE_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit certificates data list
export const handleEditCertificates = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CERTIFICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit certificates data list
export const handleDeleteCertificates = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CERTIFICATE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CERTIFICATES API

// COUPONS API

// For handle get coupons data list
export const handleGetCoupons = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.COUPONS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add coupons data list
export const handleAddCoupons = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.COUPONS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit coupons data list
export const handleEditCoupons = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.COUPONS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit coupons data list
export const handleDeleteCoupons = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.COUPONS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// COUPONS API

// DELIVERY FORMATS API

// For handle get delivery formats data list
export const handleGetDeliveryFormats = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DELIVERY_FORMATS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add delivery formats data list
export const handleAddDeliveryFormats = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DELIVERY_FORMATS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit delivery formats data list
export const handleEditDeliveryFormats = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DELIVERY_FORMATS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit delivery formats data list
export const handleDeleteDeliveryFormats = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DELIVERY_FORMATS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DELIVERY FORMATS API

// DISTRIBUTORS API

// For handle get distributors data list
export const handleGetDistributors = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DISTRIBUTOR_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add distributors data list
export const handleAddDistributors = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DISTRIBUTOR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit distributors data list
export const handleEditDistributors = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DISTRIBUTOR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload distributors image data list
export const handleUploadDistributorImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DISTRIBUTOR_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit distributors data list
export const handleDeleteDistributors = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DISTRIBUTOR_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DISTRIBUTORS API

// DOSAGE FORM API

// For handle get dosage form data list
export const handleGetDosageForm = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DOSAGE_FORM_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add dosage form data list
export const handleAddDosageForm = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DOSAGE_FORM_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit dosage form data list
export const handleEditDosageForm = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DOSAGE_FORM_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit dosage form data list
export const handleDeleteDosageForm = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DOSAGE_FORM_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DOSAGE FORM API

// DOSAGE DETAILS API

// For handle get dosage details data by ID list
export const handleGetOneDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DOSAGE_DETAILS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get dosage details data list
export const handleGetDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.DOSAGE_DETAILS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add dosage details data list
export const handleAddDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DOSAGE_DETAILS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add dosage details data list
export const handleSearchDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DOSAGE_DETAILS_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add dosage details data list
export const handleDuplicateDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.DOSAGE_DETAILS_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit dosage details data list
export const handleEditDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.DOSAGE_DETAILS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit dosage details data list
export const handleDeleteDosageDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.DOSAGE_DETAILS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DOSAGE DETAILS API

// FLAVOURS API

// For handle get flavours data list
export const handleGetFlavours = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.FLAVOUR_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add flavours data list
export const handleAddFlavours = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.FLAVOUR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit flavours data list
export const handleEditFlavours = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.FLAVOUR_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit flavours data list
export const handleDeleteFlavours = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.FLAVOUR_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// FLAVOURS API

// MARKETER API

// For handle get marketers data list
export const handleGetMarketers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.MARKETER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add marketer data list
export const handleAddMarketers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MARKETER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload marketer image data list
export const handleUploadMarketerImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MARKETER_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit marketer data list
export const handleEditMarketers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.MARKETER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit marketer data list
export const handleDeleteMarketers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.MARKETER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// MARKETER API

// MANUFACTURERS API

// For handle get manufacturers data list
export const handleGetManufacturers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.MANUFACTURER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add manufacturers data list
export const handleAddManufacturers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MANUFACTURER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload manufacturer image data list
export const handleUploadManufacturerImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MANUFACTURER_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit manufacturers data list
export const handleEditManufacturers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.MANUFACTURER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit manufacturers data list
export const handleDeleteManufacturers = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.MANUFACTURER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// MANUFACTURERS API

// MANUFACTURERS DOCUMENTS API
// Only if required
// MANUFACTURERS DOCUMENTS API

// MEDICINAL INGREDIENTS API

// For handle get medicinal ingredients data by Id list
export const handleGetOneMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get medicinal ingredients data list
export const handleGetMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add medicinal ingredients data list
export const handleAddMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search medicinal ingredients data list
export const handleSearchMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate medicinal ingredients data list
export const handleDuplicateMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit medicinal ingredients data list
export const handleEditMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit medicinal ingredients data list
export const handleDeleteMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MEDICINAL_INGREDIENTS_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// MEDICINAL INGREDIENTS API

// NON MEDICINAL INGREDIENTS API

// For handle get medicinal ingredients data by Id list
export const handleGetOneNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get non medicinal ingredients data list
export const handleGetNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add non medicinal ingredients data list
export const handleAddNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search non medicinal ingredients data list
export const handleSearchNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate non medicinal ingredients data list
export const handleDuplicateNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit non medicinal ingredients data list
export const handleEditNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete non medicinal ingredients data list
export const handleDeleteNonMedicinalIngredients = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.NON_MEDICINAL_INGREDIENTS_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// NON MEDICINAL INGREDIENTS API

// PERMISSION API

// For handle get permission data list
export const handleGetPermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PERMISSION_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add permission data list
export const handleAddPermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PERMISSION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit permission data list
export const handleEditPermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PERMISSION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete permission data list
export const handleDeletePermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PERMISSION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PERMISSION API

// PRODUCT API

// For handle get products data list
export const handleGetProductOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products data list
export const handleGetProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products data list
export const handleAddProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products data list
export const handleEditProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products data list
export const handleDeleteProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT API

// PRODUCT BATCH API

// For handle get products batch data list
export const handleGetOneProductBatch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_BATCH_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products batch data list
export const handleGetProductBatch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_BATCH_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products batch data list
export const handleAddProductBatch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_BATCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products batch data list
export const handleEditProductBatch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_BATCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products batch data list
export const handleDeleteProductBatch = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_BATCH_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT BATCH API

// PRODUCT CERTIFICATE API

// For handle get products certificate by id data list
export const handleGetOneProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products certificate data list
export const handleGetProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products certificate data list
export const handleAddProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search product certificate data list
export const handleSearchProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate product certificate data list
export const handleDuplicateProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products certificate data list
export const handleEditProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products certificate data list
export const handleDeleteProductCertificate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_CERTIFICATE_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT CERTIFICATE API

// PRODUCT IMAGE API

// For handle get products image data by Id list
export const handleGetOneProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_IMAGE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products image data list
export const handleGetProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_IMAGE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload products image data list
export const handleUploadProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search products image data list
export const handleSearchProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_IMAGE_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate products image data list
export const handleDuplicateProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_IMAGE_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products image data list
export const handleUpdateProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_IMAGE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products image data list
export const handleDeleteProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_IMAGE_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT IMAGE API

// PRODUCT ISSUES API

// For handle get products issue data list
export const handleGetProductIssue = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_ISSUES_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products issue data list
export const handleAddProductIssue = async (request) => {
  try {
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_ISSUES_URL,
      request
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products issue data list
export const handleEditProductIssue = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_ISSUES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products issue data list
export const handleDeleteProductIssue = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_ISSUES_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT ISSUES API

// PRODUCT PAGES API

// For handle get products page data list
export const handleGetOneProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_PAGES_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products page data list
export const handleGetProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_PAGES_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products page data list
export const handleAddProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_PAGES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products page data list
export const handleSearchProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_PAGES_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products page data list
export const handleDuplicateProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_PAGES_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products page data list
export const handleEditProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_PAGES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products page data list
export const handleDeleteProductPage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_PAGES_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT PAGES API

// PRODUCT REVIEWS API

// For handle get products reviews data list
export const handleGetProductReview = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_REVIEWS_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products reviews data list
export const handleAddProductReview = async (request) => {
  try {
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_REVIEWS_URL,
      request
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products reviews data list
export const handleEditProductReview = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_REVIEWS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products reviews data list
export const handleDeleteProductReview = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_REVIEWS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT REVIEWS API

// PRODUCT TRACKING DETAILS API

// For handle get products page data list
export const handleGetOneProductTrackingDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_TRACKING_DETAILS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get products tracking details data list
export const handleGetProductTrackingDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_TRACKING_DETAILS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add products tracking details data list
export const handleAddProductTrackingDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_TRACKING_DETAILS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit products tracking details data list
export const handleEditProductTrackingDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_TRACKING_DETAILS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete products tracking details data list
export const handleDeleteProductTrackingDetails = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_TRACKING_DETAILS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT TRACKING DETAILS API

// PRODUCT TYPE API

// For handle get product type data list
export const handleGetProductType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_TYPE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product type data list
export const handleAddProductType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload product featured image data list
export const handleUploadProductFeaturedImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_FEATURED_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product type data list
export const handleEditProductType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_TYPE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product type data list
export const handleDeleteProductType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_TYPE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT TYPE API

// REGION API

// For handle get region data list
export const handleGetRegion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REGION_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add region data list
export const handleAddRegion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add certificates data list
export const handleUploadRegionImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGION_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit region data list
export const handleEditRegion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.REGION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit region data list
export const handleDeleteRegion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REGION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REGION API

// REGULATORY API

// For handle get regulatory data list
export const handleGetRegulatory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REGULATORY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add regulatory data list
export const handleAddRegulatory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGULATORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit regulatory data list
export const handleEditRegulatory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.REGULATORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit regulatory data list
export const handleDeleteRegulatory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REGULATORY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REGULATORY API

// REGULATORY BODY API STARTS

// For handle get regulatory body facts by id data list
export const handleGetOneRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.REGULATORY_BODY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get regulatory body facts by id data list
export const handleGetOneRegulatoryBodyPath = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.REGULATORY_BODY_PATH_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get regulatory body data list
export const handleGetRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REGULATORY_BODY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add regulatory body data list
export const handleAddRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGULATORY_BODY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search non medicinal ingredients data list
export const handleSearchRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGULATORY_BODY_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate non medicinal ingredients data list
export const handleDuplicateRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGULATORY_BODY_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit regulatory body data list
export const handleEditRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.REGULATORY_BODY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit regulatory body data list
export const handleDeleteRegulatoryBody = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REGULATORY_BODY_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REGULATORY APPROVALS API ENDS

// RESEARCH DOCUMENTS BODY API

// For handle get research documents data list
export const handleGetResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add research documents data list
export const handleAddResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search research documents data list
export const handleSearchResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate research documents data list
export const handleDuplicateResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit research documents data list
export const handleEditResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit research documents data list
export const handleDeleteResearchDocuments = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RESEARCH_DOCUMENTS_DELETE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// RESEARCH DOCUMENTS BODY API

// RETAILER API

// For handle get retailer data list
export const handleGetRetailer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.RETAILER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add retailer data list
export const handleAddRetailer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RETAILER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload retailer image data list
export const handleUploadRetailerImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.RETAILER_IMAGE_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit retailer data list
export const handleEditRetailer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.RETAILER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit retailer data list
export const handleDeleteRetailer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.RETAILER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// RETAILER API

// ROLE API

// For handle get role data list
export const handleGetRole = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ROLE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add role data list
export const handleAddRole = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.ROLE_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit role data list
export const handleEditRole = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.ROLE_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit role data list
export const handleDeleteRole = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.ROLE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ROLE API

// ROLE PERMISSION API

// For handle get role permission data list
export const handleGetRolePermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ROLE_PERMISSION_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add role permission data list
export const handleAddRolePermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ROLE_PERMISSION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit role permission data list
export const handleEditRolePermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.ROLE_PERMISSION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit role permission data list
export const handleDeleteRolePermission = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.ROLE_PERMISSION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ROLE PERMISSION API

// SUPPLEMENT FACTS API

// For handle get supplement facts by id data list
export const handleGetOneSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.SUPPLEMENT_FACTS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get supplement facts data list
export const handleGetSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.SUPPLEMENT_FACTS_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add supplement facts data list
export const handleAddSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SUPPLEMENT_FACTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search supplement facts data list
export const handleSearchSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SUPPLEMENT_FACTS_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate supplement facts data list
export const handleDuplicateSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SUPPLEMENT_FACTS_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit supplement facts data list
export const handleEditSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.SUPPLEMENT_FACTS_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit supplement facts data list
export const handleDeleteSupplementFacts = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SUPPLEMENT_FACTS_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SUPPLEMENT FACTS API

// USER API

// For handle get user data from email
export const handleGetUserOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.USER_ONE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle update user data
export const handleUpdateUserAccount = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.USER_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle update user password details
export const handleUpdateUserPassword = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.USER_PASSWORD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload user profile image
export const handleUploadUserProfileImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.USER_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// USER API

// VARIANT API

// For handle get variant data list
export const handleGetVariant = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.VARIANT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add variant data list
export const handleAddVariant = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.VARIANT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit variant data list
export const handleEditVariant = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.VARIANT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit variant data list
export const handleDeleteVariant = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.VARIANT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// VARIANT API

// WALMART SPECIFICATION API

// For handle get walmart specification data list
export const handleGetOneWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.WALMART_SPECIFICATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get walmart specification data list
export const handleGetWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.WALMART_SPECIFICATION_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add walmart specification data list
export const handleAddWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.WALMART_SPECIFICATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search walmart specification data list
export const handleSearchWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.WALMART_SPECIFICATION_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate walmart specification data list
export const handleDuplicateWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.WALMART_SPECIFICATION_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle fetch data to export walmart specification
export const handleExportWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.WALMART_SPECIFICATION_EXPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit walmart specification data list
export const handleEditWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.WALMART_SPECIFICATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit walmart specification data list
export const handleDeleteWalmartSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.WALMART_SPECIFICATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// WALMART SPECIFICATION API

// AMAZON SPECIFICATION API

// For handle get walmart specification data list
export const handleGetOneAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.AMAZON_SPECIFICATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get walmart specification data list
export const handleGetAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.AMAZON_SPECIFICATION_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add amazon specification data list
export const handleAddAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.AMAZON_SPECIFICATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle search amazon specification data list
export const handleSearchAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.AMAZON_SPECIFICATION_SEARCH_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle duplicate amazon specification data list
export const handleDuplicateAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.AMAZON_SPECIFICATION_DUPLICATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle fetch data to export amazon specification
export const handleExportAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.AMAZON_SPECIFICATION_EXPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit amazon specification data list
export const handleEditAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.AMAZON_SPECIFICATION_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit amazon specification data list
export const handleDeleteAmazonSpecification = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.AMAZON_SPECIFICATION_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// AMAZON SPECIFICATION API

// WEBSITE PURPOSE

// For handle get product data list for website
export const handleGetAllProductWebsite = async (request) => {
  try {
    const response = await axios.get(URL_CONSTANTS.PRODUCT_ALL_URL);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get product data list for website
export const handleGetSkuProductWebsite = async (request) => {
  try {
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_ALL_SKU_URL,
      request
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get product data list for website
export const handleGetBatchProductWebsite = async (request) => {
  try {
    const response = await axios.get(
      URL_CONSTANTS.PRODUCT_ALL_BATCH_URL + "/" + request
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// Contact details creation
// For handle add contact details data list
export const handleAddContactDetails = async (request) => {
  try {
    const response = await axios.post(URL_CONSTANTS.CONTACT_URL, request);
    return response;
  } catch (error) {
    return error.response;
  }
};

// WEBSITE PURPOSE
