import React from "react";
import { Card, Grid, ScrollArea, Skeleton } from "@mantine/core";
import BreadCrumbs from "../breadcrumbs/Breadcrumbs";
import ProductDetailsNavigation from "./productDetailsLink";
import { Outlet, useParams } from "react-router-dom";

function ProductDetailsComponent() {
  const params = useParams();
  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Product Details" />
      </Skeleton>
      <Skeleton radius="md" mt={20} visible={false}>
        <Grid>
          <Grid.Col md={2} lg={2}>
            <ProductDetailsNavigation productId={params.id} />
          </Grid.Col>
          <Grid.Col md={10} lg={10}>
            <ScrollArea h={750}>
              <Card shadow="xs" padding="xs" radius="md" pt={0}>
                <Outlet />
              </Card>
            </ScrollArea>
          </Grid.Col>
        </Grid>
      </Skeleton>
    </>
  );
}

export default ProductDetailsComponent;
