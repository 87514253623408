/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  NativeSelect,
  Pagination,
  Rating,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import {
  handleDeleteProductReview,
  handleGetProductReview,
} from "../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../utils/common/common";
import { IconFileExport, IconSearch, IconTrash } from "@tabler/icons-react";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { CSVLink } from "react-csv";

function Reviews() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setLoadingData(true);
        const response = await handleGetProductReview();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setLoadingData(false);
          setData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{row.product.label}</td>
      <td>{row.username}</td>
      <td>
        <Rating value={row.ratings} fractions={2} readOnly />
      </td>
      <td>{row.reviewDescription}</td>
      <td>{row.phoneNumber}</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the product review of{" "}
          <b>{datas.username}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProductReview(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Review deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Reviews" />
      </Skeleton>
      <Grid>
        <Grid.Col md={12} lg={12}>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <TextInput
                  variant="filled"
                  placeholder="Search by any field"
                  mb="md"
                  size="xs"
                  value={search}
                  icon={<IconSearch size={14} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Group spacing="xs">
                  {/* Drawer open for adding new tax data */}
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "product", key: "product.label" },
                      { label: "region", key: "region.label" },
                      { label: "username", key: "username" },
                      { label: "Phone number", key: "phoneNumber" },
                      { label: "description", key: "reviewDescription" },
                      { label: "ratings", key: "ratings" },
                      { label: "country name", key: "countryName" },
                      { label: "country code", key: "countryCode" },
                      { label: "dial code", key: "dialCode" },
                    ]}
                    filename="reviews.csv"
                  >
                    <Button
                      variant="outline"
                      color="zevcore"
                      size="xs"
                      leftIcon={<IconFileExport size={12} />}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Group>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Product</th>
                      <th>Name</th>
                      <th>Ratings</th>
                      <th>Description</th>
                      <th>Phone Number</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={8}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      setData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Skeleton>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default Reviews;
