/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { Button, Container, Grid, Group, Text } from "@mantine/core";
import Effective from "../../assets/images/effective.png";
import Safe from "../../assets/images/safe.png";
import Robust from "../../assets/images/robust.png";
import Banner from "../../assets/images/banner1.png";
import HomeSection1 from "../../assets/images/home_section1.png";
import HomeSection2 from "../../assets/images/home_section2.png";
import { useNavigate } from "react-router-dom";
import HeaderResponsive from "../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../components/mainComponents/FooterSocial";

export default function Home() {
  let navigate = useNavigate();
  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      {/* Header ends */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "auto",
            position: "relative",
            backgroundColor: "darkgray",
          }}
        >
          <img
            src={Banner}
            alt="Picture of the author"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        {/* Banner Section */}
        {/* Second Section */}
        <Container
          fluid
          py={30}
          style={{
            height: "auto",
          }}
        >
          <Grid>
            <Grid.Col md={2} lg={2}></Grid.Col>
            <Grid.Col md={8} lg={8}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  {/* <Card radius={10}> */}
                  <Group position="center">
                    <img
                      src={Effective}
                      alt="Picture of the author"
                      style={{ height: "300px" }}
                    />
                  </Group>
                  <Group position="center">
                    <Text fw={500} color="dark" align="center">
                      CLINICALLY STUDIED AND EFFECTIVE
                    </Text>
                    <Text color="#545454" align="center" size={14}>
                      Our probiotic strains are carefully tested under strict
                      conditions to ensure they provide real health benefits.
                    </Text>
                  </Group>
                  {/* </Card> */}
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  {/* <Card radius={10}> */}
                  <Group position="center">
                    <img
                      src={Safe}
                      alt="Picture of the author"
                      style={{ height: "300px" }}
                    />
                  </Group>
                  <Group position="center">
                    <Text color="#545454" fw={500} align="center">
                      SAFE
                    </Text>
                    <Text color="#545454" align="center" size={14}>
                      From genetic screening to an FDA evaluation, our strains
                      go through many rigorous quality and safety tests.
                    </Text>
                  </Group>
                  {/* </Card> */}
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  {/* <Card radius={10}> */}
                  <Group position="center">
                    <img
                      src={Robust}
                      alt="Picture of the author"
                      style={{ height: "300px" }}
                    />
                  </Group>
                  <Group position="center">
                    <Text color="#545454" width={200} fw={500}>
                      ROBUST
                    </Text>

                    <Text color="#545454" align="center" size={14}>
                      Probiotics are living things—and they need to stay that
                      way in order to work. Our probiotics are designed to
                      survive and thrive on the shelf and throughout your
                      digestive system.
                    </Text>
                  </Group>
                  {/* </Card> */}
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col md={2} lg={2}></Grid.Col>
          </Grid>
        </Container>
        {/* Second Section */}
        {/* Third Section */}
        <Container
          fluid
          py={40}
          style={{
            height: "auto",
          }}
        >
          <Grid>
            <Grid.Col md={2} lg={2}></Grid.Col>
            <Grid.Col md={4} lg={4}>
              {/* <Card shadow="md" radius="md" py={53}> */}
              <Text fw={500} size={30} color="#A61E4D">
                Just Like Nature Intended
              </Text>
              <Text size={14} my={20} color="#545454">
                Health, happiness and wellbeing. These three things are at the
                core of everything we practice at SynBioPro Nutraceutix. We
                encourage people to make their health and life a priority, Don’t
                suffer till someone notices you, reach out and ask, We believe
                that the right approach to health should always be a positive
                thought, regular exercise and wholesome food. Most importantly,
                we believe in Celebrating Life to the fullest extent. ​We are
                committed to research and product development in dietary
                supplements whether it be vitamins, probiotics.
              </Text>
              <Text size={14} color="#545454">
                We also work closely with a Scientific Advisory Panel – a team
                of forward-thinking academics, scientists and leading
                formulation specialists and clinicians to help us create and
                innovate continuously. This not only helps to formulate new
                products but also to educate the business on emerging research
                and population-specific health concerns.
              </Text>
              {/* </Card> */}
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              {/* <Card> */}
              <Group position="center">
                <img
                  src={HomeSection1}
                  alt="Picture of the author"
                  style={{ height: "400px" }}
                />
              </Group>
              {/* </Card> */}
            </Grid.Col>
            <Grid.Col md={2} lg={2}></Grid.Col>
          </Grid>
        </Container>
        {/* Third Section */}
        {/* Fourth Section */}
        <Container
          fluid
          py={40}
          style={{
            height: "auto",
          }}
        >
          <Grid>
            <Grid.Col md={2} lg={2}></Grid.Col>
            <Grid.Col md={4} lg={4}>
              {/* <Card> */}
              <Group position="center">
                <img
                  src={HomeSection2}
                  alt="Picture of the author"
                  style={{ height: "400px" }}
                />
              </Group>
              {/* </Card> */}
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              {/* <Card shadow="md" radius="md"> */}
              <Text fw={500} size={30} color="#A61E4D">
                Naturally Simple
              </Text>
              <Text size={14} my={20} color="#545454">
                Health and happiness is the foundation of a balanced quality
                life, Nutrition is the key . Our daily lifestyle determine the
                above. We all know that &apos;What we eat is what we
                become&apos;. Our lifestyles have led us to compromise our
                nutrition needs and its after effects are showing in our
                deteriorating health. SynBioPro Nutraceutix introduces the
                Advantage brand of Vitamin and Multi-minerals, cosmetic, Womens
                and Mens health for adults over 50 years, & pre-natal care .The
                complexities of existing pill solutions is making it very
                difficult for senior citizens. For starters, we decided to
                create health products backed by scientific evidence that would
                be made from best-in-class vegetarian based ingredients that
                address root causes of problems to provide total nutritive plan.
                Equally important for us, was to make products that are tasty
                and fit into our lifestyles. Advantage Gummies are made with
                pectin, low calorie corn Syrup and natural flavors derived from
                fruits and vegetables. each of the Ingredients used in the
                gummies and other formulations are pre-validated approved
                monographs which are clinically tested a by Health Canada during
                approval process. All our products on this store are approved by
                the NNHPD of Health Canada and are carefully manufactured in a
                USFDA approved GMP facility in the United States. Advantage
                Gummies are manufactured with love to spread health and
                happiness to al.
              </Text>
              {/* </Card> */}
            </Grid.Col>
            <Grid.Col md={2} lg={2}></Grid.Col>
          </Grid>
        </Container>
        {/* Fourth Section */}
      </div>
      {/* Header */}
      <FooterSocial />
    </>
  );
}
