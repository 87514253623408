import React, { useState } from "react";
import {
  IconBrandPagekit,
  IconForms,
  IconBrandAmazon,
  IconBrandWalmart,
  IconDroplet,
  IconReportAnalytics,
  IconPhoto,
  IconCertificate,
  IconReportSearch,
  IconVaccineBottle,
  IconFirstAidKit,
} from "@tabler/icons-react";
import { Box, NavLink, Paper } from "@mantine/core";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IconFirstAidKitOff } from "@tabler/icons-react";

const data = [
  {
    icon: IconBrandPagekit,
    label: "Product Information",
    link: `productInformation`,
  },
  {
    icon: IconVaccineBottle,
    label: "Supplement Facts",
    link: `supplementFacts`,
  },
  {
    icon: IconFirstAidKit,
    label: "Medicinal Ingredients",
    link: `medicinalIngredients`,
  },
  {
    icon: IconFirstAidKitOff,
    label: "Non Medicinal Ingredients",
    link: `nonMedicinalIngredients`,
  },
  {
    icon: IconForms,
    label: "Regulatory Approvals",
    link: `regulatoryBody`,
  },
  {
    icon: IconDroplet,
    label: "Dosage Details",
    link: `dosageDetails`,
  },
  {
    icon: IconReportSearch,
    label: "Research Documents",
    link: `researchDocuments`,
  },
  {
    icon: IconCertificate,
    label: "Certificates",
    link: `productCertificates`,
  },
  {
    icon: IconPhoto,
    label: "Product Images",
    link: `productImages`,
  },
  // {
  //   icon: IconReportAnalytics,
  //   label: "Product Pages",
  //   link: `productPages`,
  // },
  {
    icon: IconBrandWalmart,
    label: "Walmart Specifications",
    link: `walmartSpecifications`,
  },
  {
    icon: IconBrandAmazon,
    label: "Amazon Specifications",
    link: `amazonSpecifications`,
  },
];

export default function SettingLinks() {
  let navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const slug = pathname.split("/").pop();
  const params = useParams();
  // Handle click if e empty or undefined it wont do any thing
  const handleClick = (e) => {
    if (typeof e != "undefined") {
      navigate(`/admin/products/${params.id}/${e.link}`);
    }
  };

  const items = data.map((item, index) => (
    <NavLink
      key={index}
      active
      variant={slug === item.link ? "light" : "filled"}
      label={item.label}
      description={item.description}
      rightSection={item.rightSection}
      icon={<item.icon size="1rem" stroke={1.5} />}
      onClick={() => handleClick(item)}
    />
  ));

  return (
    <Paper shadow="xs" radius="sm" p={1}>
      <Box>{items}</Box>
    </Paper>
  );
}
