/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useState } from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  rem,
  Group,
  Text,
  Anchor,
} from "@mantine/core";
import Logo from "../../../assets/images/logo.png";
import { useForm } from "@mantine/form";
import { IconArrowBack, IconLogin, IconLogin2 } from "@tabler/icons-react";
import { handleLogin } from "../../../utils/helpers/apiFunctions";
import { useNavigate } from "react-router-dom";

function Login() {
  const { classes } = useStyles();
  let navigate = useNavigate();
  const [error, setError] = useState("");
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    submitLoading: false,
  });
  // For form validation
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        value.length < 1 ? "Email / Phone Number is required" : null,
      password: (value) => (value.length < 1 ? "Password is required" : null),
    },
  });

  const handleSubmit = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleLogin(e);
    if (response.status === 200) {
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("roleId", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/admin/dashboard");
      }, 1000);
      setVariables({ ...variables, submitLoading: false });
    } else {
      setError("Error");
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <div justify="center" align="center" style={{ marginBottom: 25 }}>
          <img
            src={Logo}
            alt=""
            className="zc-pt-3"
            style={{ height: "100px" }}
          />
        </div>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            label="Email / Phone Number"
            placeholder="example@gmail.com"
            size="md"
            withAsterisk
            mt="md"
            value={form.values.email}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            size="md"
            withAsterisk
            value={form.values.password}
            {...form.getInputProps("password")}
          />
          {/* <Checkbox label="Keep me logged in" mt="xl" size="md" /> */}
          {error && <Text color="red">{error}</Text>}
          <Button
            style={{ backgroundColor: "#A61E4D" }}
            fullWidth
            type="submit"
            mt="md"
            loading={variables.submitLoading}
            leftIcon={<IconLogin2 />}
          >
            Login
          </Button>
          <Group position="center">
            <Anchor color="#A61E4D" onClick={() => navigate("/")} mt={15}>
              Back to Home
            </Anchor>
          </Group>
        </form>
      </Paper>
    </div>
  );
}

export default Login;

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage:
      "url(https://wallpapers.com/images/hd/vintage-dark-blue-2xrl3q1w6992hsna.jpg)",
  },

  form: {
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));
