/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import "./App.css";
import { useLocalStorage } from "@mantine/hooks";

// Protected Routes
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";

// Error Page
import ErrorBoundary from "./components/errors/Error";

// Sidebar
import Sidebar from "./components/sidebar/Sidebar";
// Settings Component
import SettingsComponent from "./components/settingsComponents/SettingsComponent";
// Product Details Component
import ProductDetailsComponent from "./components/productDetailsLinks/productDetailsComponent";
// Main Header and Footer components
import MainComponent from "./components/mainComponents/MainComponent";

// Frontend website pages
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import RegionSelection from "./pages/region/Region";
import Product from "./pages/product/Product";
import Contact from "./pages/contact/Contact";

// For authentication
import Login from "./pages/auth/login/Login";

// Administrator Pages
import Dashboard from "./pages/admin/dashboard/Dashboard";
import AccountSettings from "./pages/admin/accountSettings/AccountSettings";
import Coupon from "./pages/admin/coupons/Coupon";
import Issue from "./pages/admin/issues/Issue";
import Reviews from "./pages/admin/reviews/Reviews";
import ProductBatches from "./pages/admin/productBatch/ProductBatches";
import TrackingDetails from "./pages/admin/trackingDetails/TrackingDetails";
import Products from "./pages/admin/products/Products";
import Exports from "./pages/admin/exports/Exports";
import ProductDetails from "./pages/product/productDetails/ProductDetails";
import ProductBatchDetails from "./pages/product/productBatchDetails/ProductBatchDetails";

// Settings pages
import Brands from "./pages/admin/settings/brands/Brands";
import Category from "./pages/admin/settings/category/Category";
import CategoryTypes from "./pages/admin/settings/categoryType/CategoryTypes";
import Certificates from "./pages/admin/settings/certificates/Certificates";
import DeliveryFormat from "./pages/admin/settings/deliveryFormats/DeliveryFormat";
import Distributors from "./pages/admin/settings/distributors/Distributors";
import DosageForm from "./pages/admin/settings/dosageForm/DosageForm";
import Flavours from "./pages/admin/settings/flavours/Flavours";
import Marketers from "./pages/admin/settings/marketedBy/Marketers";
import Manufacturer from "./pages/admin/settings/manufacturers/Manufacturer";
import ProductTypes from "./pages/admin/settings/productType/ProductTypes";
import Region from "./pages/admin/settings/region/Region";
import Regulatory from "./pages/admin/settings/regulatory/Regulatory";
import Retailer from "./pages/admin/settings/retailers/Retailer";
import Variant from "./pages/admin/settings/variants/Variant";

// Product Details
import AmazonSpecifications from "./pages/admin/products/amazonSpecifications/AmazonSpecifications";
import DosageDetails from "./pages/admin/products/dosageDetails/DosageDetails";
import MedicinalIngredients from "./pages/admin/products/medicinalIngredients/MedicinalIngredients";
import NonMedicinalIngredients from "./pages/admin/products/nonMedicinalIngredients/NonMedicinalIngredients";
import ProductCertificates from "./pages/admin/products/productCertificates/ProductCertificates";
import ProductImages from "./pages/admin/products/productImages/ProductImages";
import ProductInformation from "./pages/admin/products/productInformation/ProductInformation";
import ProductPages from "./pages/admin/products/productPages/ProductPages";
import RegulatoryApprovals from "./pages/admin/products/regulatoryBody/RegulatoryApprovals";
import ResearchDocuments from "./pages/admin/products/researchDocuments/ResearchDocuments";
import SupplementFacts from "./pages/admin/products/supplementFacts/SupplementFacts";
import WalmartSpecifications from "./pages/admin/products/walmartSpecifications/WalmartSpecifications";
import { Notifications } from "@mantine/notifications";

function App() {
  // For get the dark light toggle
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // Get the last mode
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // Change the color if u want to '#043c64' Carefully wile choosing dark color only
  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#A61E4D",
  });

  // For make color lighter by 10 colors level
  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          colors: {
            zevcore: [
              LightenDarkenColor(color, 140),
              LightenDarkenColor(color, 130),
              LightenDarkenColor(color, 120),
              LightenDarkenColor(color, 110),
              LightenDarkenColor(color, 100),
              LightenDarkenColor(color, 90),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 80),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 20),
            ],
          },
          primaryColor: "zevcore",
          "::-webkit-scrollbar": {
            backgroundColor: LightenDarkenColor(color, 120),
            width: "5px",
            height: "10px",
            borderRadius: 5,
            fontFamily: "Poppins",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: LightenDarkenColor(color, 120),
            borderRadius: 5,
            // "#D50000"
          },
          fontFamily: "Poppins",
          fontFamilyMonospace: "Poppins",
          headings: { fontFamily: "Poppins" },
          fontWeight: 200,
          colorScheme,
        }}
      >
        <ErrorBoundary>
          <Notifications position="top-right" zIndex={2077} />
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/region" element={<RegionSelection />} />
            <Route exact path="/:regionId/products" element={<Product />} />
            <Route
              exact
              path="/:regionId/product/:sku"
              element={<ProductDetails />}
            />
            <Route
              exact
              path="/:regionId/product/:sku/:batchId"
              element={<ProductBatchDetails />}
            />
            <Route exact path="/contact" element={<Contact />} />
            <Route element={<Sidebar />}>
              <Route
                exact
                path="/admin/dashboard"
                element={
                  <ProtectedRoutes>
                    <Dashboard />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/accountSettings"
                element={
                  <ProtectedRoutes>
                    <AccountSettings />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/products"
                element={
                  <ProtectedRoutes>
                    <Products />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/exports"
                element={
                  <ProtectedRoutes>
                    <Exports />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/coupons"
                element={
                  <ProtectedRoutes>
                    <Coupon />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/issues"
                element={
                  <ProtectedRoutes>
                    <Issue />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/reviews"
                element={
                  <ProtectedRoutes>
                    <Reviews />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/batches"
                element={
                  <ProtectedRoutes>
                    <ProductBatches />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/admin/trackingDetails/:id"
                element={
                  <ProtectedRoutes>
                    <TrackingDetails />
                  </ProtectedRoutes>
                }
              />
              {/* Settings page components starts */}
              <Route element={<SettingsComponent />}>
                <Route
                  exact
                  path="/admin/settings/brands"
                  element={
                    <ProtectedRoutes>
                      <Brands />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/category"
                  element={
                    <ProtectedRoutes>
                      <Category />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/categoryType"
                  element={
                    <ProtectedRoutes>
                      <CategoryTypes />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/certificates"
                  element={
                    <ProtectedRoutes>
                      <Certificates />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/deliveryFormats"
                  element={
                    <ProtectedRoutes>
                      <DeliveryFormat />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/distributors"
                  element={
                    <ProtectedRoutes>
                      <Distributors />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/dosageForm"
                  element={
                    <ProtectedRoutes>
                      <DosageForm />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/flavours"
                  element={
                    <ProtectedRoutes>
                      <Flavours />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/marketedBy"
                  element={
                    <ProtectedRoutes>
                      <Marketers />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/manufacturers"
                  element={
                    <ProtectedRoutes>
                      <Manufacturer />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/productType"
                  element={
                    <ProtectedRoutes>
                      <ProductTypes />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/region"
                  element={
                    <ProtectedRoutes>
                      <Region />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/regulatory"
                  element={
                    <ProtectedRoutes>
                      <Regulatory />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/retailers"
                  element={
                    <ProtectedRoutes>
                      <Retailer />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/settings/variant"
                  element={
                    <ProtectedRoutes>
                      <Variant />
                    </ProtectedRoutes>
                  }
                />
              </Route>
              {/* Settings page components ends */}
              {/* Product page components starts */}
              <Route element={<ProductDetailsComponent />}>
                <Route
                  exact
                  path="/admin/products/:id/productInformation"
                  element={
                    <ProtectedRoutes>
                      <ProductInformation />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/supplementFacts"
                  element={
                    <ProtectedRoutes>
                      <SupplementFacts />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/medicinalIngredients"
                  element={
                    <ProtectedRoutes>
                      <MedicinalIngredients />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/nonMedicinalIngredients"
                  element={
                    <ProtectedRoutes>
                      <NonMedicinalIngredients />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/regulatoryBody"
                  element={
                    <ProtectedRoutes>
                      <RegulatoryApprovals />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/dosageDetails"
                  element={
                    <ProtectedRoutes>
                      <DosageDetails />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/researchDocuments"
                  element={
                    <ProtectedRoutes>
                      <ResearchDocuments />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/productCertificates"
                  element={
                    <ProtectedRoutes>
                      <ProductCertificates />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/productImages"
                  element={
                    <ProtectedRoutes>
                      <ProductImages />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/productPages"
                  element={
                    <ProtectedRoutes>
                      <ProductPages />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/walmartSpecifications"
                  element={
                    <ProtectedRoutes>
                      <WalmartSpecifications />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  exact
                  path="/admin/products/:id/amazonSpecifications"
                  element={
                    <ProtectedRoutes>
                      <AmazonSpecifications />
                    </ProtectedRoutes>
                  }
                />
              </Route>
              {/* Product page components starts */}
            </Route>
          </Routes>
        </ErrorBoundary>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
