import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Anchor,
  AppShell,
  Burger,
  Button,
  Container,
  Group,
  Header,
  Paper,
  Text,
  Transition,
  createStyles,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import logo from "../../assets/images/logo.png";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { ModalsProvider } from "@mantine/modals";

const HEADER_HEIGHT = rem(80);

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(6)} ${rem(8)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#333333",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("md")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
  // Footer
  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  linksFoot: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

function MainComponent() {
  const links = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "About",
      link: "/about",
    },
    {
      label: "Products",
      link: "/region",
    },
    {
      label: "Contact",
      link: "/contact",
    },
  ];
  let navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(0);
  const { classes, cx } = useStyles();
  var year = new Date();
  const [localState, setLocalState] = useState(false);
  const items = links.map((link) => (
    <Anchor
      underline={false}
      className={cx(classes.link, {
        [classes.linkActive]: (link.label === active) === 1,
      })}
      href={link.link}
      onClick={() => handlePage(link.link)}
      key={link.label}
    >
      {link.label}
    </Anchor>
  ));

  const handlePage = (e) => {
    if (typeof e != "undefined") {
      navigate(e);
      setActive(e);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userIsAuthenticated = localStorage.getItem("isAuthenticated");
      if (userIsAuthenticated) {
        setLocalState(true);
      }
    } else {
      setLocalState(false);
    }
  }, []);
  return (
    <div>
      <AppShell
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
        padding="0px"
        header={
          <Header height={HEADER_HEIGHT}>
            <Container style={{ maxWidth: "80rem" }} className={classes.header}>
              <Group spacing={5} className={classes.links}>
                <img src={logo} alt="Logo" width={200} />
              </Group>
              <Group spacing={5} className={classes.links}>
                {items}
                {localState === true ? (
                  <Button
                    onClick={() => navigate("/admin/dashboard")}
                    style={{ backgroundColor: "#A61E4D" }}
                  >
                    Go to Dashboard
                  </Button>
                ) : (
                  <Button
                    onClick={() => navigate("/auth/login")}
                    style={{ backgroundColor: "#A61E4D" }}
                  >
                    Login
                  </Button>
                )}
              </Group>
              <Burger
                opened={opened}
                onClick={toggle}
                className={classes.burger}
                size="sm"
              />

              <Transition
                transition="pop-top-right"
                duration={200}
                mounted={opened}
              >
                {(styles) => (
                  <Paper className={classes.dropdown} withBorder style={styles}>
                    {items}
                  </Paper>
                )}
              </Transition>
            </Container>
          </Header>
        }
        footer={
          <div className={classes.footer}>
            <Container style={{ maxWidth: "80rem" }} className={classes.inner}>
              <Group spacing={5} className={classes.links}>
                <Text size={14} color="#A61E4D">
                  {year.getFullYear()} © {""}
                  <Anchor
                    color="#A61E4D"
                    target="_blank"
                    href="https://www.synbiopronutra.com/"
                  >
                    SynbioPro Nutraceutix Inc.
                  </Anchor>{" "}
                  All rights reserved.
                </Text>
              </Group>
              <Group
                spacing={0}
                className={classes.linksFoot}
                position="right"
                noWrap
              >
                <ActionIcon size="lg">
                  <IconBrandFacebook size="1.05rem" stroke={1.5} />
                </ActionIcon>
                <ActionIcon size="lg">
                  <IconBrandInstagram size="1.05rem" stroke={1.5} />
                </ActionIcon>
                <ActionIcon size="lg">
                  <IconBrandYoutube size="1.05rem" stroke={1.5} />
                </ActionIcon>
                <ActionIcon size="lg">
                  <IconBrandTwitter size="1.05rem" stroke={1.5} />
                </ActionIcon>
              </Group>
            </Container>
          </div>
        }
      >
        <Outlet />
      </AppShell>
    </div>
  );
}

export default MainComponent;
