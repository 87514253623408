import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  FileButton,
  Grid,
  Group,
  Image,
  Paper,
  PasswordInput,
  Skeleton,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useForm } from "@mantine/form";
import { IMAGE_URL_CONSTANTS } from "../../../utils/constants/constant";
import {
  handleGetUserOne,
  handleUpdateUserAccount,
  handleUpdateUserPassword,
  handleUploadUserProfileImage,
} from "../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../utils/common/notificationHelper";
// for image crop

function AccountSettings() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
  });

  const form = useForm({
    initialValues: {
      label: "",
      email: "",
      roleId: 1,
      phoneNumber: "",
      address: "",
      latitude: "",
      longitude: "",
    },
    validate: {
      label: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phoneNumber: (value) =>
        value.length < 10 ? "You must be at least 10 to update" : null,
    },
  });

  const formPassword = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: {
      password: (value) =>
        value.length < 2 ? "Password must have at least 2 letters" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const formImage = useForm({
    initialValues: {
      image: "",
    },
  });

  const [profileImage, setProfileImage] = useState("");
  const [username, setUsername] = useState("");

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const response = await handleGetUserOne();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            submitLoading: false,
            skeletonLoading: false,
          });
          form.setFieldValue("label", response.data.label);
          form.setFieldValue("email", response.data.email);
          form.setFieldValue("phoneNumber", response.data.phoneNumber);
          form.setFieldValue("latitude", response.data.latitude);
          form.setFieldValue("longitude", response.data.longitude);
          form.setFieldValue("address", response.data.address);
          setUsername(response.data.label);
          setProfileImage(response.data.image);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleUpdateProfileDetails = async (e) => {
    setVariables({
      ...variables,
      submitLoading: true,
      skeletonLoading: true,
    });
    const response = await handleUpdateUserAccount(e);
    // Check for response for actions

    if (response.status === 200) {
      setVariables({
        ...variables,
        submitLoading: false,
        skeletonLoading: false,
      });
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Account details updated successfully",
      });
      const dataValues = response.data.data[0];
      form.setFieldValue("label", dataValues.label);
      form.setFieldValue("email", dataValues.email);
      form.setFieldValue("phoneNumber", dataValues.phoneNumber);
      form.setFieldValue("latitude", response.data.latitude);
      form.setFieldValue("longitude", response.data.longitude);
      form.setFieldValue("address", response.data.address);
      setUsername(dataValues.label);
      setProfileImage(dataValues.image);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({
        ...variables,
        submitLoading: false,
        skeletonLoading: false,
      });
    }
  };

  const handleUpdatePasswordDetails = async (e) => {
    setVariables({
      ...variables,
      submitLoading: true,
      skeletonLoading: true,
    });
    if (e.password === e.confirmPassword) {
      const response = await handleUpdateUserPassword(e);
      // Check for response for actions
      if (response.status === 200) {
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Password updated successfully",
        });
        const dataValues = response.data.data[0];
        form.setFieldValue("label", dataValues.label);
        form.setFieldValue("email", dataValues.email);
        form.setFieldValue("phoneNumber", dataValues.phoneNumber);
        form.setFieldValue("latitude", response.data.latitude);
        form.setFieldValue("longitude", response.data.longitude);
        form.setFieldValue("address", response.data.address);
        setUsername(dataValues.label);
        setProfileImage(dataValues.image);
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
      }
    } else {
      alert("Password did`nt match");
    }
  };

  const handleUploadProfileImage = async (e) => {
    setVariables({
      ...variables,
      submitLoading: true,
      skeletonLoading: true,
    });
    if (image !== "") {
      var req = {
        image: image,
      };
      e = { ...e, ...req };
      const response = await handleUploadUserProfileImage(e);
      // Check for response for actions
      if (response.status === 200) {
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Profile image uploaded successfully",
        });
        setImage("");
        const dataValues = response.data.data[0];
        form.setFieldValue("label", dataValues.label);
        form.setFieldValue("email", dataValues.email);
        form.setFieldValue("phoneNumber", dataValues.phoneNumber);
        form.setFieldValue("latitude", response.data.latitude);
        form.setFieldValue("longitude", response.data.longitude);
        form.setFieldValue("address", response.data.address);
        setUsername(dataValues.label);
        setProfileImage(dataValues.image);
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
      }
    } else {
      alert("Please select the image");
    }
  };

  const ref = useRef();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Account Settings" />
      </Skeleton>
      <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
        <Card shadow="xs" padding="sm" radius="md">
          <form
            onSubmit={formImage.onSubmit((values) =>
              handleUploadProfileImage(values)
            )}
          >
            <Grid>
              <Grid.Col md={3} lg={3}></Grid.Col>
              <Grid.Col md={6} lg={6}>
                {/* For image croper */}
                {/* For croper */}

                <div
                  style={{
                    marginTop: 10,
                    position: "relative",
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    {!image ? (
                      <Avatar
                        src={IMAGE_URL_CONSTANTS.USER_IMAGE + profileImage}
                        size={150}
                        radius={150}
                        mx="auto"
                      />
                    ) : (
                      <Avatar src={image} size={150} radius={150} mx="auto" />
                    )}
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {username}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button variant="outline" mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              </Grid.Col>
              <Grid.Col md={3} lg={3}></Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Group position="center">
                  <Button
                    loading={variables.submitLoading}
                    type="submit"
                    size="sm"
                    variant="filled"
                  >
                    Upload Profile Image
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
      <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
        <Card shadow="xs" padding="sm" my={10} radius="md">
          <Text>Account Details</Text>
          <form
            onSubmit={form.onSubmit((values) =>
              handleUpdateProfileDetails(values)
            )}
          >
            <Grid style={{ padding: "10px" }}>
              <Grid.Col md={4} lg={4} mt={1}>
                <TextInput
                  label="Username"
                  withAsterisk
                  value={form.values.label}
                  placeholder="Username"
                  {...form.getInputProps("label")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4} mt={1}>
                <TextInput
                  label="Email"
                  withAsterisk
                  value={form.values.email}
                  placeholder="Email"
                  {...form.getInputProps("email")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4} mt={1}>
                <TextInput
                  label="Phone Number"
                  withAsterisk
                  value={form.values.phoneNumber}
                  placeholder="Phone Number"
                  {...form.getInputProps("phoneNumber")}
                />
              </Grid.Col>
              <Divider />
              <Grid.Col md={6} lg={6} mt={1}>
                <TextInput
                  label="Latitude"
                  withAsterisk
                  value={form.values.latitude}
                  placeholder="Latitude"
                  {...form.getInputProps("latitude")}
                />
              </Grid.Col>
              <Grid.Col md={6} lg={6} mt={1}>
                <TextInput
                  label="Longitude"
                  withAsterisk
                  value={form.values.longitude}
                  placeholder="Longitude"
                  {...form.getInputProps("longitude")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12} mt={1}>
                <Textarea
                  label="Address"
                  withAsterisk
                  value={form.values.address}
                  placeholder="Enter your address"
                  {...form.getInputProps("address")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12} mt={1}>
                <Group position="right">
                  <Button
                    loading={variables.submitLoading}
                    type="submit"
                    size="sm"
                    variant="filled"
                  >
                    Update Account Details
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
      <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
        <Card shadow="xs" padding="sm" my={10} radius="md">
          <Text>Change Password</Text>
          <form
            onSubmit={formPassword.onSubmit((values) =>
              handleUpdatePasswordDetails(values)
            )}
          >
            <Grid style={{ padding: "10px" }}>
              <Grid.Col md={4} lg={4} mt={1}>
                <PasswordInput
                  label="Password"
                  withAsterisk
                  value={formPassword.values.password}
                  placeholder="Password"
                  {...formPassword.getInputProps("password")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4} mt={1}>
                <PasswordInput
                  label="Confirm Password"
                  withAsterisk
                  value={formPassword.values.confirmPassword}
                  placeholder="Confirm Password"
                  {...formPassword.getInputProps("confirmPassword")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12} mt={1}>
                <Group position="right">
                  <Button
                    loading={variables.submitLoading}
                    type="submit"
                    size="sm"
                    variant="filled"
                  >
                    Update Password
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
    </>
  );
}

export default AccountSettings;
