/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import { useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Group,
  Paper,
  SimpleGrid,
  Skeleton,
  Text,
  TextInput,
  Textarea,
  createStyles,
  rem,
} from "@mantine/core";
import { IconAt, IconMapPin, IconPhone, IconSun } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { handleAddContactDetails } from "../../utils/helpers/apiFunctions";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import HeaderResponsive from "../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../components/mainComponents/FooterSocial";

const containerStyle = {
  width: "100%",
  height: "450px",
};

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan("sm");

  return {
    wrapper: {
      display: "flex",
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
      borderRadius: theme.radius.lg,
      padding: rem(4),
      border: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[2]
      }`,

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    form: {
      boxSizing: "border-box",
      flex: 1,
      padding: theme.spacing.xl,
      paddingLeft: `calc(${theme.spacing.xl} * 2)`,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: rem(-12),
    },

    fieldInput: {
      flex: 1,

      "& + &": {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: "flex",

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    contacts: {
      boxSizing: "border-box",
      position: "relative",
      borderRadius: theme.radius.lg,
      // backgroundImage: `url(${bg.src})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      border: `${rem(1)} solid transparent`,
      padding: theme.spacing.xl,
      flex: `0 0 ${rem(280)}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
      },
    },

    title: {
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  };
});

export default function Contact() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
  });
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    color: "",
    message: "",
  });
  const { classes } = useStyles();

  const [center, setCenter] = useState({
    lat: 12.3484343,
    lng: 76.6301862,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      subject: "",
      description: "",
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
    },
  });

  // Handle add function
  const handleAddContactInformation = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddContactDetails(e);
    // Check for response for actions
    if (response.status === 200) {
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        color: "green",
        message: "Enquire registered successfully..!",
      });
      setTimeout(() => {
        setVariables({
          ...variables,
          submitLoading: false,
          color: "",
          message: "",
        });
      }, 3000);
    } else {
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        color: "red",
        message: "Sorry.! could not send the enquire..",
      });

      setTimeout(() => {
        setVariables({
          ...variables,
          submitLoading: false,
          color: "",
          message: "",
        });
      }, 3000);
    }
  };
  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      <main>
        <Skeleton visible={variables.skeletonLoading}>
          {/* Banner Section */}
          <Container
            fluid
            mt={30}
            style={{
              height: "auto",
            }}
          >
            <Grid>
              <Grid.Col md={2} lg={2}></Grid.Col>
              <Grid.Col md={8} lg={8}>
                <Paper radius="lg" withBorder p={10}>
                  <Grid>
                    <Grid.Col
                      md={4}
                      lg={4}
                      style={{ backgroundColor: "#A61E4D", borderRadius: 10 }}
                    >
                      <Card radius="md" style={{ backgroundColor: "#A61E4D" }}>
                        <div className={classes.contacts}>
                          <Text
                            fz="lg"
                            fw={700}
                            className={classes.title}
                            c="#fff"
                          >
                            Contact information
                          </Text>
                          <Grid>
                            <Grid.Col md={2} lg={2}>
                              <IconAt color="white" />
                            </Grid.Col>
                            <Grid.Col md={9} lg={9}>
                              <Text size={14} color="white">
                                Email
                              </Text>
                              <Text size={14} color="white">
                                adesiraju@synbiopronutra.com
                              </Text>
                            </Grid.Col>
                          </Grid>
                          <Grid>
                            <Grid.Col md={2} lg={2}>
                              <IconPhone color="white" />
                            </Grid.Col>
                            <Grid.Col md={9} lg={9}>
                              <Text size={14} color="white">
                                Phone
                              </Text>
                              <Text size={14} color="white">
                                1888-297-9624
                              </Text>
                            </Grid.Col>
                          </Grid>
                          <Grid>
                            <Grid.Col md={2} lg={2}>
                              <IconMapPin color="white" />
                            </Grid.Col>
                            <Grid.Col md={9} lg={9}>
                              <Text size={14} color="white">
                                Address
                              </Text>
                              <Text size={14} color="white">
                                synbiopro@gmail.com
                              </Text>
                            </Grid.Col>
                          </Grid>
                          <Grid>
                            <Grid.Col md={2} lg={2}>
                              <IconSun color="white" />
                            </Grid.Col>
                            <Grid.Col md={9} lg={9}>
                              <Text size={14} color="white">
                                Working hours
                              </Text>
                              <Text size={14} color="white">
                                9 a.m. - 6 p.m.
                              </Text>
                            </Grid.Col>
                          </Grid>
                        </div>
                      </Card>
                    </Grid.Col>

                    <Grid.Col md={8} lg={8}>
                      <form
                        className={classes.form}
                        onSubmit={form.onSubmit((values) =>
                          handleAddContactInformation(values)
                        )}
                      >
                        <Text
                          fz="lg"
                          fw={700}
                          align="center"
                          color={variables.color}
                          className={classes.title}
                        >
                          {variables.message}
                        </Text>
                        <Text
                          fz="lg"
                          fw={700}
                          color="#545454"
                          className={classes.title}
                        >
                          Get in touch
                        </Text>

                        <div className={classes.fields}>
                          <SimpleGrid
                            cols={2}
                            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                          >
                            <TextInput
                              label="Your name"
                              placeholder="Your name"
                              required
                              value={form.values.username}
                              {...form.getInputProps("username")}
                            />
                            <TextInput
                              label="Your email"
                              placeholder="hello@example.dev"
                              required
                              value={form.values.email}
                              {...form.getInputProps("email")}
                            />
                          </SimpleGrid>

                          <TextInput
                            mt="md"
                            label="Subject"
                            placeholder="Subject"
                            required
                            value={form.values.subject}
                            {...form.getInputProps("subject")}
                          />

                          <Textarea
                            mt="md"
                            label="Your message"
                            placeholder="Please include all relevant information"
                            minRows={3}
                            value={form.values.description}
                            {...form.getInputProps("description")}
                          />

                          <Group position="right" mt="md">
                            <Button
                              type="submit"
                              style={{ backgroundColor: "#A61E4D" }}
                            >
                              Send message
                            </Button>
                          </Group>
                        </div>
                      </form>
                    </Grid.Col>
                  </Grid>
                </Paper>
              </Grid.Col>
              <Grid.Col md={2} lg={2}></Grid.Col>
            </Grid>
          </Container>
          {/* Banner Section */}
          {/* Second Section */}
          {isLoaded ? (
            <Container
              fluid
              mb={40}
              style={{
                height: "450px",
              }}
            >
              <Grid>
                <Grid.Col md={2} lg={2}></Grid.Col>
                <Grid.Col md={8} lg={8}>
                  <Paper radius="lg" withBorder p={10}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={17}
                    >
                      <Marker
                        clickable={true}
                        title="username"
                        zIndex={1000}
                        position={center}
                      />
                    </GoogleMap>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={2} lg={2}></Grid.Col>
              </Grid>
            </Container>
          ) : null}
          {/* Second Section */}
        </Skeleton>
      </main>
      <FooterSocial />
    </>
  );
}
