/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  FileInput,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  Radio,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Tabs,
  Text,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddResearchDocuments,
  handleGetResearchDocuments,
  handleGetRegion,
  handleSearchResearchDocuments,
  handleEditResearchDocuments,
  handleDuplicateResearchDocuments,
  handleDeleteResearchDocuments,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { IconFile, IconPhoto, IconTrash } from "@tabler/icons-react";
import { MIME_TYPES } from "@mantine/dropzone";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
import { useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { dataSlice } from "../../../../utils/common/common";

function ResearchDocuments() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
    pdfDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [defaultValue, setDefaultValue] = useState("1");
  const [updatedAt, setUpdatedAt] = useState();
  const [available, setAvailable] = useState(true);

  // For form validation
  const form = useForm({
    initialValues: {
      productId: params.id,
      regionId: "",
      type: "",
      document: "",
      fileName: "",
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      type: "",
      document: "",
      fileName: "",
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: defaultValue,
      regionIdTo: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetResearchDocuments(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            // Assign documents here
            setCount(response.data.data.length);
            setUpdatedAt(response.data.data[0].updatedAt);
            setVariables({
              ...variables,
              data: response.data.data.reverse(),
              submitLoading: false,
            });
            const datas = dataSlice({
              data: response.data.data,
              page: 1,
              total: 10,
            });
            setData(datas);
            formEdit.setFieldValue("regionId", 1);
          } else {
            setVariables({
              ...variables,
              data: response.data.data.reverse(),
              submitLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.type}</td>
      <td>
        <Anchor target="_blank" href={row.path}>
          {row.document}
        </Anchor>
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Document change handlers starts

  // Research Document
  const documentRef = useRef();
  const handleDocumentChange = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        form.setFieldValue("document", reader.result)
      );
      reader.addEventListener("load", () =>
        formEdit.setFieldValue("document", reader.result)
      );
      reader.readAsDataURL(e);
      form.setFieldValue("fileName", e.name);
      formEdit.setFieldValue("fileName", e.name);
      documentRef.current.value = "";
    }
  };
  // Document change handlers ends

  // Handle add function
  const handleCreateResearchDocuments = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    if (e.researchPdf !== "" && e.researchPdf !== null) {
      const response = await handleAddResearchDocuments(e);
      // Check for response for actions
      if (response.status === 200) {
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Research Documents uploaded successfully",
        });
        setCount(1);
        formEdit.reset();
        setVariables({
          ...variables,
          data: response.data.data.reverse(),
          submitLoading: false,
        });
        const datas = dataSlice({
          data: response.data.data,
          page: 1,
          total: 10,
        });
        setData(datas);
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    } else {
      alert("At least one document is required");
    }
  };

  const handleUpdateResearchDocuments = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditResearchDocuments(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Research Documents updated successfully",
      });
      setCount(1);
      formEdit.reset();
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search supplements facts by regionId & productId function
  const handleFetchRegionResearchDocuments = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    const response = await handleSearchResearchDocuments(req);
    if (response.status === 200) {
      if (
        response.data.data !== "" &&
        response.data.data !== null &&
        typeof response.data.data !== "undefined"
      ) {
        setVariables({
          ...variables,
          submitLoading: false,
        });
        if (response.data.data.length > 0) {
          // Assign documents here
          setCount(response.data.data.length);
          setAvailable(true);
          setData(response.data.data);
          formEdit.setFieldValue(
            "regionId",
            response.data.data[0].regionId.toString()
          );
        } else {
          setAvailable(false);
        }
      } else {
        setVariables({
          ...variables,
          submitLoading: false,
        });
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle duplicate supplements facts function
  const handleConfirmDuplicateResearchDocuments = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateResearchDocuments(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Research Documents duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the research document?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: defaultValue,
      productId: params.id,
      rowId: e,
    };
    const response = await handleDeleteResearchDocuments(req);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Research document deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() =>
                      handleFetchRegionResearchDocuments(item.value)
                    }
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              {available ? (
                <Tabs.Panel value={defaultValue} pt="xs">
                  <div style={{ padding: "10px" }}>
                    <form
                      onSubmit={formEdit.onSubmit((values) =>
                        handleUpdateResearchDocuments(values)
                      )}
                    >
                      <Grid>
                        <Grid.Col md={12} lg={12}>
                          <Text>Research Documents</Text>
                        </Grid.Col>
                        <Grid.Col md={4} lg={4}>
                          <Select
                            label="Select Type"
                            placeholder="Select Type"
                            data={[
                              "Research PDF",
                              "Human PDF",
                              "Technical PDF",
                              "Material PDF",
                              "Analysis PDF",
                              "Gras PDF",
                            ]}
                            withAsterisk
                            required
                            searchable
                            value={formEdit.values.type}
                            {...formEdit.getInputProps("type")}
                          />
                        </Grid.Col>
                        <Grid.Col md={4} lg={4}>
                          <FileInput
                            ref={documentRef}
                            label="Document"
                            placeholder="Document"
                            withAsterisk
                            required
                            accept={[MIME_TYPES.pdf]}
                            icon={<IconFile size={rem(14)} />}
                            onChange={handleDocumentChange}
                          />
                        </Grid.Col>

                        <Grid.Col md={12} lg={12}>
                          <Group position="right">
                            <Button
                              loading={variables.submitLoading}
                              disabled={variables.submitLoading}
                              radius="sm"
                              type="submit"
                              size="sm"
                              color="#A61E4D"
                            >
                              Upload Research Documents
                            </Button>
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </form>
                    <Grid mt={10}>
                      <Grid.Col md={12} lg={12}>
                        <ScrollArea scrollbarSize={10}>
                          <>
                            <Card withBorder>
                              <Table
                                striped
                                highlightOnHover
                                verticalSpacing="sm"
                              >
                                {/* Table header defines */}
                                <thead>
                                  <tr>
                                    <th>Sl.No</th>
                                    <th>Document Type</th>
                                    <th>Document</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                {rows.length > 0 ? (
                                  <tbody>{rows}</tbody>
                                ) : (
                                  <>
                                    {variables.submitLoading === true ? (
                                      <tbody>
                                        <tr>
                                          <td colSpan={8}>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <Loader
                                                size="md"
                                                color="#A61E4D"
                                                variant="dots"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td colSpan={8}>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <Text weight={500} align="center">
                                                No data available!
                                              </Text>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </>
                                )}
                              </Table>
                            </Card>
                          </>
                        </ScrollArea>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="left">
                          <Badge color="#FCC2D7">
                            Last updated on:&nbsp;
                            {new Date(updatedAt).toLocaleString("en-UK")}
                          </Badge>
                        </Group>
                        <Group position="right">
                          <Button
                            loading={variables.submitLoading}
                            disabled={variables.submitLoading}
                            radius="sm"
                            size="sm"
                            color="#A61E4D"
                            onClick={() =>
                              setVariables({
                                ...variables,
                                confirmDrawer: true,
                              })
                            }
                          >
                            Duplicate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </div>
                </Tabs.Panel>
              ) : (
                <Tabs.Panel value={defaultValue} pt="xs">
                  <Group position="center" mt="md">
                    <Text>
                      Research documents not available for selected region
                    </Text>
                  </Group>
                </Tabs.Panel>
              )}
            </Tabs>
          ) : (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={form.onSubmit((values) =>
                  handleCreateResearchDocuments(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    <Text>Research Documents</Text>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Select
                      label="Select Type"
                      placeholder="Select Type"
                      data={[
                        "Research PDF",
                        "Human PDF",
                        "Technical PDF",
                        "Material PDF",
                        "Analysis PDF",
                        "Gras PDF",
                      ]}
                      withAsterisk
                      required
                      searchable
                      value={form.values.type}
                      {...form.getInputProps("type")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <FileInput
                      ref={documentRef}
                      label="Document"
                      placeholder="Document"
                      withAsterisk
                      required
                      accept={[MIME_TYPES.pdf]}
                      icon={<IconFile size={rem(14)} />}
                      onChange={handleDocumentChange}
                    />
                  </Grid.Col>

                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        loading={variables.submitLoading}
                        disabled={variables.submitLoading}
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="#A61E4D"
                      >
                        Upload Research Documents
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
              <Grid mt={10}>
                <Grid.Col md={12} lg={12}>
                  <ScrollArea scrollbarSize={10}>
                    <>
                      <Card withBorder>
                        <Table striped highlightOnHover verticalSpacing="sm">
                          {/* Table header defines */}
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Document Type</th>
                              <th>Document</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {rows.length > 0 ? (
                            <tbody>{rows}</tbody>
                          ) : (
                            <>
                              {variables.submitLoading === true ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={8}>
                                      <div style={{ textAlign: "center" }}>
                                        <Loader
                                          size="md"
                                          color="#A61E4D"
                                          variant="dots"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan={8}>
                                      <div style={{ textAlign: "center" }}>
                                        <Text weight={500} align="center">
                                          No data available!
                                        </Text>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </>
                          )}
                        </Table>
                      </Card>
                    </>
                  </ScrollArea>
                </Grid.Col>
              </Grid>
            </div>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Regulatory Body"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateResearchDocuments(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.submitLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ResearchDocuments;
