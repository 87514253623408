/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Group,
  Image,
  Modal,
  Radio,
  Skeleton,
  Tabs,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddProductPage,
  handleGetRegion,
  handleSearchProductPage,
  handleEditProductPage,
  handleDuplicateProductPage,
  handleGetOneProductPage,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
// Text editor imports starts
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
import { IconPhoto, IconX } from "@tabler/icons-react";
// Text editor imports ends
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
// for image crop
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useParams } from "react-router-dom";

function ProductPages() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [defaultValue, setDefaultValue] = useState("1");

  // For form validation
  const form = useForm({
    initialValues: {
      productId: params.id,
      regionId: "",
      label: "",
      keyBenefits: "",
      keyApplication: "",
      sideCardHeading: "",
      sideCardDetails: "",
      sideCardHeading2: "",
      sideCardDetails2: "",
    },
    validate: {
      label: (value) => (value.length < 1 ? "Page title is required" : null),
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      label: "",
      keyBenefits: "",
      keyApplication: "",
      sideCardHeading: "",
      sideCardDetails: "",
      sideCardHeading2: "",
      sideCardDetails2: "",
    },
    validate: {
      label: (value) => (value.length < 1 ? "Page title is required" : null),
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: defaultValue,
      regionIdTo: "",
    },
  });

  // Editor for key benefits
  const [benefits, setBenefits] = useState();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Product Key Benefits" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyBenefits", html);
      formEdit.setFieldValue("keyBenefits", html);
      setBenefits(html);
    },
  });

  // Editor for key application
  const [application, setApplication] = useState();
  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Product Key Application" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyApplication", html);
      formEdit.setFieldValue("keyApplication", html);
      setApplication(html);
    },
  });

  // Editor for side card details - 1
  const [details, setDetails] = useState();
  const editor2 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Product side card details" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("sideCardDetails", html);
      formEdit.setFieldValue("sideCardDetails", html);
      setDetails(html);
    },
  });

  // Editor for side card details - 1
  const [details2, setDetails2] = useState();
  const editor3 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Product side card details" }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("sideCardDetails2", html);
      formEdit.setFieldValue("sideCardDetails2", html);
      setDetails2(html);
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const response = await handleGetOneProductPage(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
              skeletonLoading: false,
            });

            setCount(1);
            formEdit.setFieldValue("value", response.data.data.id);
            formEdit.setFieldValue(
              "regionId",
              response.data.data.regionId.toString()
            );
            if (
              response.data.data.label !== null &&
              response.data.data.label !== "" &&
              typeof response.data.data.label !== "undefined"
            ) {
              formEdit.setFieldValue("label", response.data.data.label);
            } else {
              formEdit.setFieldValue("label", "");
            }
            if (
              response.data.data.keyBenefits !== null &&
              response.data.data.keyBenefits !== "" &&
              typeof response.data.data.keyBenefits !== "undefined"
            ) {
              editor?.commands.setContent(response.data.data.keyBenefits);
              formEdit.setFieldValue(
                "keyBenefits",
                response.data.data.keyBenefits
              );
            } else {
              formEdit.setFieldValue("keyBenefits", "");
            }
            if (
              response.data.data.keyApplication !== null &&
              response.data.data.keyApplication !== "" &&
              typeof response.data.data.keyApplication !== "undefined"
            ) {
              editor1?.commands.setContent(response.data.data.keyApplication);
              formEdit.setFieldValue(
                "keyApplication",
                response.data.data.keyApplication
              );
            } else {
              formEdit.setFieldValue("keyApplication", "");
            }
            if (
              response.data.data.sideCardHeading !== null &&
              response.data.data.sideCardHeading !== "" &&
              typeof response.data.data.sideCardHeading !== "undefined"
            ) {
              formEdit.setFieldValue(
                "sideCardHeading",
                response.data.data.sideCardHeading
              );
            } else {
              formEdit.setFieldValue("sideCardHeading", "");
            }
            if (
              response.data.data.sideCardDetails !== null &&
              response.data.data.sideCardDetails !== "" &&
              typeof response.data.data.sideCardDetails !== "undefined"
            ) {
              editor2?.commands.setContent(response.data.data.sideCardDetails);
              formEdit.setFieldValue(
                "sideCardDetails",
                response.data.data.sideCardDetails
              );
            } else {
              formEdit.setFieldValue("sideCardDetails", "");
            }
            if (
              response.data.data.sideCardHeading2 !== null &&
              response.data.data.sideCardHeading2 !== "" &&
              typeof response.data.data.sideCardHeading2 !== "undefined"
            ) {
              formEdit.setFieldValue(
                "sideCardHeading2",
                response.data.data.sideCardHeading2
              );
            } else {
              formEdit.setFieldValue("sideCardHeading2", "");
            }
            if (
              response.data.data.sideCardDetails2 !== null &&
              response.data.data.sideCardDetails2 !== "" &&
              typeof response.data.data.sideCardDetails2 !== "undefined"
            ) {
              editor3?.commands.setContent(response.data.data.sideCardDetails2);
              formEdit.setFieldValue(
                "sideCardDetails2",
                response.data.data.sideCardDetails2
              );
            } else {
              formEdit.setFieldValue("sideCardDetails2", "");
            }
            if (
              response.data.data.banner !== null &&
              response.data.data.banner !== "" &&
              typeof response.data.data.banner !== "undefined"
            ) {
              setImage(response.data.data.banner);
            } else {
              setImage("");
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
              skeletonLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [editor, editor1, editor2, editor3]);

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e[0]);
      ref.current.value = "";
    }
  };

  // Handle add function
  const handleCreateProductPage = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
    };
    e = { ...e, ...req };
    const response = await handleAddProductPage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product page created successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleUpdateProductPage = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProductPage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product page updated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search supplements facts by regionId & productId function
  const handleFetchRegionProductPage = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    const response = await handleSearchProductPage(req);
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        setCount(response.data.data.length);
        // Assign form values here
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle duplicate supplements facts function
  const handleConfirmDuplicateProductPage = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateProductPage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product page duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() => handleFetchRegionProductPage(item.value)}
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel value={defaultValue} pt="xs">
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formEdit.onSubmit((values) =>
                      handleUpdateProductPage(values)
                    )}
                  >
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <TextInput
                          label="Product Page Title"
                          value={formEdit.values.label}
                          placeholder="Product Page Title"
                          {...formEdit.getInputProps("label")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Product Key Benefits</Text>
                        <RichTextEditor editor={editor}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={benefits}
                            mb={20}
                            {...formEdit.getInputProps("keyBenefits")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Product Key Application</Text>
                        <RichTextEditor editor={editor1}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>

                          <RichTextEditor.Content
                            value={application}
                            mb={20}
                            {...formEdit.getInputProps("keyApplication")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <TextInput
                          label="Side Card Heading"
                          value={formEdit.values.sideCardHeading}
                          placeholder="Side Card Heading"
                          {...formEdit.getInputProps("sideCardHeading")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Side Card Details </Text>
                        <RichTextEditor editor={editor2}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={details}
                            mb={20}
                            {...formEdit.getInputProps("sideCardDetails")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <TextInput
                          label="Side Card Heading-2"
                          value={formEdit.values.sideCardHeading2}
                          placeholder="Side Card Heading-2"
                          {...formEdit.getInputProps("sideCardHeading2")}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text mb={5}>Side Card Details-2 </Text>
                        <RichTextEditor editor={editor3}>
                          <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Bold />
                              <RichTextEditor.Italic />
                              <RichTextEditor.Underline />
                              <RichTextEditor.Strikethrough />
                              <RichTextEditor.ClearFormatting />
                              <RichTextEditor.Highlight />
                              <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.H1 />
                              <RichTextEditor.H2 />
                              <RichTextEditor.H3 />
                              <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Blockquote />
                              <RichTextEditor.Hr />
                              <RichTextEditor.BulletList />
                              <RichTextEditor.OrderedList />
                              <RichTextEditor.Subscript />
                              <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.Link />
                              <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                              <RichTextEditor.AlignLeft />
                              <RichTextEditor.AlignCenter />
                              <RichTextEditor.AlignJustify />
                              <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                          </RichTextEditor.Toolbar>
                          <RichTextEditor.Content
                            value={details2}
                            mb={20}
                            {...formEdit.getInputProps("sideCardDetails2")}
                          />
                        </RichTextEditor>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Text fw={500} mb={10}>
                          Product Page Banner Image
                        </Text>
                        {uploadImage !== "" && uploadImage !== null ? (
                          <>
                            <ReactCrop
                              crop={crop}
                              onChange={(_, percentCrop) =>
                                setCrop(percentCrop)
                              }
                              onComplete={(c) => setCompletedCrop(c)}
                              aspect={aspect}
                            >
                              <Image
                                ref={imgRef}
                                alt="Crop me"
                                src={uploadImage}
                                style={{
                                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                                }}
                                onLoad={onImageLoad}
                              />
                            </ReactCrop>
                            <div>
                              {previewCanvasRef != null ? (
                                <canvas
                                  ref={previewCanvasRef}
                                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                  style={{
                                    width: Math.round(
                                      completedCrop?.width ?? 0
                                    ),
                                    height: Math.round(
                                      completedCrop?.height ?? 0
                                    ),
                                    marginBottom: 50,
                                    display: "none",
                                  }}
                                />
                              ) : null}

                              <Group position="right" mt="md" mb={20}>
                                <Button
                                  type="submit"
                                  color="zevcore"
                                  onClick={() => {
                                    setUploadImage("");
                                    setImage("");
                                  }}
                                >
                                  Clear Image
                                </Button>
                              </Group>
                            </div>
                          </>
                        ) : (
                          <Dropzone
                            openRef={openRef}
                            onDrop={changeHandler}
                            onReject={(files) =>
                              console.log("rejected files", files)
                            }
                            style={{
                              borderWidth: 1,
                              paddingBottom: 50,
                            }}
                            radius="md"
                            maxSize={3 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                          >
                            <Group
                              position="center"
                              spacing="md"
                              style={{
                                minHeight: rem(220),
                                pointerEvents: "none",
                              }}
                            >
                              <Dropzone.Accept>
                                <IconPhoto
                                  size="3.2rem"
                                  stroke={1.5}
                                  color="Green"
                                />
                              </Dropzone.Accept>
                              <Dropzone.Reject>
                                <IconX size="3.2rem" stroke={1.5} color="red" />
                              </Dropzone.Reject>
                              <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                              </Dropzone.Idle>

                              <div>
                                <Text size="xl" inline>
                                  Drag banner image here or click to select
                                  files
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7}>
                                  Attach as many files as you like, each file
                                  should not exceed 5mb
                                </Text>
                              </div>
                            </Group>
                          </Dropzone>
                        )}
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="right">
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            type="submit"
                            size="sm"
                            color="#A61E4D"
                          >
                            Update Product Page
                          </Button>
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            size="sm"
                            color="#A61E4D"
                            onClick={() =>
                              setVariables({
                                ...variables,
                                confirmDrawer: true,
                              })
                            }
                          >
                            Duplicate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={form.onSubmit((values) =>
                  handleCreateProductPage(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Product Page Title"
                      value={form.values.label}
                      placeholder="Product Page Title"
                      {...form.getInputProps("label")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Product Key Benefits</Text>
                    <RichTextEditor editor={editor}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>

                      <RichTextEditor.Content
                        value={benefits}
                        mb={20}
                        {...form.getInputProps("keyBenefits")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Product Key Application</Text>
                    <RichTextEditor editor={editor1}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>

                      <RichTextEditor.Content
                        value={application}
                        mb={20}
                        {...form.getInputProps("keyApplication")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Side Card Heading"
                      value={form.values.sideCardHeading}
                      placeholder="Side Card Heading"
                      {...form.getInputProps("sideCardHeading")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Side Card Details </Text>
                    <RichTextEditor editor={editor2}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={details}
                        mb={20}
                        {...form.getInputProps("sideCardDetails")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Side Card Heading-2"
                      value={form.values.sideCardHeading2}
                      placeholder="Side Card Heading-2"
                      {...form.getInputProps("sideCardHeading2")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text mb={5}>Side Card Details-2 </Text>
                    <RichTextEditor editor={editor3}>
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>
                      <RichTextEditor.Content
                        value={details2}
                        mb={20}
                        {...form.getInputProps("sideCardDetails2")}
                      />
                    </RichTextEditor>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Text fw={500} mb={10}>
                      Product Page Banner Image
                    </Text>

                    <Dropzone
                      openRef={openRef}
                      onDrop={changeHandler}
                      onReject={(files) => console.log("rejected files", files)}
                      style={{
                        borderWidth: 1,
                        paddingBottom: 50,
                      }}
                      radius="md"
                      maxSize={3 * 1024 ** 2}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        position="center"
                        spacing="md"
                        style={{ minHeight: rem(220), pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto size="3.2rem" stroke={1.5} color="Green" />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX size="3.2rem" stroke={1.5} color="red" />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconPhoto size="3.2rem" stroke={1.5} />
                        </Dropzone.Idle>

                        <div>
                          <Text size="xl" inline>
                            Drag banner image here or click to select files
                          </Text>
                          <Text size="sm" color="dimmed" inline mt={7}>
                            Attach as many files as you like, each file should
                            not exceed 5mb
                          </Text>
                        </div>
                      </Group>
                    </Dropzone>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        loading={variables.skeletonLoading}
                        disabled={variables.skeletonLoading}
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="#A61E4D"
                      >
                        Add Product Page
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Product Page"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateProductPage(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductPages;
