/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  NativeSelect,
  Pagination,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useForm } from "@mantine/form";
import {
  handleAddProductBatch,
  handleDeleteProductBatch,
  handleEditProductBatch,
  handleGetProduct,
  handleGetProductBatch,
} from "../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../utils/common/common";
import {
  IconClock,
  IconFileExport,
  IconListNumbers,
  IconPencil,
  IconPlane,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

function ProductBatches() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const ref = useRef();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [product, setProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // For form validation
  const form = useForm({
    initialValues: {
      productId: "",
      regionId: "",
      idStartFrom: "",
      noOfProducts: "",
      inWordDate: new Date(),
      inWordTime: "",
      manufactureDate: new Date(),
      expireDate: new Date(),
    },
    validate: {
      productId: (value) => (value.length < 1 ? "Product is required" : null),
    },
  });

  // For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: "",
      regionId: "",
      idStartFrom: "",
      noOfProducts: "",
      inWordDate: "",
      inWordTime: "",
      manufactureDate: "",
      expireDate: "",
    },
  });

  const formSearch = useForm({
    initialValues: {
      productId: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          skeletonLoading: true,
          submitLoading: true,
        });
        const response = await handleGetProductBatch();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
            submitLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setData(datas);
        }

        const response1 = await handleGetProduct();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setProduct(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td>
        <ActionIcon>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={row.productId}
            viewBox={`0 0 256 256`}
          />
        </ActionIcon>
      </td>
      <td>{row.idStartFrom}</td>
      <td>{row.noOfProducts}</td>
      <td>{new Date(row.inWordDate).toLocaleDateString("en-UK")}</td>
      <td>{row.inWordTime}</td>
      <td>{new Date(row.manufactureDate).toLocaleDateString("en-UK")}</td>
      <td>{new Date(row.expireDate).toLocaleDateString("en-UK")}</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => navigate(`/admin/trackingDetails/${row.id}`)}
          >
            <IconPencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add function
  const handleCreateProductBatch = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddProductBatch(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Batch created successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the coupon <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProductBatch(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Batch deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Product Batch" />
      </Skeleton>
      <Grid>
        <Grid.Col md={12} lg={12}>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <div style={{ padding: "10px" }}>
                <Text fw={500} mb={20}>
                  Add Product Batch
                </Text>
                <form
                  onSubmit={form.onSubmit((values) =>
                    handleCreateProductBatch(values)
                  )}
                >
                  <Grid>
                    <Grid.Col md={4} lg={4}>
                      <Select
                        label="Select Product"
                        placeholder="Select Product"
                        data={product}
                        searchable
                        value={form.values.productId}
                        clearable
                        {...form.getInputProps("productId")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TextInput
                        label="Starts From"
                        withAsterisk
                        value={form.values.idStartFrom}
                        placeholder="Starts From"
                        {...form.getInputProps("idStartFrom")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TextInput
                        label="Total Products"
                        withAsterisk
                        value={form.values.noOfProducts}
                        placeholder="Total Products"
                        {...form.getInputProps("noOfProducts")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="In Word Date"
                        placeholder="In Word Date"
                        value={form.values.inWordDate}
                        {...form.getInputProps("inWordDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <TimeInput
                        label="In Word Time"
                        ref={ref}
                        rightSection={
                          <ActionIcon onClick={() => ref.current.showPicker()}>
                            <IconClock size="1rem" stroke={1.5} />
                          </ActionIcon>
                        }
                        value={form.values.inWordTime}
                        {...form.getInputProps("inWordTime")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="Manufacture Date"
                        placeholder="Manufacture Date"
                        value={form.values.manufactureDate}
                        {...form.getInputProps("manufactureDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <DatePickerInput
                        label="Expire Date"
                        placeholder="Expire Date"
                        value={form.values.expireDate}
                        {...form.getInputProps("expireDate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="right">
                        <Button
                          radius="sm"
                          type="submit"
                          size="sm"
                          color="#A61E4D"
                        >
                          Add Product Batch
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </form>
              </div>
            </Card>
          </Skeleton>
          <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
            <Card shadow="xs" padding="sm" radius="md">
              <Title order={6} mb={5}>
                Search Product
              </Title>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    placeholder="Select Product"
                    data={product}
                    searchable
                    required
                    clearable
                    {...formSearch.getInputProps("productId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Button
                    radius="md"
                    type="submit"
                    color="#A61E4D"
                    leftIcon={<IconSearch size="1rem" />}
                  >
                    Search
                  </Button>
                </Grid.Col>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                <TextInput
                  variant="filled"
                  placeholder="Search by any field"
                  mb="md"
                  size="xs"
                  value={search}
                  icon={<IconSearch size={14} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Group spacing="xs">
                  {/* Drawer open for adding new tax data */}

                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "product", key: "product.label" },
                      { label: "id start from", key: "idStartFrom" },
                      { label: "no of products", key: "noOfProducts" },
                      { label: "in word date", key: "inWordDate" },
                      { label: "in word time", key: "inWordTime" },
                      { label: "manufacture date", key: "manufactureDate" },
                      { label: "expire date", key: "expireDate" },
                    ]}
                    filename="product_batches.csv"
                  >
                    <Button
                      variant="outline"
                      color="zevcore"
                      size="xs"
                      leftIcon={<IconFileExport size={12} />}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Group>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>QR-Code</th>
                      <th>Start From</th>
                      <th>Inward Quantity</th>
                      <th>Inward Date</th>
                      <th>Inward Time</th>
                      <th>Total Products</th>
                      <th>Manufacture Date</th>
                      <th>Expire Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {variables.submitLoading === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={10}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={10}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      setData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Skeleton>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default ProductBatches;
