/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  Modal,
  NativeSelect,
  Pagination,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddDeliveryFormats,
  handleDeleteDeliveryFormats,
  handleEditDeliveryFormats,
  handleGetDeliveryFormats,
} from "../../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../../utils/common/common";
import {
  IconFileExport,
  IconPencil,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { CSVLink } from "react-csv";

function DeliveryFormat() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // For form validation
  const form = useForm({
    initialValues: {
      label: "",
      subType: "",
    },
    validate: {
      label: (value) =>
        value.length < 2
          ? "Delivery format name must have at least 2 letters"
          : null,
      subType: (value) =>
        value.length < 2
          ? "Delivery format sub type must have at least 2 letters"
          : null,
    },
  });

  // For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      label: "",
      subType: "",
    },
    validate: {
      label: (value) =>
        value.length < 2
          ? "Delivery format name must have at least 2 letters"
          : null,
      subType: (value) =>
        value.length < 2
          ? "Delivery format sub type must have at least 2 letters"
          : null,
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetDeliveryFormats();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            submitLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setLoadingData(false);
          setData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={row.label}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{row.label}</td>
      <td>{row.subType}</td>
      <td>--</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <IconPencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add function
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddDeliveryFormats(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Delivery format added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle open edit modal
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    formEdit.setFieldValue("value", datas.id);
    formEdit.setFieldValue("label", datas.label);
    formEdit.setFieldValue("subType", datas.subType);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Handle update data function
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditDeliveryFormats(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Delivery format updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the Delivery format{" "}
          <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteDeliveryFormats(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Delivery format deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card shadow="xs" padding="sm" radius="md">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <TextInput
              variant="filled"
              placeholder="Search by any field"
              mb="md"
              size="xs"
              value={search}
              icon={<IconSearch size={14} />}
              onChange={async (e) => {
                // On change search ofr the data that is enter
                setSearch(e.currentTarget.value);
                setPage(1);
                const datas = await dataSearch({
                  data: variables.data,
                  value: e.currentTarget.value,
                  activePage: activePage,
                  total: total,
                });
                setData(datas);
                setRefreshTable(new Date());
              }}
              sx={{ width: 250 }}
            />
            <Group spacing="xs">
              {/* Drawer open for adding new tax data */}
              <Button
                variant="outline"
                color="zevcore"
                size="xs"
                mr={10}
                onClick={() =>
                  setVariables({
                    ...variables,
                    addDrawer: true,
                  })
                }
                leftIcon={<IconPlus size={12} />}
              >
                Add Delivery Format
              </Button>
              <CSVLink
                data={variables.data}
                headers={[
                  {
                    label: "Delivery Format Type",
                    key: "label",
                  },
                  {
                    label: "Delivery Format Sub Type",
                    key: "subType",
                  },
                ]}
                filename="delivery_format.csv"
              >
                <Button
                  variant="outline"
                  color="zevcore"
                  size="xs"
                  leftIcon={<IconFileExport size={12} />}
                >
                  Export
                </Button>
              </CSVLink>
            </Group>
          </div>
          <ScrollArea>
            <Table striped highlightOnHover verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Delivery Format Type</th>
                  <th>Delivery Format Sub Type</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === false ? (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#A61E4D" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              No data available!
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Rows per page
              </Text>
              <NativeSelect
                ml={5}
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* Last update on badge starts */}
            {variables.data.length > 0 ? (
              <>
                <Badge color="#FCC2D7" ml={5} mt={3}>
                  Last updated on:&nbsp;
                  {new Date(
                    variables.data.reduce(function (r, a) {
                      return r.updatedAt > a.updatedAt ? r : a;
                    }).updatedAt
                  ).toLocaleString("en-UK")}
                </Badge>
              </>
            ) : null}
            {/* Last update on badge ends */}
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
        </Card>
      </Skeleton>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Delivery Format"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <TextInput
                    label="Format Type"
                    withAsterisk
                    value={form.values.label}
                    placeholder="Format Type"
                    {...form.getInputProps("label")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <TextInput
                    label="Format Sub Type"
                    withAsterisk
                    value={form.values.subType}
                    placeholder="Format Sub Type"
                    {...form.getInputProps("subType")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="#A61E4D"
                  >
                    Add Delivery Format
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Delivery Format Details"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Format Type"
                  withAsterisk
                  required
                  value={formEdit.values.label}
                  placeholder="Format Type"
                  {...formEdit.getInputProps("label")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Format Sub Type"
                  withAsterisk
                  required
                  value={formEdit.values.subType}
                  placeholder="Format Sub Type"
                  {...formEdit.getInputProps("subType")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="#A61E4D"
                >
                  Update Delivery Format
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}
    </>
  );
}

export default DeliveryFormat;
