/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import { useEffect, useState } from "react";
import {
  Accordion,
  Anchor,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  Mark,
  Modal,
  NumberInput,
  Paper,
  Rating,
  Skeleton,
  Tabs,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import {
  IconCertificate,
  IconDrone,
  IconExclamationMark,
  IconEye,
  IconFile,
  IconInfoCircle,
  IconMessageCircle,
  IconPercentage,
  IconPhoto,
  IconRating12Plus,
  IconReportAnalytics,
  IconSettings,
  IconStar,
  IconStarFilled,
} from "@tabler/icons-react";
import {
  handleAddCoupons,
  handleAddProductIssue,
  handleAddProductReview,
  handleGetSkuProductWebsite,
} from "../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useForm } from "@mantine/form";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import renderHTML from "react-render-html";
import { Carousel } from "@mantine/carousel";
import { IMAGE_URL_CONSTANTS } from "../../../utils/constants/constant";
import { useParams } from "react-router-dom";
import HeaderResponsive from "../../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../../components/mainComponents/FooterSocial";

const containerStyle = {
  width: "100%",
  height: "450px",
};

export default function ProductBatchDetails() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
  });
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    couponDrawer: false,
    reviewDrawer: false,
    issueDrawer: false,
  });

  const [center, setCenter] = useState({
    lat: 12.3484343,
    lng: 76.6301862,
  });

  // render states
  const params = useParams();
  const [productId, setProductId] = useState("");
  const [label, setLabel] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [keyBenefits, setKeyBenefits] = useState("");
  const [servingSize, setServingSize] = useState("");
  const [servingContainer, setServingContainer] = useState("");
  const [starValue, setStarValue] = useState("");
  const [plusValue, setPlusValue] = useState("");
  const [amountServing, setAmountServing] = useState("");
  const [medicinalIngredient, setMedicinalIngredient] = useState([]);
  const [nonMedicinalIngredient, setNonMedicinalIngredient] = useState([]);
  const [recommendedDosage, setRecommendedDosage] = useState("");
  const [recommendedPurpose, setRecommendedPurpose] = useState("");
  const [riskInformation, setRiskInformation] = useState("");
  const [waringText, setWaringText] = useState("");
  const [productGtin, setProductGtin] = useState("");
  const [productCount, setProductCount] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [regulatory, setRegulatory] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [reviewArray, setReviewArray] = useState([]);
  const [relatedDocument, setRelatedDocuments] = useState([]);
  const [certificateArray, setCertificateArray] = useState([]);

  // For coupon form validation
  const couponForm = useForm({
    initialValues: {
      username: "",
      phoneNumber: "",
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
    },
  });

  // For review form validation
  const reviewForm = useForm({
    initialValues: {
      username: "",
      phoneNumber: "",
      rating: "",
      description: "",
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
    },
  });

  // For issue form validation
  const issueForm = useForm({
    initialValues: {
      username: "",
      phoneNumber: "",
      issue: "",
      description: "",
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Username must have at least 2 letters" : null,
    },
  });

  // Fetching data from products table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const req = {
          sku: params.sku,
          regionId: params.regionId,
        };
        const response = await handleGetSkuProductWebsite(req);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            // data: response.data.data.reverse(),
            skeletonLoading: false,
            submitLoading: false,
            data: response.data.data[0],
          });
          setProductId(response.data.data[0].id);
          setLabel(response.data.data[0].label);
          setBrand(response.data.data[0].brand.label);
          setCategory(response.data.data[0].category.label);
          setDescription(response.data.data[0].productDescription);
          setKeyBenefits(response.data.data[0].keyBenefits);

          setCountryOrigin("India");
          if (response.data.data[0].supplement_facts.length > 0) {
            setServingSize(
              response.data.data[0].supplement_facts[0].servingSize
            );
            setServingContainer(
              response.data.data[0].supplement_facts[0].servingContainer
            );
            setAmountServing(
              response.data.data[0].supplement_facts[0].amountServing
            );
            setStarValue(response.data.data[0].supplement_facts[0].starValue);
            setPlusValue(response.data.data[0].supplement_facts[0].plusValue);
            setMedicinalIngredient(response.data.data[0].medicinal_ingredients);
            setNonMedicinalIngredient(
              response.data.data[0].non_medicinal_ingredients
            );
            setRecommendedDosage(
              response.data.data[0].dosage_details[0].directions
            );
            setRecommendedPurpose(
              response.data.data[0].dosage_details[0].directions
            );
            setRiskInformation(
              response.data.data[0].dosage_details[0].riskInformation
            );
            setWaringText(
              response.data.data[0].dosage_details[0].cautionWarnings
            );
            const imageObject = response.data.data[0].product_images[0].image;
            const objectArray = imageObject.split(",");
            setImageArray(objectArray);
            setRegulatory(response.data.data[0].regulator_bodies);
            setReviewArray(response.data.data[0].product_reviews);
            setRelatedDocuments(response.data.data[0].research_documents);
            setRelatedDocuments(response.data.data[0].research_documents);
            setCertificateArray(response.data.data[0].product_certificates);
          }
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Handle add function
  const handleSubmitCouponRequest = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddCoupons(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Coupon requested successfully",
      });
      couponForm.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle add function
  const handleSubmitReviewDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      productId: productId,
      regionId: params.regionId,
    };
    e = { ...e, ...req };
    const response = await handleAddProductReview(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Review submitted successfully",
      });
      reviewForm.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle add function
  const handleSubmitIssueDetails = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddProductIssue(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Issue submitted successfully",
      });
      issueForm.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Fetching data from products table
  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Skeleton visible={variables.skeletonLoading}>
          {/* Banner Section */}
          <Container
            my={20}
            style={{
              height: "auto",
            }}
          >
            <Grid>
              <Grid.Col md={6} lg={6}>
                <Card>
                  <Group position="center">
                    <Carousel maw={400} mx="auto" withIndicators height="auto">
                      {imageArray.map((image, index) => (
                        <Carousel.Slide key={index}>
                          <Image
                            src={IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + image}
                            width="100%"
                            height="100%"
                            alt="Product image"
                          />
                        </Carousel.Slide>
                      ))}
                    </Carousel>
                  </Group>
                </Card>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Card style={{ backgroundColor: "#eeeeee" }}>
                  <Group position="left">
                    <Text size={12}>
                      Product Name: <Text color="dimmed">{label}</Text>
                    </Text>
                  </Group>
                  <Text size={12}>Category Type: {category}</Text>
                  <Text size={12}>Brand: {brand}</Text>
                  <Text size={12}>SKU: {variables.data.sku}</Text>
                  <Text size={12}>Serving Size: {servingSize}</Text>
                  <Text size={12}>
                    Serving Per Container: {servingContainer}
                  </Text>
                  <Text size={12}>Delivery Format: {servingContainer}</Text>
                </Card>
                <Card>
                  <Text size={12}>Description: {renderHTML(description)}</Text>
                </Card>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Divider size={1} />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Card>
                  <Text fw={700} size={20} py={20} align="center">
                    Product Description
                  </Text>
                  <Tabs defaultValue="information">
                    <Tabs.List grow>
                      <Tabs.Tab
                        p={10}
                        value="information"
                        icon={<IconInfoCircle size="1.5rem" />}
                      >
                        Information
                      </Tabs.Tab>
                      <Tabs.Tab
                        value="documents"
                        icon={<IconFile size="1.5rem" />}
                      >
                        Related Documents
                      </Tabs.Tab>
                      <Tabs.Tab
                        value="approvals"
                        icon={<IconReportAnalytics size="1.5rem" />}
                      >
                        Regulatory Approvals
                      </Tabs.Tab>
                      <Tabs.Tab
                        value="certificates"
                        icon={<IconCertificate size="1.5rem" />}
                      >
                        Certificates
                      </Tabs.Tab>
                      <Tabs.Tab
                        value="reviews"
                        icon={<IconStarFilled size="1.5rem" />}
                      >
                        Product Reviews
                      </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="information" pt="xs">
                      <Paper radius="md" p="md" withBorder>
                        <Grid>
                          <Grid.Col md={4} lg={4}>
                            <Paper p="sm" withBorder>
                              <Title order={4} mb={10} align="center">
                                Supplement Facts
                              </Title>
                              <Text size={10}>Serving Size: {servingSize}</Text>
                              <Text size={10}>
                                Serving Per Container: {servingContainer}
                              </Text>
                              <Divider size="sm" my={5} />
                              <Grid>
                                <Grid.Col md={4} lg={4}>
                                  <Text size={10}>Medicinal Ingredient</Text>
                                </Grid.Col>
                                <Grid.Col md={4} lg={4}>
                                  <Text size={10}>Amt Per Serving</Text>
                                </Grid.Col>
                                <Grid.Col md={4} lg={4}>
                                  <Text size={10}>% DV*</Text>
                                </Grid.Col>
                              </Grid>
                              <Divider size="xs" my={5} />
                              {medicinalIngredient.map((item, index) => (
                                <Grid key={index}>
                                  <Grid.Col md={4} lg={4}>
                                    <Text size={10}>{item.label}</Text>
                                  </Grid.Col>
                                  <Grid.Col md={4} lg={4}>
                                    <Text size={10}>
                                      {item.unit1} {item.unit2} CFU
                                    </Text>
                                  </Grid.Col>
                                  <Grid.Col md={4} lg={4}>
                                    <Text size={10}>{item.quantity} %</Text>
                                  </Grid.Col>
                                </Grid>
                              ))}
                              <Divider size="xs" my={5} />
                              <Text size={10}>
                                <Text fw={700}>
                                  * : {renderHTML(starValue)}
                                </Text>
                              </Text>
                              <Text size={10}>
                                <Text fw={700}>
                                  ** : {renderHTML(plusValue)}
                                </Text>
                              </Text>
                            </Paper>
                            <Paper my={5} p="sm" withBorder>
                              <Text align="left" size={12}>
                                These statements have not been evaluated by the
                                food and drug administration. This product is
                                not intended to diagnose, treat cure or prevent
                                any disease.
                              </Text>
                            </Paper>

                            <Text py={5} size={12}>
                              <Text fw={700}>Non-Medicinal Ingredients:</Text>
                              {nonMedicinalIngredient.map((item, index) => (
                                <>{item.label}, </>
                              ))}
                            </Text>
                          </Grid.Col>
                          <Grid.Col md={4} lg={4}>
                            <Paper p="sm" withBorder>
                              <Text py={5} size={12}>
                                <Text fw={700}>Recommended Dosage:</Text>{" "}
                                {renderHTML(recommendedDosage)}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>
                                  Recommended Use or Purpose:
                                </Text>
                                {renderHTML(recommendedPurpose)}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Risk Information:</Text>
                                {renderHTML(riskInformation)}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Caution and Warnings:</Text>
                                {renderHTML(waringText)}
                              </Text>
                            </Paper>
                          </Grid.Col>
                          <Grid.Col md={4} lg={4}>
                            <Paper p="sm" withBorder>
                              <Text py={5} size={12}>
                                <Text fw={700}>Product GTIN:</Text>{" "}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Product Count:</Text>{" "}
                                {amountServing}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Country of Origin:</Text>{" "}
                                {countryOrigin}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Manufactured By:</Text>{" "}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Marketed By:</Text>{" "}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Imported & Distributed By:</Text>{" "}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Manufacturing Date:</Text>{" "}
                              </Text>
                              <Text py={5} size={12}>
                                <Text fw={700}>Best Before:</Text>{" "}
                              </Text>
                            </Paper>
                          </Grid.Col>
                        </Grid>
                      </Paper>
                    </Tabs.Panel>

                    <Tabs.Panel value="documents">
                      {relatedDocument.map((item, index) => (
                        <Paper
                          key={index}
                          style={{ backgroundColor: "#eeeeee" }}
                          radius="md"
                          p="md"
                          withBorder
                          my={4}
                        >
                          <Text size={14}>
                            Research Pdf:{" "}
                            <Anchor href={item.researchPath} target="_blank">
                              {item.researchPdf}
                            </Anchor>
                          </Text>
                          <Text size={14}>
                            Human Pdf:{" "}
                            <Anchor href={item.humanPath} target="_blank">
                              {item.humanPdf}
                            </Anchor>
                          </Text>
                          <Text size={14}>
                            Technical Pdf:{" "}
                            <Anchor href={item.technicalPath} target="_blank">
                              {item.technicalPdf}
                            </Anchor>
                          </Text>
                          <Text size={14}>
                            Material Pdf:{" "}
                            <Anchor href={item.materialPath} target="_blank">
                              {item.materialPdf}
                            </Anchor>
                          </Text>
                          <Text size={14}>
                            Analysis Pdf:{" "}
                            <Anchor href={item.analysisPdf} target="_blank">
                              {item.analysisPdf}
                            </Anchor>
                          </Text>
                          <Text size={14}>
                            Gras Pdf:{" "}
                            <Anchor href={item.grasPath} target="_blank">
                              {item.grasPdf}
                            </Anchor>
                          </Text>
                        </Paper>
                      ))}
                    </Tabs.Panel>

                    <Tabs.Panel value="approvals">
                      <Grid>
                        {regulatory.map((item, index) => (
                          <Grid.Col md={4} lg={4}>
                            <Paper
                              key={index}
                              style={{ backgroundColor: "lightpink" }}
                              radius="md"
                              p="md"
                              withBorder
                              my={4}
                            >
                              <Text size={12}>
                                Regulatory Body: {item.regulator.label}
                              </Text>
                              <Text size={12}>
                                Country: {item.regulator.region.label}
                              </Text>
                              <Text size={12}>Status: {item.status}</Text>
                              <Text size={12}>
                                License Number: {item.licenseNumber}
                              </Text>
                            </Paper>
                          </Grid.Col>
                        ))}
                      </Grid>
                    </Tabs.Panel>

                    <Tabs.Panel value="certificates">
                      <Grid>
                        {certificateArray.map((item, index) => (
                          <Grid.Col md={3} lg={3} key={index}>
                            <Card withBorder>
                              <Group position="center">
                                <Text size={12} py={10}>
                                  {item.type}
                                </Text>
                              </Group>
                              <Group position="center">
                                <Image
                                  alt="Image"
                                  src={
                                    IMAGE_URL_CONSTANTS.CERTIFICATE_IMAGE +
                                    item.certificate.image
                                  }
                                  width="100%"
                                  height="100%"
                                />
                              </Group>
                              <Group my={10} position="center">
                                <Button
                                  size="xs"
                                  variant="outline"
                                  radius="sm"
                                  color="pink"
                                  leftIcon={<IconEye />}
                                >
                                  View
                                </Button>
                              </Group>
                            </Card>
                          </Grid.Col>
                        ))}
                      </Grid>
                    </Tabs.Panel>

                    <Tabs.Panel value="reviews">
                      {reviewArray.map((item, index) => (
                        <Paper
                          key={index}
                          style={{ backgroundColor: "#eeeeee" }}
                          radius="md"
                          p="md"
                          withBorder
                          my={5}
                        >
                          <Text color="black" size={12}>
                            {item.username}
                          </Text>
                          <Rating value={item.ratings} fractions={2} readOnly />
                          <Text c="dimmed" size={12}>
                            {item.reviewDescription}
                          </Text>
                        </Paper>
                      ))}
                    </Tabs.Panel>
                  </Tabs>
                </Card>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Divider size={1} />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Card>
                  <Text size={12}>Key Benefits: {renderHTML(keyBenefits)}</Text>
                </Card>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Divider size={1} />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                {isLoaded ? (
                  <Paper radius="md" withBorder p={5}>
                    <Card>
                      <Text fw={700} align="center">
                        Product Journey
                      </Text>
                    </Card>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={3}
                    >
                      {/* <Marker
                      clickable={true}
                      title="username"
                      zIndex={1000}
                      position={center}
                    /> */}
                    </GoogleMap>
                  </Paper>
                ) : null}
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Divider size={1} />
              </Grid.Col>
            </Grid>

            <Card radius="sm" withBorder my={10}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Card>
                    <Button
                      color="grape"
                      leftIcon={<IconPercentage />}
                      fullWidth
                      variant="filled"
                      onClick={() =>
                        setVariables({
                          ...variables,
                          couponDrawer: true,
                        })
                      }
                    >
                      Collect Coupon
                    </Button>
                  </Card>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Card>
                    <Button
                      color="pink"
                      leftIcon={<IconStar />}
                      fullWidth
                      variant="filled"
                      onClick={() =>
                        setVariables({
                          ...variables,
                          reviewDrawer: true,
                        })
                      }
                    >
                      Review Product
                    </Button>
                  </Card>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Card>
                    <Button
                      color="pink"
                      fullWidth
                      leftIcon={<IconExclamationMark />}
                      variant="filled"
                      onClick={() =>
                        setVariables({
                          ...variables,
                          issueDrawer: true,
                        })
                      }
                    >
                      Register Issue
                    </Button>
                  </Card>
                </Grid.Col>
              </Grid>
            </Card>
          </Container>
          {/* Banner Section */}
        </Skeleton>

        {/* Coupon Modal */}
        <Modal
          opened={variables.couponDrawer}
          onClose={() => setVariables({ ...variables, couponDrawer: false })}
          title={"Coupon"}
          overlayProps={{
            color: "darkgray",
          }}
        >
          <div className="zvcr-scroll">
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={couponForm.onSubmit((values) =>
                  handleSubmitCouponRequest(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    Enter the below details to collect coupon
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Name"
                      withAsterisk
                      value={couponForm.values.username}
                      placeholder="Name"
                      {...couponForm.getInputProps("username")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Phone Number"
                      withAsterisk
                      value={couponForm.values.phoneNumber}
                      placeholder="Phone Number"
                      {...couponForm.getInputProps("phoneNumber")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        variant="outline"
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="pink"
                        loading={variables.submitLoading}
                      >
                        Submit
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          </div>
        </Modal>
        {/* Coupon Modal */}

        {/* Review Modal */}
        <Modal
          opened={variables.reviewDrawer}
          onClose={() => setVariables({ ...variables, reviewDrawer: false })}
          title={"Review"}
          overlayProps={{
            color: "darkgray",
          }}
        >
          <div className="zvcr-scroll">
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={reviewForm.onSubmit((values) =>
                  handleSubmitReviewDetails(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    Enter the product review
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Name"
                      withAsterisk
                      value={reviewForm.values.username}
                      placeholder="Name"
                      {...reviewForm.getInputProps("username")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <NumberInput
                      label="Phone Number"
                      withAsterisk
                      maxLength={10}
                      value={reviewForm.values.phoneNumber}
                      placeholder="Phone Number"
                      {...reviewForm.getInputProps("phoneNumber")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group>
                      <Text>Star Ratings:</Text>
                      <Rating
                        defaultValue={2}
                        value={reviewForm.values.rating}
                        {...reviewForm.getInputProps("rating")}
                      />
                    </Group>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Textarea
                      label="Description"
                      placeholder="Please enter the review"
                      minRows={3}
                      value={reviewForm.values.description}
                      {...reviewForm.getInputProps("description")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        variant="outline"
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="pink"
                        loading={variables.submitLoading}
                      >
                        Submit
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          </div>
        </Modal>
        {/* Review Modal */}

        {/* Issue Modal */}
        <Modal
          opened={variables.issueDrawer}
          onClose={() => setVariables({ ...variables, issueDrawer: false })}
          title={"Review"}
          overlayProps={{
            color: "darkgray",
          }}
        >
          <div className="zvcr-scroll">
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={issueForm.onSubmit((values) =>
                  handleSubmitIssueDetails(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    Enter the product issue
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <TextInput
                      label="Name"
                      withAsterisk
                      value={issueForm.values.username}
                      placeholder="Name"
                      {...issueForm.getInputProps("username")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <NumberInput
                      label="Phone Number"
                      withAsterisk
                      maxLength={10}
                      value={issueForm.values.phoneNumber}
                      placeholder="Phone Number"
                      {...issueForm.getInputProps("phoneNumber")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Textarea
                      label="What issue did you face?"
                      placeholder="Please enter the issue you are facing"
                      minRows={3}
                      value={issueForm.values.issue}
                      {...issueForm.getInputProps("issue")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Textarea
                      label="Description"
                      placeholder="Please enter the description about the issue"
                      minRows={3}
                      value={issueForm.values.description}
                      {...issueForm.getInputProps("description")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button
                        variant="outline"
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="pink"
                        loading={variables.submitLoading}
                      >
                        Submit
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          </div>
        </Modal>
        {/* Issue Modal */}
      </div>
      <FooterSocial />
    </>
  );
}
