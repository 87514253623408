/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

// const BASE_URL = `http://127.0.0.1:3001/`;
const BASE_URL = `https://sybobkac846515.activira.ca/`;
const API_URL = BASE_URL + "api";

export const URL_CONSTANTS = {
  AMAZON_SPECIFICATION_URL: `${API_URL}/amazonSpecification`,
  AMAZON_SPECIFICATION_SEARCH_URL: `${API_URL}/amazonSpecificationSearch`,
  AMAZON_SPECIFICATION_DUPLICATE_URL: `${API_URL}/amazonSpecificationDuplicate`,
  AMAZON_SPECIFICATION_EXPORT_URL: `${API_URL}/amazonSpecificationExport`,
  AUTH_LOGIN_URL: `${API_URL}/auth/signIn`,
  AUTH_REGISTRATION_URL: `${API_URL}/auth/signUp`,
  BRAND_URL: `${API_URL}/brand`,
  CALORIES_URL: `${API_URL}/calories`,
  CATEGORY_URL: `${API_URL}/category`,
  CATEGORY_TYPE_URL: `${API_URL}/categoryType`,
  CERTIFICATE_URL: `${API_URL}/certificate`,
  CERTIFICATE_IMAGE_UPLOAD_URL: `${API_URL}/certificateUpload`,
  COUPONS_URL: `${API_URL}/coupons`,
  DASHBOARD_URL: `${API_URL}/dashboard`,
  DELIVERY_FORMATS_URL: `${API_URL}/deliveryFormat`,
  DISTRIBUTOR_URL: `${API_URL}/distributor`,
  DISTRIBUTOR_IMAGE_UPLOAD_URL: `${API_URL}/distributorUpload`,
  DOSAGE_FORM_URL: `${API_URL}/dosageForm`,
  DOSAGE_DETAILS_URL: `${API_URL}/dosageDetails`,
  DOSAGE_DETAILS_SEARCH_URL: `${API_URL}/dosageDetailsSearch`,
  DOSAGE_DETAILS_DUPLICATE_URL: `${API_URL}/dosageDetailsDuplicate`,
  FLAVOUR_URL: `${API_URL}/flavour`,
  MARKETER_URL: `${API_URL}/marketer`,
  MARKETER_IMAGE_UPLOAD_URL: `${API_URL}/marketerUpload`,
  MANUFACTURER_URL: `${API_URL}/manufacturer`,
  MANUFACTURER_DOCUMENTS_URL: `${API_URL}/manufacturerDoc`,
  MANUFACTURER_IMAGE_UPLOAD_URL: `${API_URL}/manufacturerUpload`,
  MEDICINAL_INGREDIENTS_URL: `${API_URL}/medicinalIngredients`,
  MEDICINAL_INGREDIENTS_SEARCH_URL: `${API_URL}/medicinalIngredientsSearch`,
  MEDICINAL_INGREDIENTS_DUPLICATE_URL: `${API_URL}/medicinalIngredientsDuplicate`,
  MEDICINAL_INGREDIENTS_DELETE_URL: `${API_URL}/medicinalIngredientsDelete`,
  NON_MEDICINAL_INGREDIENTS_URL: `${API_URL}/nonMedicinalIngredients`,
  NON_MEDICINAL_INGREDIENTS_SEARCH_URL: `${API_URL}/nonMedicinalIngredientsSearch`,
  NON_MEDICINAL_INGREDIENTS_DUPLICATE_URL: `${API_URL}/nonMedicinalIngredientsDuplicate`,
  NON_MEDICINAL_INGREDIENTS_DELETE_URL: `${API_URL}/nonMedicinalIngredientsDelete`,
  PERMISSION_URL: `${API_URL}/permission`,
  PRODUCT_URL: `${API_URL}/product`,
  PRODUCT_FEATURED_IMAGE_UPLOAD_URL: `${API_URL}/productFeaturedUpload`,
  PRODUCT_BATCH_URL: `${API_URL}/productBatch`,
  PRODUCT_CERTIFICATE_URL: `${API_URL}/productCertificate`,
  PRODUCT_CERTIFICATE_SEARCH_URL: `${API_URL}/productCertificateSearch`,
  PRODUCT_CERTIFICATE_DUPLICATE_URL: `${API_URL}/productCertificateDuplicate`,
  PRODUCT_CERTIFICATE_DELETE_URL: `${API_URL}/productCertificateDelete`,
  PRODUCT_IMAGE_URL: `${API_URL}/productImage`,
  PRODUCT_IMAGE_UPLOAD_URL: `${API_URL}/productImageUpload`,
  PRODUCT_IMAGE_SEARCH_URL: `${API_URL}/productImageSearch`,
  PRODUCT_IMAGE_DUPLICATE_URL: `${API_URL}/productImageDuplicate`,
  PRODUCT_IMAGE_DELETE_URL: `${API_URL}/productImageDelete`,
  PRODUCT_ISSUES_URL: `${API_URL}/productIssues`,
  PRODUCT_PAGES_URL: `${API_URL}/productPages`,
  PRODUCT_PAGES_SEARCH_URL: `${API_URL}/productPagesSearch`,
  PRODUCT_PAGES_DUPLICATE_URL: `${API_URL}/productPagesDuplicate`,
  PRODUCT_REVIEWS_URL: `${API_URL}/productReviews`,
  PRODUCT_TRACKING_DETAILS_URL: `${API_URL}/productTrackingDetails`,
  PRODUCT_TYPE_URL: `${API_URL}/productType`,
  REGION_URL: `${API_URL}/region`,
  REGION_IMAGE_UPLOAD_URL: `${API_URL}/regionUpload`,
  REGULATORY_URL: `${API_URL}/regulatory`,
  REGULATORY_BODY_URL: `${API_URL}/regulatoryBody`,
  REGULATORY_BODY_PATH_URL: `${API_URL}/regulatoryBodyPath`,
  REGULATORY_BODY_DELETE_URL: `${API_URL}/regulatoryBodyDelete`,
  REGULATORY_BODY_SEARCH_URL: `${API_URL}/regulatoryBodySearch`,
  REGULATORY_BODY_DUPLICATE_URL: `${API_URL}/regulatoryBodyDuplicate`,
  RESEARCH_DOCUMENTS_URL: `${API_URL}/researchDocuments`,
  RESEARCH_DOCUMENTS_SEARCH_URL: `${API_URL}/researchDocumentsSearch`,
  RESEARCH_DOCUMENTS_DUPLICATE_URL: `${API_URL}/researchDocumentsDuplicate`,
  RESEARCH_DOCUMENTS_DELETE_URL: `${API_URL}/researchDocumentsDelete`,
  RETAILER_URL: `${API_URL}/retailer`,
  RETAILER_IMAGE_UPLOAD_URL: `${API_URL}/retailerUpload`,
  ROLE_URL: `${API_URL}/role`,
  ROLE_PERMISSION_URL: `${API_URL}/rolePermission`,
  SUPPLEMENT_FACTS_URL: `${API_URL}/supplementFacts`,
  SUPPLEMENT_FACTS_SEARCH_URL: `${API_URL}/supplementFactsSearch`,
  SUPPLEMENT_FACTS_DUPLICATE_URL: `${API_URL}/supplementFactsDuplicate`,
  USER_ONE_URL: `${API_URL}/userOne`,
  USER_URL: `${API_URL}/user`,
  USER_PASSWORD_URL: `${API_URL}/userPassword`,
  USER_UPLOAD_URL: `${API_URL}/userUpload`,
  VARIANT_URL: `${API_URL}/variant`,
  WALMART_SPECIFICATION_URL: `${API_URL}/walmartSpecification`,
  WALMART_SPECIFICATION_SEARCH_URL: `${API_URL}/walmartSpecificationSearch`,
  WALMART_SPECIFICATION_DUPLICATE_URL: `${API_URL}/walmartSpecificationDuplicate`,
  WALMART_SPECIFICATION_EXPORT_URL: `${API_URL}/walmartSpecificationExport`,
  // For website purpose starts
  PRODUCT_ALL_URL: `${API_URL}/productAll`,
  PRODUCT_ALL_SKU_URL: `${API_URL}/productAllSku`,
  PRODUCT_ALL_BATCH_URL: `${API_URL}/productAllBatch`,
  CONTACT_URL: `${API_URL}/contact`,
  // For website purpose ends
};

export const IMAGE_URL_CONSTANTS = {
  PRODUCT_IMAGE: `${BASE_URL}public/images/products/`,
  USER_IMAGE: `${BASE_URL}public/images/users/`,
  CERTIFICATE_IMAGE: `${BASE_URL}public/images/certificates/`,
  DISTRIBUTOR_IMAGE: `${BASE_URL}public/images/distributors/`,
  MANUFACTURER_IMAGE: `${BASE_URL}public/images/manufacturer/`,
  RETAILER_IMAGE: `${BASE_URL}public/images/retailer/`,
  REGION_IMAGE: `${BASE_URL}public/images/region/`,
};

export const walmartHeaders = [
  { label: "SKU", key: "sku" },
  { label: "Product ID Type", key: "product_walmarts[0].productIdType" },
  { label: "Product ID", key: "product_walmarts[0].productIdNumber" },
  { label: "Product Name", key: "label" },
  { label: "Brand", key: "brand.label" },
  { label: "Selling Price", key: "label" },
  { label: "Shipping Weight(lbs)", key: "product_walmarts[0].shippingWeight" },
  { label: "Site Description", key: "productDescription" },
  { label: "Main Image URL", key: "featuredImage" },
  { label: "Additional Image URL-1", key: "product_images.image" },
  { label: "Additional Image URL-2", key: "product_images.image" },
  { label: "Additional Image URL-3", key: "product_images.image" },
  { label: "Additional Image URL-4", key: "product_images.image" },
  { label: "California Prop Warning Text", key: "hsn_code" },
  { label: "Label Image Contain", key: "featuredImage" },
  { label: "Label Image URL", key: "featuredImagePath" },
  { label: "Ingredient Statement", key: "hsn_code" },
  { label: "Key Feature-1", key: "keyBenefits" },
  { label: "Key Feature-2", key: "keyBenefits" },
  { label: "Manufacturer Name", key: "keyBenefits" },
  { label: "MSRP", key: "msrp" },
  { label: "Count Per Pack", key: "hsn_code" },
  { label: "Minimum Days", key: "hsn_code" },
  { label: "Flavour", key: "flavour.label" },
  { label: "Age Group", key: "product_walmarts[0].ageGroup" },
  { label: "Health Concern", key: "product_walmarts[0].healthConcerns" },
  {
    label: "Is Controlled Substance",
    key: "product_walmarts[0].IsControlledSubstance",
  },
  {
    label: "Drug Facts Dosage / Instructions Image",
    key: "product_walmarts[0].drugFactDosage",
  },
  {
    label: "Drug Facts Active / Inactive Ingredients Image",
    key: "product_walmarts[0].drugFactActive",
  },
  {
    label: "Supplement Dosage / Instructions Image",
    key: "product_walmarts[0].supplementDosage",
  },
  {
    label: "Supplement Active / Inactive Ingredients Image",
    key: "product_walmarts[0].supplementActive",
  },
  {
    label: "Active Ingredient Name",
    key: "product_walmarts[0].activeIngredientName",
  },
  {
    label: "Inactive Ingredients (+)",
    key: "product_walmarts[0].inActiveIngredient",
  },
  { label: "Form", key: "product_walmarts[0].formValue" },
  { label: "Instructions", key: "product_walmarts[0].instruction" },
  { label: "Dosage", key: "product_walmarts[0].dosageInstruction" },
  {
    label: "Stop Use Indications (+)",
    key: "product_walmarts[0].stopUseIndication",
  },
  { label: "Medicine Strength", key: "product_walmarts[0].medicineStrength" },
  { label: "National Drug Code", key: "product_walmarts[0].nationalDrugCode" },
  {
    label: "Supplements (nutrients) (+) Name",
    key: "product_walmarts[0].supplementName",
  },
  {
    label: "Supplements (nutrients) (+) Amount",
    key: "product_walmarts[0].supplementAmount",
  },
  {
    label: "Supplements (nutrients) (+) Percentage Daily Value",
    key: "product_walmarts[0].supplementPercentageDailyValue",
  },
  { label: "Serving Size", key: "product_walmarts[0].servingSize" },
  { label: "Sugar per Serving", key: "product_walmarts[0].sugarPerServing" },
  {
    label: "Nutrient Content Claims (+)",
    key: "product_walmarts[0].nutrientContentClaims",
  },
  {
    label: "Total Fat Per Serving (totalFat) Measure",
    key: "product_walmarts[0].totalFatPerServing",
  },
  {
    label: "Total Fat Per Serving (totalFat) Unit",
    key: "product_walmarts[0].totalFatPerServingUnit",
  },
  {
    label: "Total Carbohydrate (totalCarbohydrate) Measure",
    key: "product_walmarts[0].totalCarbohydrate",
  },
  {
    label: "Total Carbohydrate (totalCarbohydrate) Unit",
    key: "product_walmarts[0].totalCarbohydrateUnit",
  },
  {
    label: "Total Protein Per Serving (totalProtein) Measure",
    key: "product_walmarts[0].totalProtein",
  },
  {
    label: "Total Protein Per Serving (totalProtein) Unit",
    key: "product_walmarts[0].totalProteinUnit",
  },
  {
    label: "Calories Per Serving (calories) Measure",
    key: "product_walmarts[0].caloriesPerServing",
  },
  {
    label: "Calories Per Serving (calories) Unit",
    key: "product_walmarts[0].caloriesPerServingUnit",
  },
  {
    label: "Primary Ingredient (+)",
    key: "product_walmarts[0].primaryIngredient",
  },
  { label: "Symptoms", key: "product_walmarts[0].symptoms" },
  { label: "Dietary Method (+)", key: "product_walmarts[0].dietaryMethod" },
  {
    label: "Servings Per Container",
    key: "product_walmarts[0].servingPerContainer",
  },
  { label: "Body Part (+)", key: "product_walmarts[0].bodyPart" },
  { label: "Color (+)", key: "product_walmarts[0].color" },
  { label: "Size", key: "product_walmarts[0].size" },
  { label: "Gender", key: "product_walmarts[0].gender" },
  {
    label: "Additional Product Attribute Name",
    key: "product_walmarts[0].additionalProductAttributeName",
  },
  {
    label: "Additional Product Attribute Value",
    key: "product_walmarts[0].additionalProductAttributeValue",
  },
  { label: "Variant Group ID", key: "product_walmarts[0].variantGroupID" },
  {
    label: "Variant Attribute Names (+)",
    key: "product_walmarts[0].variantAttributeNames",
  },
  { label: "Is Primary Variant", key: "product_walmarts[0].IsPrimaryVariant" },
  {
    label: "Swatch Variant Attribute",
    key: "product_walmarts[0].swatchVariantAttribute",
  },
  { label: "Swatch Image URL", key: "product_walmarts[0].swatchImageURL" },
  {
    label: "Restriction Type",
    key: "product_walmarts[0].product_walmart2s[0].restrictionType",
  },
  { label: "States", key: "product_walmarts[0].product_walmart2s[0].states" },
  {
    label: "ZIP Codes",
    key: "product_walmarts[0].product_walmart2s[0].zipCodes",
  },
  {
    label: "Fulfillment Lag Time",
    key: "product_walmarts[0].product_walmart2s[0].fulfillmentLagTime",
  },
  {
    label: "PPU Quantity of Units",
    key: "product_walmarts[0].product_walmart2s[0].ppuQuantityOfUnits",
  },
  {
    label: "PPU Unit of Measure",
    key: "product_walmarts[0].product_walmart2s[0].ppuUnitOfMeasure",
  },
  {
    label: "Contains Electronic Component?",
    key: "product_walmarts[0].product_walmart2s[0].containsElectronicComponent",
  },
  {
    label: "Contained Battery Type",
    key: "product_walmarts[0].product_walmart2s[0].containedBatteryType",
  },
  {
    label: "Contains Chemical, Aerosol or Pesticide?",
    key: "product_walmarts[0].product_walmart2s[0].containsChemicalAerosolOrPesticide",
  },
  {
    label: "Multipack Quantity",
    key: "product_walmarts[0].product_walmart2s[0].multiPackQuantity",
  },
  {
    label: "Ships in Original Packaging",
    key: "product_walmarts[0].product_walmart2s[0].shipsInOriginalPackaging",
  },
  {
    label: "Must ship alone?",
    key: "product_walmarts[0].product_walmart2s[0].mustShipAlone",
  },
  {
    label: "Local Delivery Depth (in)",
    key: "product_walmarts[0].product_walmart2s[0].localDeliveryDepthIn",
  },
  {
    label: "Local Delivery Width (in)",
    key: "product_walmarts[0].product_walmart2s[0].localDeliveryWidthIn",
  },
  {
    label: "Local Delivery Height (in)",
    key: "product_walmarts[0].product_walmart2s[0].localDeliveryHeightIn",
  },
  {
    label: "Local Delivery Weight (lbs)",
    key: "product_walmarts[0].product_walmart2s[0].localDeliveryWeight",
  },
  {
    label: "Site Start Date",
    key: "product_walmarts[0].product_walmart2s[0].siteStartDate",
  },
  {
    label: "Site End Date",
    key: "product_walmarts[0].product_walmart2s[0].siteEndDate",
  },
  {
    label: "Additional Offer Attribute Name",
    key: "product_walmarts[0].product_walmart2s[0].additionalOfferAttributeName",
  },
  {
    label: "Additional Offer Attribute Value",
    key: "product_walmarts[0].product_walmart2s[0].additionalOfferAttributeValue",
  },
  {
    label: "External Product ID Type",
    key: "product_walmarts[0].product_walmart2s[0].externalProductIDType",
  },
  {
    label: "External Product ID",
    key: "product_walmarts[0].product_walmart2s[0].externalProductID",
  },
  {
    label: "Product Id Update",
    key: "product_walmarts[0].product_walmart2s[0].productIdUpdate",
  },
  {
    label: "SKU Update",
    key: "product_walmarts[0].product_walmart2s[0].skuUpdate",
  },
];

export const amazonHeaders = [
  { label: "Product Type", key: "sku" },
  { label: "Seller SKU", key: "product_walmarts[0].productIdType" },
  { label: "Brand Name", key: "brand.label" },
  { label: "Item Name (aka Title)", key: "label" },
  { label: "Manufacturer", key: "manufacturer.label" },
  { label: "Product ID", key: "product_amazons[0].productId" },
  { label: "Product ID Type)", key: "product_amazons[0].productIDType" },
  { label: "Manufacturer Part Number", key: "hsn_code" },
  { label: "Product Description", key: "productDescription" },
  { label: "Recommended Browse Nodes", key: "keyBenefits" },
  { label: "Bullet Point", key: "keyBenefits" },
  { label: "Bullet Point", key: "keyBenefits" },
  { label: "Bullet Point", key: "keyBenefits" },
  { label: "Bullet Point", key: "keyBenefits" },
  { label: "Bullet Point", key: "keyBenefits" },
  { label: "Color", key: "product_amazons[0].colorMap" },
  { label: "Color Map", key: "product_amazons[0].colorMap" },
  { label: "Item Form", key: "product_amazons[0].itemForm" },
  { label: "PPU Count", key: "product_amazons[0].ppuCountUnitOfMeasure" },
  {
    label: "PPU Count Unit of Measure",
    key: "product_amazons[0].ppuCountUnitOfMeasure",
  },
  {
    label: "Country/Region Of Origin",
    key: "product_amazons[0].countryOfOrigin",
  },
  { label: "HSN Code", key: "hsn_code" },
  {
    label: "Contains Liquid Contents?",
    key: "product_amazons[0].containsLiquidContent",
  },
  {
    label: "Is the Item Heat Sensitive?",
    key: "product_amazons[0].isTheItemHeatSensitive",
  },
  { label: "Your price", key: "msrp" },
  { label: "Quantity", key: "hsn_code" },
  { label: "Number of Items", key: "hsn_code" },
  { label: "List Price", key: "msrp" },
  { label: "Maximum Retail Price", key: "hsn_code" },
  { label: "Main Image URL", key: "product_images[0].path" },
  { label: "Target Gender", key: "product_amazons[0].targetGender" },
  { label: "Other Image URL1", key: "product_images[0].path" },
  { label: "Other Image URL2", key: "product_images[0].path" },
  { label: "Other Image URL3", key: "product_images[0].path" },
  { label: "Other Image URL4", key: "product_images[0].path" },
  { label: "Other Image URL5", key: "product_images[0].path" },
  { label: "Other Image URL6", key: "product_images[0].path" },
  { label: "Other Image URL7", key: "product_images[0].path" },
  { label: "Other Image URL8", key: "product_images[0].path" },
  { label: "Swatch Image URL", key: "product_images[0].path" },
  { label: "Parentage", key: "product_amazons[0].percentage" },
  { label: "Parent SKU", key: "hsn_code" },
  { label: "Relationship Type", key: "product_amazons[0].relationalShipType" },
  { label: "Variation Theme", key: "product_amazons[0].variationTheme" },
  { label: "Update Delete", key: "product_amazons[0].updateDelete" },
  { label: "Model number", key: "hsn_code" },
  { label: "Model Name", key: "hsn_code" },
  { label: "Product Exemption Reason", key: "hsn_code" },
  { label: "Search Terms", key: "hsn_code" },
  { label: "Size", key: "hsn_code" },
  { label: "Scent", key: "hsn_code" },
  { label: "Directions", key: "hsn_code" },
  { label: "Special Ingredients", key: "hsn_code" },
  { label: "Manufacturer contact information", key: "manufacturer.address" },
  { label: "Flavor", key: "product_amazons[0].flavour" },
  { label: "Serving Quantity", key: "supplement_facts.servingSize" },
  {
    label: "Serving Quantity Unit",
    key: "product_amazons[0].servingQuantityUnit",
  },
  { label: "Energy Content String", key: "hsn_code" },
  { label: "Total Fat String", key: "product_walmarts[0].totalFatPerServing" },
  { label: "Saturated Fat String", key: "hsn_code" },
  {
    label: "Total Carbohydrate String",
    key: "product_walmarts[0].totalCarbohydrate",
  },
  { label: "Sugars String", key: "hsn_code" },
  { label: "style_name", key: "hsn_code" },
  { label: "Soluble Fiber String", key: "hsn_code" },
  {
    label: "Carbohydrate Unit",
    key: "product_walmarts[0].totalCarbohydrateUnit",
  },
  { label: "Other Carbohydrate", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Protein", key: "hsn_code" },
  { label: "Added Sugars", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Melting Temperature Unit", key: "hsn_code" },
  { label: "Diet Type", key: "hsn_code" },
  { label: "Diet Type", key: "hsn_code" },
  { label: "Diet Type", key: "hsn_code" },
  { label: "Total Carbohydrate", key: "hsn_code" },
  { label: "Starch", key: "hsn_code" },
  { label: "Product Benefits", key: "keyBenefits" },
  { label: "Specification Met", key: "hsn_code" },
  { label: "Energy Content From Saturated Fat", key: "hsn_code" },
  { label: "Packer", key: "hsn_code" },
  { label: "Item Type Name", key: "hsn_code" },
  { label: "Melting Temperature (degrees Celsius)", key: "hsn_code" },
  { label: "Material Type FREE", key: "hsn_code" },
  { label: "Material Type FREE", key: "hsn_code" },
  { label: "Material Type FREE", key: "hsn_code" },
  { label: "Material Type FREE", key: "hsn_code" },
  { label: "Material Type FREE", key: "hsn_code" },
  { label: "Importer", key: "hsn_code" },
  { label: "Protein Unit", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Serving Recommendation", key: "hsn_code" },
  { label: "Material Composition", key: "hsn_code" },
  { label: "Energy Content From Trans Fat", key: "hsn_code" },
  { label: "Insoluble Fiber String", key: "hsn_code" },
  { label: "Included Components", key: "hsn_code" },
  { label: "Included Components", key: "hsn_code" },
  { label: "Included Components", key: "hsn_code" },
  { label: "Included Components", key: "hsn_code" },
  { label: "Included Components", key: "hsn_code" },
  { label: "Monounsaturated Fat String", key: "hsn_code" },
  { label: "Polyunsaturated Fat String", key: "hsn_code" },
  { label: "Sugar Alcohols String", key: "hsn_code" },
  { label: "Starch String", key: "hsn_code" },
  { label: "Dietary Fiber String", key: "hsn_code" },
  { label: "Material Features", key: "hsn_code" },
  { label: "Calcium Per Serving Unit Of Measure", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Soluble Fiber", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Energy Content from Fat", key: "hsn_code" },
  { label: "Monounsaturated Fat", key: "hsn_code" },
  { label: "Maximum Seller Allowed Price", key: "hsn_code" },
  { label: "flash_point_unit_of_measure", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "FC Shelf Life Unit", key: "hsn_code" },
  { label: "Primary Supplement Type", key: "hsn_code" },
  { label: "Primary Supplement Type", key: "hsn_code" },
  { label: "Primary Supplement Type", key: "hsn_code" },
  { label: "Primary Supplement Type", key: "hsn_code" },
  { label: "Primary Supplement Type", key: "hsn_code" },
  { label: "Dietary Fiber", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Skip Offer", key: "hsn_code" },
  { label: "Is the liquid product double sealed?", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Minimum Seller Allowed Price", key: "hsn_code" },
  { label: "Fat Unit", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Polyunsaturated Fat", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Vitamin and Mineral Unit", key: "hsn_code" },
  { label: "Nutrient", key: "hsn_code" },
  { label: "Trans Fat", key: "hsn_code" },
  { label: "Container Type", key: "hsn_code" },
  { label: "Insoluble Fiber", key: "hsn_code" },
  {
    label: "Is exempt from supplier declared external product identifier",
    key: "hsn_code",
  },
  { label: "Energy Unit", key: "hsn_code" },
  { label: "Saturated Fat", key: "hsn_code" },
  { label: "Minimum Advertised Price Display", key: "hsn_code" },
  { label: "Dosage Form", key: "hsn_code" },
  { label: "Energy Content", key: "hsn_code" },
  { label: "Sugar Alcohols", key: "hsn_code" },
  { label: "_3d_technology", key: "hsn_code" },
  { label: "Item Length Unit Of Measure", key: "hsn_code" },
  { label: "Item Width Unit Of Measure", key: "hsn_code" },
  { label: "Item Height Unit Of Measure", key: "hsn_code" },
  { label: "Item Height", key: "hsn_code" },
  { label: "Item Width", key: "hsn_code" },
  { label: "Item Length", key: "hsn_code" },
  { label: "Liquid Volume", key: "hsn_code" },
  { label: "Liquid Volume Unit", key: "hsn_code" },
  { label: "Load Capacity", key: "hsn_code" },
  { label: "Load Capacity Unit Of Measure", key: "hsn_code" },
  {
    label: "Batteries are Included",
    key: "product_amazons[0].batteriesAreIncluded",
  },
  {
    label: "Is this product a battery or does it use batteries?",
    key: "product_amazons[0].isThisProductABatteryOrDoesItUseBatteries",
  },
  { label: "Number of batteries", key: "hsn_code" },
  { label: "Number of batteries", key: "hsn_code" },
  { label: "Number of batteries", key: "hsn_code" },
  {
    label: "Battery composition",
    key: "product_amazons[0].batteryComposition",
  },
  { label: "Watt hours per battery", key: "hsn_code" },
  {
    label: "Lithium Battery Energy Content Unit Of Measure",
    key: "product_amazons[0].lithiumBatteryEnergyContentUnitOfMeasure",
  },
  {
    label: "Lithium Battery Packaging",
    key: "product_amazons[0].lithiumBatteryPackaging",
  },
  { label: "Lithium content", key: "hsn_code" },
  {
    label: "Lithium Battery Weight Unit Of Measure",
    key: "product_amazons[0].lithiumBatteryWeightUnitOfMeasure",
  },
  { label: "Number of Lithium-ion Cells", key: "hsn_code" },
  { label: "Number of Lithium Metal Cells", key: "hsn_code" },
  { label: "Usage", key: "product_amazons[0].usage" },
  { label: "Ingredients", key: "hsn_code" },
  { label: "Allergen Information", key: "hsn_code" },
  { label: "Allergen Information", key: "hsn_code" },
  { label: "Allergen Information", key: "hsn_code" },
  { label: "Allergen Information", key: "hsn_code" },
  { label: "Allergen Information", key: "hsn_code" },
  { label: "Safety Warning", key: "hsn_code" },
  { label: "Battery type/size", key: "hsn_code" },
  { label: "Battery type/size", key: "hsn_code" },
  { label: "Battery type/size", key: "hsn_code" },
  { label: "UN number", key: "hsn_code" },
  { label: "Warranty Description", key: "hsn_code" },
  { label: "Liquid Contents Description", key: "hsn_code" },
  { label: "Item Condition", key: "hsn_code" },
  { label: "Condition Note", key: "hsn_code" },
  { label: "Sale Price", key: "hsn_code" },
  { label: "Restock Date", key: "hsn_code" },
  { label: "Sale Start Date", key: "hsn_code" },
  { label: "Sale End Date", key: "hsn_code" },
  { label: "Offer Start Date", key: "hsn_code" },
  { label: "Offer End Date", key: "hsn_code" },
  { label: "Max Order Quantity", key: "hsn_code" },
  { label: "Handling Time", key: "hsn_code" },
  { label: "Release Date", key: "hsn_code" },
  {
    label: "Is Gift Wrap Available?",
    key: "product_amazons[0].isGiftWrapAvailable",
  },
  { label: "Shipping-Template", key: "product_amazons[0].shippingTemplate" },
  {
    label: "Product Expiration Type",
    key: "product_amazons[0].productExpirationType",
  },
  {
    label: "Fulfillment Center Shelf Life",
    key: "product_amazons[0].fcShelfLifeUnit",
  },
  {
    label: "Can Be Gift Messaged",
    key: "product_amazons[0].canBeGiftMessaged",
  },
  {
    label: "Is Product Expirable",
    key: "product_amazons[0].isProductExpiable",
  },
  { label: "Lot Offering Expiration Date", key: "hsn_code" },
  { label: "Minimum Advertised Price", key: "hsn_code" },
  { label: "Business Price", key: "hsn_code" },
  { label: "Quantity Price Type", key: "hsn_code" },
  { label: "Quantity Price 1", key: "hsn_code" },
  { label: "Quantity Lower Bound 1", key: "hsn_code" },
  { label: "Quantity Price 2", key: "hsn_code" },
  { label: "Quantity Lower Bound 2", key: "hsn_code" },
  { label: "Quantity Price 3", key: "hsn_code" },
  { label: "Quantity Lower Bound 3", key: "hsn_code" },
  { label: "Quantity Price 4", key: "hsn_code" },
  { label: "Quantity Lower Bound 4", key: "hsn_code" },
  { label: "Quantity Price 5", key: "hsn_code" },
  { label: "Quantity Lower Bound 5", key: "hsn_code" },
  { label: "Pricing Action", key: "hsn_code" },
  {
    label: "United Nations Standard Products and Services Code",
    key: "hsn_code",
  },
  { label: "National Stock Number", key: "hsn_code" },
];
