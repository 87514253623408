/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  CopyButton,
  FileButton,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  Paper,
  Radio,
  ScrollArea,
  Skeleton,
  Table,
  Tabs,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleGetRegion,
  handleUploadProductImage,
  handleSearchProductImage,
  handleUpdateProductImage,
  handleGetOneProductImage,
  handleDuplicateProductImage,
  handleDeleteProductImage,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { IconTrash, IconTrashX } from "@tabler/icons-react";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
import { useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { dataSlice, imageModal } from "../../../../utils/common/common";

function ProductImages() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [data, setData] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [imageArrayEdit, setImageArrayEdit] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [defaultValue, setDefaultValue] = useState("1");
  const [available, setAvailable] = useState(true);
  const [updatedAt, setUpdatedAt] = useState();

  const formImage = useForm({
    initialValues: {
      productId: params.id,
      regionId: "",
      image: "",
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: defaultValue,
      regionIdTo: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneProductImage(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
            });
            // Assign documents here
            if (response.data.data.length > 0) {
              setCount(response.data.data.length);
              setUpdatedAt(response.data.data[0].updatedAt);
              setVariables({
                ...variables,
                data: response.data.data.reverse(),
                skeletonLoading: false,
              });
              const datas = dataSlice({
                data: response.data.data,
                page: 1,
                total: 10,
              });
              setData(datas);
              formImage.setFieldValue("regionId", 1);
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Crop Image
  const ref = useRef();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };

  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image !== "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + row.image,
                  title: row.label,
                });
              }}
              src={IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + row.image}
              radius={30}
              size={50}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + row.image,
                  title: row.label,
                });
              }}
              color="#A61E4D"
              radius={30}
              size={50}
            />
          )}
        </Group>
      </td>
      <td>
        <CopyButton value={row.path}>
          {({ copied, copy }) => (
            <Button color={copied ? "teal" : "pink"} onClick={copy}>
              {copied ? "Copied url" : "Copy url"}
            </Button>
          )}
        </CopyButton>
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Uploading product image with values
  const handleUploadNewImage = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      productId: params.id,
      regionId: "",
    };
    e = { ...e, ...req };
    const response = await handleUploadProductImage(e);
    // Check for response from upload actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product image upload successfully",
      });
      setImage("");
      setCount(1);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleUploadMoreImages = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      productId: params.id,
      regionId: defaultValue,
    };
    e = { ...e, ...req };
    const response = await handleUpdateProductImage(e);
    // Check for response from upload actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product image upload successfully",
      });
      setImage("");
      setCount(1);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search supplements facts by regionId & productId function
  const handleFetchProductImagesRegion = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    const response = await handleSearchProductImage(req);
    if (response.status === 200) {
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle duplicate supplements facts function
  const handleConfirmDuplicateProductImages = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateProductImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product images duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">Are you sure you want to delete the image ?</Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    setVariables({ ...variables, submitLoading: true, skeletonLoading: true });
    const req = {
      regionId: defaultValue,
      productId: params.id,
      rowId: e,
    };
    const response = await handleDeleteProductImage(req);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product image upload successfully",
      });
      setImage("");
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() => handleFetchProductImagesRegion(item.value)}
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel value={defaultValue} pt="xs">
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formImage.onSubmit((values) =>
                      handleUploadMoreImages(values)
                    )}
                  >
                    <Grid style={{ padding: "10px" }}>
                      {/* Content Here */}
                      <Grid.Col span={12}>
                        <>
                          {/* For cropper */}
                          <div
                            style={{
                              marginTop: 15,
                              position: "relative",
                              marginBottom: 30,
                            }}
                          >
                            <Paper
                              p="lg"
                              sx={(theme) => ({
                                backgroundColor:
                                  theme.colorScheme === "dark"
                                    ? theme.colors.dark[8]
                                    : theme.white,
                              })}
                            >
                              {!image ? (
                                <Avatar
                                  src={
                                    IMAGE_URL_CONSTANTS.PRODUCT_IMAGE +
                                    productImage
                                  }
                                  size={200}
                                  mx="auto"
                                />
                              ) : (
                                <Avatar src={image} size={200} mx="auto" />
                              )}

                              <Text ta="center" fz="lg" weight={500} mt="md">
                                Upload Product Image
                              </Text>

                              <Group position="center">
                                <FileButton
                                  resetRef={ref}
                                  onChange={changeHandler}
                                  accept={[
                                    "image/png",
                                    "image/jpeg",
                                    "image/sgv+xml",
                                    "image/gif",
                                  ]}
                                >
                                  {(props) => (
                                    <Button
                                      variant="outline"
                                      mt={20}
                                      {...props}
                                    >
                                      Select Image
                                    </Button>
                                  )}
                                </FileButton>
                              </Group>
                            </Paper>
                          </div>
                        </>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="center">
                          <Button
                            loading={variables.submitLoading}
                            type="submit"
                            size="sm"
                            variant="filled"
                          >
                            Upload Product Image
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                  <Grid mt={10}>
                    <Grid.Col md={12} lg={12}>
                      <ScrollArea scrollbarSize={10}>
                        <>
                          <Card withBorder>
                            <Table
                              striped
                              highlightOnHover
                              verticalSpacing="sm"
                            >
                              {/* Table header defines */}
                              <thead>
                                <tr>
                                  <th>Sl.No</th>
                                  <th>Image</th>
                                  <th>Copy Path</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              {rows.length > 0 ? (
                                <tbody>{rows}</tbody>
                              ) : (
                                <>
                                  {variables.submitLoading === true ? (
                                    <tbody>
                                      <tr>
                                        <td colSpan={4}>
                                          <div style={{ textAlign: "center" }}>
                                            <Loader
                                              size="md"
                                              color="#A61E4D"
                                              variant="dots"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td colSpan={4}>
                                          <div style={{ textAlign: "center" }}>
                                            <Text weight={500} align="center">
                                              No data available!
                                            </Text>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              )}
                            </Table>
                          </Card>
                        </>
                      </ScrollArea>
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="left">
                        <Badge color="#FCC2D7">
                          Last updated on:&nbsp;
                          {new Date(updatedAt).toLocaleString("en-UK")}
                        </Badge>
                      </Group>
                      <Group position="right">
                        <Button
                          loading={variables.skeletonLoading}
                          disabled={variables.skeletonLoading}
                          radius="sm"
                          size="sm"
                          color="#A61E4D"
                          onClick={() =>
                            setVariables({
                              ...variables,
                              confirmDrawer: true,
                            })
                          }
                        >
                          Duplicate
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={formImage.onSubmit((values) =>
                  handleUploadNewImage(values)
                )}
              >
                <Grid>
                  <Grid.Col span={12}>
                    <>
                      {/* For cropper */}
                      <div
                        style={{
                          marginTop: 15,
                          position: "relative",
                          marginBottom: 30,
                        }}
                      >
                        <Paper
                          p="lg"
                          sx={(theme) => ({
                            backgroundColor:
                              theme.colorScheme === "dark"
                                ? theme.colors.dark[8]
                                : theme.white,
                          })}
                        >
                          {!image ? (
                            <Avatar
                              src={
                                IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + productImage
                              }
                              size={200}
                              mx="auto"
                            />
                          ) : (
                            <Avatar src={image} size={200} mx="auto" />
                          )}

                          <Text ta="center" fz="lg" weight={500} mt="md">
                            {productImage}
                          </Text>

                          <Group position="center">
                            <FileButton
                              resetRef={ref}
                              onChange={changeHandler}
                              accept={[
                                "image/png",
                                "image/jpeg",
                                "image/sgv+xml",
                                "image/gif",
                              ]}
                            >
                              {(props) => (
                                <Button variant="outline" mt={20} {...props}>
                                  Select Image
                                </Button>
                              )}
                            </FileButton>
                          </Group>
                        </Paper>
                      </div>
                    </>
                    <div>
                      <Grid>
                        {imageArray.map((row, index) => (
                          <Grid.Col key={index} span={2}>
                            <Paper shadow="xs" p={2} withBorder>
                              <Image
                                width={"100%"}
                                src={row}
                                alt="Random unsplash image"
                              />
                              <ActionIcon
                                onClick={() => {
                                  var filter = imageArray.filter(
                                    (img) => img !== row
                                  );
                                  setImageArray(filter);
                                }}
                              >
                                <IconTrashX color="red" size={15} />
                              </ActionIcon>
                            </Paper>
                          </Grid.Col>
                        ))}
                      </Grid>
                    </div>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12} mt={1}>
                    <Group position="center">
                      <Button
                        loading={variables.submitLoading}
                        type="submit"
                        size="sm"
                        variant="filled"
                      >
                        Upload Product Image
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
              <Grid mt={10}>
                <Grid.Col md={12} lg={12}>
                  <ScrollArea scrollbarSize={10}>
                    <>
                      <Card withBorder>
                        <Table striped highlightOnHover verticalSpacing="sm">
                          {/* Table header defines */}
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Image</th>
                              <th>Copy Path</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {rows.length > 0 ? (
                            <tbody>{rows}</tbody>
                          ) : (
                            <>
                              {variables.submitLoading === true ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={4}>
                                      <div style={{ textAlign: "center" }}>
                                        <Loader
                                          size="md"
                                          color="#A61E4D"
                                          variant="dots"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td colSpan={4}>
                                      <div style={{ textAlign: "center" }}>
                                        <Text weight={500} align="center">
                                          No data available!
                                        </Text>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </>
                          )}
                        </Table>
                      </Card>
                    </>
                  </ScrollArea>
                </Grid.Col>
              </Grid>
            </div>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Product Images"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateProductImages(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductImages;
