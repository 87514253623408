/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Modal,
  Radio,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";
import {
  handleGetRegion,
  handleGetCategoryType,
  handleAddNonMedicinalIngredients,
  handleEditNonMedicinalIngredients,
  handleSearchNonMedicinalIngredients,
  handleDuplicateNonMedicinalIngredients,
  handleGetOneNonMedicinalIngredients,
  handleDeleteNonMedicinalIngredients,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { IconTrash } from "@tabler/icons-react";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
import { modals } from "@mantine/modals";

function NonMedicinalIngredients() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [category, setCategory] = useState([]);
  const [available, setAvailable] = useState(true);
  const [defaultValue, setDefaultValue] = useState("1");
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const [updatedAt, setUpdatedAt] = useState();

  // For form validation
  const form = useForm({
    initialValues: {
      nonMedicinalIngredients: [
        {
          productId: params.id,
          regionId: "",
          nmiCategoryId: "1",
          label: "",
          quantity: "",
          standardGrade: "",
          msds: "",
          coa: "",
        },
      ],
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      editNonMedicinalIngredients: [
        {
          value: "",
          productId: params.id,
          regionId: "",
          nmiCategoryId: "1",
          label: "",
          quantity: "",
          standardGrade: "",
          msds: "",
          coa: "",
        },
      ],
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: defaultValue,
      regionIdTo: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneNonMedicinalIngredients(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
            });
            if (response.data.data.length > 0) {
              setCount(response.data.data.length);
              setUpdatedAt(response.data.data[0].updatedAt);
              // Assign values here
              const datas = response.data.data.map((data) => ({
                ...data,
                nmiCategoryId: data.nmiCategoryId.toString(),
              }));
              formEdit.setFieldValue("editNonMedicinalIngredients", datas);
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }

        const response2 = await handleGetCategoryType();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setCategory(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const fields = form.values.nonMedicinalIngredients.map((item, index) => (
    <tr key={index}>
      <td>
        <Text>{index + 1}</Text>
      </td>
      <td>
        <Select
          placeholder="Select Category"
          searchable
          data={category}
          value={form.values.nmiCategoryId}
          {...form.getInputProps(
            `nonMedicinalIngredients.${index}.nmiCategoryId`
          )}
        />
      </td>
      <td>
        <TextInput
          placeholder="Title"
          value={form.values.label}
          {...form.getInputProps(`nonMedicinalIngredients.${index}.label`)}
        />
      </td>
      <td>
        <TextInput
          placeholder="Quantity"
          value={form.values.quantity}
          {...form.getInputProps(`nonMedicinalIngredients.${index}.quantity`)}
        />
      </td>
      <td>
        <TextInput
          placeholder="Standard Grade"
          value={form.values.standardGrade}
          {...form.getInputProps(
            `nonMedicinalIngredients.${index}.standardGrade`
          )}
        />
      </td>
      <td>
        <TextInput
          placeholder="MSDS"
          value={form.values.msds}
          {...form.getInputProps(`nonMedicinalIngredients.${index}.msds`)}
        />
      </td>
      <td>
        <TextInput
          placeholder="COA"
          value={form.values.coa}
          {...form.getInputProps(`nonMedicinalIngredients.${index}.coa`)}
        />
      </td>
      <td>
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem("nonMedicinalIngredients", index)}
        >
          <IconTrash size="1rem" />
        </ActionIcon>
      </td>
    </tr>
  ));

  const fieldEdits = formEdit.values.editNonMedicinalIngredients.map(
    (item, index) => (
      <tr key={index}>
        <td>
          <Text>{index + 1}</Text>
        </td>
        <td>
          <Select
            placeholder="Select Category"
            searchable
            value={
              formEdit.values.editNonMedicinalIngredients[index].nmiCategoryId
            }
            data={category}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.nmiCategoryId`
            )}
          />
        </td>
        <td>
          <TextInput
            placeholder="Title"
            value={formEdit.values.editNonMedicinalIngredients[index].label}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.label`
            )}
          />
        </td>
        <td>
          <TextInput
            placeholder="Quantity"
            value={formEdit.values.quantity}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.quantity`
            )}
          />
        </td>
        <td>
          <TextInput
            placeholder="Standard Grade"
            value={formEdit.values.standardGrade}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.standardGrade`
            )}
          />
        </td>
        <td>
          <TextInput
            placeholder="MSDS"
            value={formEdit.values.msds}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.msds`
            )}
          />
        </td>
        <td>
          <TextInput
            placeholder="COA"
            value={formEdit.values.coa}
            {...formEdit.getInputProps(
              `editNonMedicinalIngredients.${index}.coa`
            )}
          />
        </td>
        <td>
          <ActionIcon
            color="red"
            onClick={() =>
              openConfirmModalDelete(
                formEdit.values.editNonMedicinalIngredients[index].id
              )
            }
            // onClick={() =>
            //   formEdit.removeListItem("editNonMedicinalIngredients", index)
            // }
          >
            <IconTrash size="1rem" />
          </ActionIcon>
        </td>
      </tr>
    )
  );

  // Handle add medicinal ingredients function
  const handleCreateNonMedicinalIngredients = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddNonMedicinalIngredients(e);
    // Check for response for actions
    if (response.status === 200) {
      setVariables({ ...variables, submitLoading: true });
      setCount(response.data.data.length);
      setDefaultValue("1");
      const datas = response.data.data.map((data) => ({
        ...data,
        nmiCategoryId: data.nmiCategoryId.toString(),
      }));
      formEdit.setFieldValue("editNonMedicinalIngredients", datas);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Non Medicinal ingredient created successfully",
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle edit medicinal ingredients function
  const handleUpdateMedicinalIngredients = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditNonMedicinalIngredients(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Non Medicinal ingredient updated successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search medicinal ingredients by regionId & productId function
  const handleFetchRegionMedicinalIngredients = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    const response = await handleSearchNonMedicinalIngredients(req);
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        setCount(response.data.data.length);
        setAvailable(true);
        // Assign values here
        const datas = response.data.data.map((data) => ({
          ...data,
          nmiCategoryId: data.nmiCategoryId.toString(),
        }));
        formEdit.setFieldValue("editNonMedicinalIngredients", datas);
      } else {
        setAvailable(false);
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle create duplicate medicinal ingredients function
  const handleConfirmDuplicateNonMedicinalIngredients = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateNonMedicinalIngredients(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Non Medicinal ingredient duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const openConfirmModalDelete = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the non medicinal ingredient ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    setVariables({ ...variables, submitLoading: true, skeletonLoading: true });
    const req = {
      regionId: defaultValue,
      productId: params.id,
      rowId: e,
    };
    const response = await handleDeleteNonMedicinalIngredients(req);
    // Check the response for notification and actions
    if (response.status === 200) {
      if (
        response.data.data !== "" &&
        response.data.data !== null &&
        typeof response.data.data !== "undefined"
      ) {
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
        if (response.data.data.length > 0) {
          setCount(response.data.data.length);
          // Assign values here
          const datas = response.data.data.map((data) => ({
            ...data,
            nmiCategoryId: data.nmiCategoryId.toString(),
          }));
          formEdit.setFieldValue("editNonMedicinalIngredients", datas);
        }
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Non medicinal ingredient deleted successfully",
        });
      } else {
        setVariables({
          ...variables,
          submitLoading: false,
          skeletonLoading: false,
        });
      }
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() =>
                      handleFetchRegionMedicinalIngredients(item.value)
                    }
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel value={defaultValue} pt="xs">
                <Group position="right" mt="md">
                  <Button
                    size="sm"
                    radius="sm"
                    onClick={() =>
                      formEdit.insertListItem("editNonMedicinalIngredients", {
                        id: "",
                        productId: params.id,
                        regionId: defaultValue,
                        nmiCategoryId: "1",
                        label: "",
                        quantity: "",
                        standardGrade: "",
                        msds: "",
                        coa: "",
                        addedBy: 1,
                        active: false,
                      })
                    }
                  >
                    Add More
                  </Button>
                </Group>
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formEdit.onSubmit((values) =>
                      handleUpdateMedicinalIngredients(values)
                    )}
                  >
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <ScrollArea
                          scrollbarSize={10}
                          style={{
                            height: "50vh",
                          }}
                        >
                          {fieldEdits.length > 0 ? (
                            <>
                              <Table
                                horizontalSpacing="md"
                                verticalSpacing="xs"
                                withBorder
                                withColumnBorders
                              >
                                <thead
                                  style={{
                                    backgroundColor: "#FFF0F6",
                                  }}
                                >
                                  <tr>
                                    <th>Sl.No</th>
                                    <th>Category</th>
                                    <th>Title</th>
                                    <th>Quantity</th>
                                    <th>Standard Grade</th>
                                    <th>MSDS</th>
                                    <th>COA</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody key={refreshTable}>{fieldEdits}</tbody>
                              </Table>
                            </>
                          ) : null}
                        </ScrollArea>
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="left">
                          <Badge color="#FCC2D7">
                            Last updated on:&nbsp;
                            {new Date(updatedAt).toLocaleString("en-UK")}
                          </Badge>
                        </Group>
                        <Group position="right">
                          <Button
                            radius="sm"
                            type="submit"
                            size="sm"
                            color="#A61E4D"
                          >
                            Update Non Medicinal Ingredients
                          </Button>
                          <Button
                            loading={variables.skeletonLoading}
                            disabled={variables.skeletonLoading}
                            radius="sm"
                            size="sm"
                            color="#A61E4D"
                            onClick={() =>
                              setVariables({
                                ...variables,
                                confirmDrawer: true,
                              })
                            }
                          >
                            Duplicate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <>
              <Group position="right" mt="md">
                <Button
                  size="sm"
                  radius="sm"
                  onClick={() =>
                    form.insertListItem("nonMedicinalIngredients", {
                      productId: params.id,
                      regionId: "",
                      nmiCategoryId: "1",
                      label: "",
                      quantity: "",
                      standardGrade: "",
                      msds: "",
                      coa: "",
                      active: false,
                    })
                  }
                >
                  Add More
                </Button>
              </Group>
              <div style={{ padding: "10px" }}>
                <form
                  onSubmit={form.onSubmit((values) =>
                    handleCreateNonMedicinalIngredients(values)
                  )}
                >
                  <Grid>
                    <Grid.Col md={12} lg={12}>
                      <ScrollArea
                        scrollbarSize={10}
                        style={{
                          height: "50vh",
                        }}
                      >
                        {fields.length > 0 ? (
                          <>
                            <Table
                              horizontalSpacing="md"
                              verticalSpacing="xs"
                              withBorder
                              withColumnBorders
                            >
                              <thead
                                style={{
                                  backgroundColor: "#FFF0F6",
                                }}
                              >
                                <tr>
                                  <th>Sl.No</th>
                                  <th>Category</th>
                                  <th>Title</th>
                                  <th>Quantity</th>
                                  <th>Standard Grade</th>
                                  <th>MSDS</th>
                                  <th>COA</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody key={refreshTable}>{fields}</tbody>
                            </Table>
                          </>
                        ) : null}
                      </ScrollArea>
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="right">
                        <Button
                          radius="sm"
                          type="submit"
                          size="sm"
                          color="#A61E4D"
                        >
                          Add Non Medicinal Ingredients
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </form>
              </div>
            </>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Non Medicinal Ingredients"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateNonMedicinalIngredients(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NonMedicinalIngredients;
