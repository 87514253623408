/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import {
  ActionIcon,
  AppShell,
  Burger,
  Header,
  MediaQuery,
  Navbar,
  ScrollArea,
  useMantineTheme,
  TextInput,
  Menu,
  Text,
  Group,
  rem,
  createStyles,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core"; //for import mantine required functions and theme
import React, { useState } from "react";
import {
  IconUserExclamation,
  IconLogout,
  IconUserCircle,
  IconPercentage,
  IconExclamationCircle,
  IconMapCheck,
  IconSettings,
  IconDashboard,
  IconStar,
  IconPill,
  IconMoonStars,
  IconSun,
  IconHome,
  IconFileExport,
} from "@tabler/icons-react";
import Logo from "../../assets/images/logo.png";
import Icon from "../../assets/images/logo.png";
import IconW from "../../assets/images/logo_dark.png";
import { LinksGroup } from "./NavLinks";
import { useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { useNavigate, Outlet } from "react-router-dom"; // for import react dom navigation components
import { SpotlightProvider, useSpotlight } from "@mantine/spotlight";
import { openConfirmModal } from "@mantine/modals";
import { IconSearch } from "@tabler/icons-react";
import { Notifications } from "@mantine/notifications";
const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 200,
    height: "auto",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.gray : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
//for   made mantine theme style change and write custome theme here

function SpotlightControl(props) {
  const spotlight = useSpotlight();
  return (
    <Group position="center">
      <TextInput
        icon={<IconSearch size={18} />}
        size="xs"
        radius="xl"
        placeholder="Search "
        sx={{ marginLeft: 60 }}
        onClick={spotlight.openSpotlight}
        rightSectionWidth={42}
        {...props}
      />
    </Group>
  );
}

const mockdata = [
  {
    icon: IconDashboard,
    label: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    icon: IconPill,
    label: "Products",
    link: "/admin/products",
  },
  {
    icon: IconFileExport,
    label: "Exports",
    link: "/admin/exports",
  },
  {
    icon: IconMapCheck,
    label: "Tracking & Tracing",
    link: "/admin/batches",
  },
  {
    icon: IconExclamationCircle,
    label: "Issues",
    link: "/admin/issues",
  },
  {
    icon: IconStar,
    label: "Reviews",
    link: "/admin/reviews",
  },
  {
    icon: IconPercentage,
    label: "Coupons",
    link: "/admin/coupons",
  },
  {
    icon: IconSettings,
    label: "Settings",
    link: "/admin/settings/brands",
  },
];

function Sidebar({ children }) {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  // For get the dark light toggle
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // Get the last mode
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // Change the color if u want to '#043c64' Carefully wile choosing dark color only
  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#A61E4D",
  });

  // For make color lighter by 10 colors level
  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };

  const dark = colorScheme === "dark";
  const { classes } = useStyles();
  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  let navigate = useNavigate();

  const actions = [
    {
      title: "Dashboard",
      onTrigger: () => navigate("/admin/Dashboard"),
    },
  ];

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          colors: {
            zevcore: [
              LightenDarkenColor(color, 140),
              LightenDarkenColor(color, 130),
              LightenDarkenColor(color, 120),
              LightenDarkenColor(color, 110),
              LightenDarkenColor(color, 100),
              LightenDarkenColor(color, 90),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 80),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 20),
            ],
          },
          primaryColor: "zevcore",
          "::-webkit-scrollbar": {
            backgroundColor: LightenDarkenColor(color, 120),
            width: "5px",
            height: "10px",
            borderRadius: 5,
            fontFamily: "Poppins",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: LightenDarkenColor(color, 120),
            borderRadius: 5,
            // "#D50000"
          },
          fontFamily: "Poppins",
          fontFamilyMonospace: "Poppins",
          headings: { fontFamily: "Poppins" },
          fontWeight: 200,
          colorScheme,
        }}
      >
        <ModalsProvider>
          <div>
            <AppShell
              styles={(theme) => ({
                main: {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[8]
                      : theme.colors.gray[0],
                },
              })}
              // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
              navbarOffsetBreakpoint="sm"
              // fixed prop on AppShell will be automatically added to Header and Navbar
              fixed
              navbar={
                <Navbar
                  p="md"
                  // Breakpoint at which navbar will be hidden if hidden prop is true
                  hiddenBreakpoint="sm"
                  // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
                  hidden={!opened}
                  // when viewport size is less than theme.breakpoints.sm navbar width is 100%
                  // viewport size > theme.breakpoints.sm – width is 300px
                  // viewport size > theme.breakpoints.lg – width is 400px
                  width={{ sm: 300, lg: 250 }}
                >
                  <Navbar.Section
                    grow
                    className={classes.links}
                    component={ScrollArea}
                    scrollbarSize={2}
                  >
                    <div className={classes.linksInner}>{links}</div>
                  </Navbar.Section>
                </Navbar>
              }
              header={
                <Header height={70} p="md">
                  {/* Handle other responsive styles with MediaQuery component or createStyles function */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                      <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                      />
                    </MediaQuery>

                    <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                      {dark ? (
                        <img
                          src={IconW}
                          alt=""
                          className="zc-pt-1 zc-mr-4"
                          style={{ height: "150px" }}
                        />
                      ) : (
                        <img
                          src={Icon}
                          alt=""
                          className="zc-pt-1 zc-mr-4"
                          style={{ height: "150px" }}
                        />
                      )}
                    </MediaQuery>

                    <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                      {dark ? (
                        <>
                          {/* For view logo */}
                          <img
                            src={Logo}
                            alt=""
                            style={{ height: "70px", marginLeft: 0 }}
                          />
                          <SpotlightProvider
                            actions={actions}
                            searchIcon={<IconSearch size={18} />}
                            searchPlaceholder="Search..."
                            shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                            nothingFoundMessage="No data available!..."
                          >
                            <SpotlightControl />
                          </SpotlightProvider>
                        </>
                      ) : (
                        <>
                          <img
                            src={Logo}
                            alt=""
                            style={{ height: "70px", marginLeft: 0 }}
                          />
                          <SpotlightProvider
                            actions={actions}
                            searchIcon={<IconSearch size={18} />}
                            searchPlaceholder="Search..."
                            shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                            nothingFoundMessage="No data available!..."
                          >
                            <SpotlightControl />
                          </SpotlightProvider>
                        </>
                      )}
                    </MediaQuery>

                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <ActionIcon
                    className="zc-mt-2 zc-mr-1 zc-ml-2"
                    color={dark ? "yellow" : "blue"}
                    onClick={() => toggleColorScheme()}
                    title="Toggle color scheme"
                  >
                    {dark ? <IconSun size={25} /> : <IconMoonStars size={25} />}
                  </ActionIcon> */}

                      <Menu shadow="md" className="no-drag zc-mr-1" width={200}>
                        <Menu.Target>
                          <ActionIcon
                            className="zc-mt-2 zc-mr-2 zc-ml-2"
                            type="button"
                          >
                            <IconUserCircle size={45} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            icon={<IconUserExclamation size={14} />}
                            onClick={() => navigate("/admin/accountSettings")}
                          >
                            Profile Settings
                          </Menu.Item>
                          <Menu.Item
                            icon={<IconHome size={14} />}
                            onClick={() => navigate("/")}
                          >
                            Go To Home
                          </Menu.Item>

                          <Menu.Item
                            // Logout Confirm modal
                            onClick={() => {
                              openConfirmModal({
                                title: "Confirm Logout ",
                                children: (
                                  <Text size="sm">
                                    This action is so important that you are
                                    required to confirm logout. Please click one
                                    of these buttons to proceed.
                                  </Text>
                                ),

                                labels: {
                                  confirm: "Confirm",
                                  cancel: "Cancel",
                                },
                                confirmProps: { color: "red" },
                                onCancel: () => console.log("Cancel"),
                                onConfirm: () => {
                                  localStorage.clear();
                                  navigate("/");
                                },
                              });
                            }}
                            color="red"
                            icon={<IconLogout size={14} />}
                          >
                            Log Out
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </div>
                  </div>
                </Header>
              }
            >
              <Outlet />
            </AppShell>
          </div>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default Sidebar;
