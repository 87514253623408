/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Select,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleGetBrand,
  handleGetCategory,
  handleGetFlavours,
  handleGetVariant,
  handleGetRegion,
  handleGetManufacturers,
  handleGetDistributors,
  handleEditProduct,
  handleGetProductOne,
  handleGetMarketers,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { useParams } from "react-router-dom";
// Text editor imports starts
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
// Text editor imports ends

function ProductInformation() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
  });
  const params = useParams();
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);
  const [flavour, setFlavour] = useState([]);
  const [variant, setVariant] = useState([]);
  const [region, setRegion] = useState([]);
  const [manufacture, setManufacture] = useState([]);
  const [marketedBy, setMarketedBy] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [updatedAt, setUpdatedAt] = useState();

  // Editor for description
  const [descriptionValue, setDescriptionValue] = useState();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Product Description" }),
    ],
    content: descriptionValue,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("productDescription", html);
      setDescriptionValue(html);
    },
  });

  // Editor for key benefits
  const [keyValue, setKeyValue] = useState();
  const editor1 = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "Key Benefits / Health Claims" }),
    ],
    content: keyValue,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      form.setFieldValue("keyBenefits", html);
      setKeyValue(html);
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetProductOne(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            skeletonLoading: false,
          });
          setUpdatedAt(response.data.data.updatedAt);
          form.setFieldValue("value", response.data.data.id);
          form.setFieldValue("label", response.data.data.label);
          form.setFieldValue("sku", response.data.data.sku);
          if (
            response.data.data.brandId !== null &&
            response.data.data.brandId !== "" &&
            typeof response.data.data.brandId !== "undefined"
          ) {
            form.setFieldValue(
              "brandId",
              response.data.data.brandId.toString()
            );
          } else {
            form.setFieldValue("brandId", "");
          }
          if (
            response.data.data.categoryId !== null &&
            response.data.data.categoryId !== "" &&
            typeof response.data.data.categoryId !== "undefined"
          ) {
            form.setFieldValue(
              "categoryId",
              response.data.data.categoryId.toString()
            );
          } else {
            form.setFieldValue("categoryId", "");
          }
          if (
            response.data.data.flavourId !== null &&
            response.data.data.flavourId !== "" &&
            typeof response.data.data.flavourId !== "undefined"
          ) {
            form.setFieldValue(
              "flavourId",
              response.data.data.flavourId.toString()
            );
          } else {
            form.setFieldValue("flavourId", "");
          }
          if (
            response.data.data.variantId !== null &&
            response.data.data.variantId !== "" &&
            typeof response.data.data.variantId !== "undefined"
          ) {
            form.setFieldValue(
              "variantId",
              response.data.data.variantId.toString()
            );
          } else {
            form.setFieldValue("variantId", "");
          }

          if (
            response.data.data.regionId !== null &&
            response.data.data.regionId !== "" &&
            typeof response.data.data.regionId !== "undefined"
          ) {
            form.setFieldValue(
              "regionId",
              response.data.data.regionId.toString()
            );
          } else {
            form.setFieldValue("regionId", "");
          }

          if (
            response.data.data.gtin !== null &&
            response.data.data.gtin !== "" &&
            typeof response.data.data.gtin !== "undefined"
          ) {
            form.setFieldValue("gtin", response.data.data.gtin.toString());
          } else {
            form.setFieldValue("gtin", "");
          }
          // Assigning routeOfAdministration to form
          if (
            response.data.data.routeOfAdministration !== null &&
            response.data.data.routeOfAdministration !== "" &&
            typeof response.data.data.routeOfAdministration !== "undefined"
          ) {
            form.setFieldValue(
              "routeOfAdministration",
              response.data.data.routeOfAdministration
            );
          } else {
            form.setFieldValue("routeOfAdministration", "");
          }
          // Assigning primary packing to form
          if (
            response.data.data.primaryPacking !== null &&
            response.data.data.primaryPacking !== "" &&
            typeof response.data.data.primaryPacking !== "undefined"
          ) {
            form.setFieldValue(
              "primaryPacking",
              response.data.data.primaryPacking
            );
          } else {
            form.setFieldValue("primaryPacking", "");
          }
          // Assigning country of origin to form
          if (
            response.data.data.countryOfOrigin !== null &&
            response.data.data.countryOfOrigin !== "" &&
            typeof response.data.data.countryOfOrigin !== "undefined"
          ) {
            form.setFieldValue(
              "countryOfOrigin",
              response.data.data.countryOfOrigin.toString()
            );
          } else {
            form.setFieldValue("countryOfOrigin", "");
          }
          // Assigning msrp to form
          if (
            response.data.data.msrp !== null &&
            response.data.data.msrp !== "" &&
            typeof response.data.data.msrp !== "undefined"
          ) {
            form.setFieldValue("msrp", response.data.data.msrp);
          } else {
            form.setFieldValue("msrp", "");
          }
          // Assigning genus to form
          if (
            response.data.data.genus !== null &&
            response.data.data.genus !== "" &&
            typeof response.data.data.genus !== "undefined"
          ) {
            form.setFieldValue("genus", response.data.data.genus);
          } else {
            form.setFieldValue("genus", "");
          }
          // Assigning animal source to form
          if (
            response.data.data.animalSource !== null &&
            response.data.data.animalSource !== "" &&
            typeof response.data.data.animalSource !== "undefined"
          ) {
            form.setFieldValue("animalSource", response.data.data.animalSource);
          } else {
            form.setFieldValue("animalSource", "");
          }
          // Assigning manufacturer ID to form
          if (
            response.data.data.manufacturerID !== null &&
            response.data.data.manufacturerID !== "" &&
            typeof response.data.data.manufacturerID !== "undefined"
          ) {
            form.setFieldValue(
              "manufacturerID",
              response.data.data.manufacturerID.toString()
            );
          } else {
            form.setFieldValue("manufacturerID", "");
          }
          // Assigning distributedBy ID to form
          if (
            response.data.data.distributedByID !== null &&
            response.data.data.distributedByID !== "" &&
            typeof response.data.data.distributedByID !== "undefined"
          ) {
            form.setFieldValue(
              "distributedByID",
              response.data.data.distributedByID.toString()
            );
          } else {
            form.setFieldValue("distributedByID", "");
          }
          // Assigning marketedBy ID to form
          if (
            response.data.data.marketedByID !== null &&
            response.data.data.marketedByID !== "" &&
            typeof response.data.data.marketedByID !== "undefined"
          ) {
            form.setFieldValue(
              "marketedByID",
              response.data.data.marketedByID.toString()
            );
          } else {
            form.setFieldValue("marketedByID", "");
          }
          // Assigning product description to form
          if (
            response.data.data.productDescription !== null &&
            response.data.data.productDescription !== "" &&
            typeof response.data.data.productDescription !== "undefined"
          ) {
            editor?.commands.setContent(response.data.data.productDescription);
            setDescriptionValue(response.data.data.productDescription);
            form.setFieldValue(
              "productDescription",
              response.data.data.productDescription
            );
          } else {
            form.setFieldValue("productDescription", "");
          }
          // Assigning keybenefits to form
          if (
            response.data.data.keyBenefits !== null &&
            response.data.data.keyBenefits !== "" &&
            typeof response.data.data.keyBenefits !== "undefined"
          ) {
            editor1?.commands.setContent(response.data.data.keyBenefits);
            setKeyValue(response.data.data.keyBenefits);
            form.setFieldValue(
              "keyBenefits",
              response.data.data.productDescription
            );
          } else {
            form.setFieldValue("keyBenefits", "");
          }
        }

        const response1 = await handleGetBrand();
        if (response1.status === 200) {
          var data = response1.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setBrand(clean);
        }

        const response2 = await handleGetCategory();
        if (response2.status === 200) {
          var data = response2.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setCategory(clean);
        }

        const response3 = await handleGetFlavours();
        if (response3.status === 200) {
          var data = response3.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setFlavour(clean);
        }

        const response4 = await handleGetVariant();
        if (response4.status === 200) {
          var data = response4.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setVariant(clean);
        }

        const response5 = await handleGetRegion();
        console.log(response5);
        if (response5.status === 200) {
          var data = response5.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setRegion(clean);
        }

        const response6 = await handleGetManufacturers();
        console.log(response6);
        if (response6.status === 200) {
          var data = response6.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setManufacture(clean);
        }

        const response7 = await handleGetDistributors();
        if (response7.status === 200) {
          var data = response7.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setDistributor(clean);
        }

        const response8 = await handleGetMarketers();

        if (response8.status === 200) {
          var data = response8.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setMarketedBy(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [editor, editor1]);

  // For form validation
  const form = useForm({
    initialValues: {
      value: "",
      label: "",
      sku: "",
      brandId: "",
      variantId: "",
      flavourId: "",
      categoryId: "",
      regionId: "",
      gtin: "",
      routeOfAdministration: "",
      primaryPacking: "",
      countryOfOrigin: "",
      manufacturerID: "",
      marketedByID: "",
      distributedByID: "",
      productDescription: "",
      keyBenefits: "",
      msrp: "",
      genus: "",
      animalSource: "",
    },
    validate: {
      label: (value) =>
        value.length < 2 ? "Brand name must have at least 2 letters" : null,
    },
  });

  // Handle add function
  const handleUpdateInformation = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProduct(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product information updated successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={form.onSubmit((values) =>
                handleUpdateInformation(values)
              )}
            >
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Product Name"
                    withAsterisk
                    value={form.values.label}
                    placeholder="Product Name"
                    {...form.getInputProps("label")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Product SKU"
                    withAsterisk
                    value={form.values.sku}
                    placeholder="Product SKU"
                    {...form.getInputProps("sku")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Brand"
                    placeholder="Select Brand"
                    searchable
                    data={brand}
                    value={form.values.brandId}
                    {...form.getInputProps("brandId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Category"
                    placeholder="Select Category"
                    searchable
                    data={category}
                    value={form.values.categoryId}
                    {...form.getInputProps("categoryId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Flavour"
                    placeholder="Select Flavour"
                    searchable
                    data={flavour}
                    value={form.values.flavourId}
                    {...form.getInputProps("flavourId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Variant"
                    placeholder="Select Variant"
                    searchable
                    data={variant}
                    value={form.values.variantId}
                    {...form.getInputProps("variantId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Regions"
                    placeholder="Select Regions"
                    searchable
                    data={region}
                    value={form.values.regionId}
                    {...form.getInputProps("regionId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Product GTIN"
                    withAsterisk
                    value={form.values.gtin}
                    placeholder="Product GTIN"
                    {...form.getInputProps("gtin")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Route Of Administration"
                    value={form.values.routeOfAdministration}
                    placeholder="Route Of Administration"
                    {...form.getInputProps("routeOfAdministration")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Primary Packing"
                    value={form.values.primaryPacking}
                    placeholder="Primary Packing"
                    {...form.getInputProps("primaryPacking")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Country Of Origin"
                    placeholder="Country Of Origin"
                    searchable
                    data={region}
                    value={form.values.countryOfOrigin}
                    {...form.getInputProps("countryOfOrigin")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="MSRP"
                    value={form.values.msrp}
                    placeholder="MSRP"
                    {...form.getInputProps("msrp")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Genus"
                    value={form.values.genus}
                    placeholder="Genus"
                    {...form.getInputProps("genus")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Animal Source"
                    value={form.values.animalSource}
                    placeholder="Animal Source"
                    {...form.getInputProps("animalSource")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Manufacturer"
                    placeholder="Select Manufacturer"
                    searchable
                    data={manufacture}
                    value={form.values.manufacturerID}
                    {...form.getInputProps("manufacturerID")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Distributor"
                    placeholder="Select Distributor"
                    searchable
                    data={distributor}
                    value={form.values.distributedByID}
                    {...form.getInputProps("distributedByID")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Marketed By"
                    placeholder="Select Marketed By"
                    searchable
                    data={marketedBy}
                    value={form.values.marketedByID}
                    {...form.getInputProps("marketedByID")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Text mb={5}>Description</Text>
                  <RichTextEditor editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={descriptionValue}
                      mb={20}
                      {...form.getInputProps("productDescription")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Text mb={5}>Key Benefits / Health Claims</Text>
                  <RichTextEditor editor={editor1}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                        <RichTextEditor.Code />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.H1 />
                        <RichTextEditor.H2 />
                        <RichTextEditor.H3 />
                        <RichTextEditor.H4 />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content
                      value={keyValue}
                      mb={20}
                      {...form.getInputProps("keyBenefits")}
                    />
                  </RichTextEditor>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="left">
                    <Badge color="#FCC2D7">
                      Last updated on:&nbsp;
                      {new Date(updatedAt).toLocaleString("en-UK")}
                    </Badge>
                  </Group>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Update Information
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </Card>
      </Skeleton>
    </>
  );
}

export default ProductInformation;
