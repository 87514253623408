/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
// for import the dependencies data
import React from "react";
import { Paper, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const BreadCrumbs = (props) => {
  let navigate = useNavigate();
  // navigation import for navigate to page
  const handleClick = (e) => {
    navigate(e);
  };

  return (
    <Paper
      radius="xs"
      py="xs"
      style={{
        display: "flex",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        marginBottom: 0,
        borderBottomWidth: 0.7,
        borderBottomStyle: "solid",
        borderBottomColor: "lightgrey",
      }}
    >
      {/* Props text name Main page name */}
      <Text color="#A61E4D" weight={600}>
        {props.Text} {props.Name ? <span>( {props.Name} )</span> : null}
      </Text>
      {/* Show right side bread crumb with link */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text color="#A61E4D" size={12} weight={600}>
          Dashboard
        </Text>
        {/* For title page */}
        {props.Title ? (
          <>
            <Text color="#A61E4D" size={12} weight={600}>
              &#62;
            </Text>
            <Text color="#A61E4D" size={12} weight={600}>
              {props.Title}
            </Text>
          </>
        ) : null}
        {/* For text page */}
        {props.Text ? (
          <>
            <Text color="#A61E4D" size={12} weight={600}>
              &nbsp; &#62; &nbsp;
            </Text>
            <Text color="#A61E4D" size={12} weight={600}>
              {props.Text}
            </Text>
          </>
        ) : null}
      </div>
    </Paper>
  );
};

export default BreadCrumbs;
