/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  FileButton,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  NativeSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleAddManufacturers,
  handleDeleteManufacturers,
  handleEditManufacturers,
  handleGetManufacturers,
  handleGetRegion,
  handleUploadManufacturerImage,
} from "../../../../utils/helpers/apiFunctions";
import {
  dataSearch,
  dataSlice,
  imageModal,
} from "../../../../utils/common/common";
import {
  IconFileExport,
  IconPencil,
  IconPlus,
  IconSearch,
  IconTrash,
  IconPhoto,
} from "@tabler/icons-react";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import { CSVLink } from "react-csv";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";

function Manufacturer() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [region, setRegion] = useState([]);
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // For form validation
  const form = useForm({
    initialValues: {
      label: "",
      phoneNumber: "",
      email: "",
      licenseNumber: "",
      address: "",
      regionId: "",
      countryName: "",
      iso: "",
      dialCode: "",
      latitude: "",
      longitude: "",
    },
    validate: {
      label: (value) =>
        value.length < 2
          ? "Manufacturer name must have at least 2 letters"
          : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phoneNumber: (value) =>
        value.length < 10 ? "Phone number must be at least 10 digit" : null,
    },
  });

  // For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      label: "",
      phoneNumber: "",
      email: "",
      licenseNumber: "",
      address: "",
      regionId: "",
      countryName: "",
      iso: "",
      dialCode: "",
      latitude: "",
      longitude: "",
    },
    validate: {
      label: (value) =>
        value.length < 2
          ? "Manufacturer name must have at least 2 letters"
          : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phoneNumber: (value) =>
        value.length < 10 ? "Phone number must be at least 10 digit" : null,
    },
  });

  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetManufacturers();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            submitLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setLoadingData(false);
          setData(datas);
        }
        const response1 = await handleGetRegion();

        if (response1.status === 200) {
          var data = response1.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={row.id}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image !== "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: IMAGE_URL_CONSTANTS.MANUFACTURER_IMAGE + row.image,
                  title: row.label,
                });
              }}
              src={IMAGE_URL_CONSTANTS.MANUFACTURER_IMAGE + row.image}
              radius={30}
              size={50}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: IMAGE_URL_CONSTANTS.MANUFACTURER_IMAGE + row.image,
                  title: row.label,
                });
              }}
              color="#A61E4D"
              radius={30}
              size={50}
            />
          )}
          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm">
                {row.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              Email: {row.email}
            </Text>
            <Text c="dimmed" fz="xs">
              Phone Number: {row.phoneNumber}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.region.label}</td>
      <td>{row.iso}</td>
      <td>--</td>
      <td>--</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.id)}>
            <IconPhoto size={18} color="gray" />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.id)}>
            <IconPencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add function
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddManufacturers(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Manufacturer added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle open edit modal
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    formEdit.setFieldValue("value", datas.id);
    formEdit.setFieldValue("regionId", datas.region.id.toString());
    formEdit.setFieldValue("label", datas.label);
    formEdit.setFieldValue("email", datas.email);
    formEdit.setFieldValue("phoneNumber", datas.phoneNumber);
    formEdit.setFieldValue("licenseNumber", datas.licenseNumber);
    formEdit.setFieldValue("iso", datas.iso);
    formEdit.setFieldValue("countryName", datas.countryName);
    formEdit.setFieldValue("dialCode", datas.dialCode);
    formEdit.setFieldValue("latitude", datas.latitude);
    formEdit.setFieldValue("longitude", datas.longitude);
    formEdit.setFieldValue("address", datas.address);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  const [manufacturer, setManufacturer] = useState("Manufacturer");
  const [manufacturerImage, setManufacturerImage] = useState(null);
  const [value, setValue] = useState();
  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setManufacturer(datas.label);
    setManufacturerImage(datas.image);
    setValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // Uploading certificate image with values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: value,
    };
    e = { ...e, ...req };
    const response = await handleUploadManufacturerImage(e);
    // Check for response from upload actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Manufacture image upload successfully",
      });
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, imageDrawer: false });
    }
  };

  // Crop Image
  const ref = useRef();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };

  // Handle update data function
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditManufacturers(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Manufacturer updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the Manufacturer <b>{datas.label}</b>{" "}
          ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteManufacturers(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Manufacturer deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card shadow="xs" padding="sm" radius="md">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <TextInput
              variant="filled"
              placeholder="Search by any field"
              mb="md"
              size="xs"
              value={search}
              icon={<IconSearch size={14} />}
              onChange={async (e) => {
                // On change search ofr the data that is enter
                setSearch(e.currentTarget.value);
                setPage(1);
                const datas = await dataSearch({
                  data: variables.data,
                  value: e.currentTarget.value,
                  activePage: activePage,
                  total: total,
                });
                setData(datas);
                setRefreshTable(new Date());
              }}
              sx={{ width: 250 }}
            />
            <Group spacing="xs">
              {/* Drawer open for adding new tax data */}
              <Button
                variant="outline"
                color="zevcore"
                size="xs"
                mr={10}
                onClick={() =>
                  setVariables({
                    ...variables,
                    addDrawer: true,
                  })
                }
                leftIcon={<IconPlus size={12} />}
              >
                Add Manufacturer
              </Button>
              <CSVLink
                data={variables.data}
                headers={[
                  { label: "name", key: "label" },
                  { label: "email", key: "email" },
                  { label: "phone", key: "phoneNumber" },
                  { label: "license number", key: "licenseNumber" },
                  { label: "address", key: "address" },
                  { label: "region", key: "region.label" },
                  { label: "country name", key: "countryName" },
                  { label: "iso", key: "iso" },
                  { label: "dial code", key: "dialCode" },
                  { label: "latitude", key: "latitude" },
                  { label: "longitude", key: "longitude" },
                ]}
                filename="manufacturer.csv"
              >
                <Button
                  variant="outline"
                  color="zevcore"
                  size="xs"
                  leftIcon={<IconFileExport size={12} />}
                >
                  Export
                </Button>
              </CSVLink>
            </Group>
          </div>
          <ScrollArea>
            <Table striped highlightOnHover verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Manufacturer</th>
                  <th>Region</th>
                  <th>ISO</th>
                  <th>license Number</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {variables.submitLoading === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#A61E4D" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              No data available!
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Rows per page
              </Text>
              <NativeSelect
                ml={5}
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {variables.data.length > 0 ? (
              <>
                <Badge color="#FCC2D7" ml={5} mt={3}>
                  Last updated on:&nbsp;
                  {new Date(
                    variables.data.reduce(function (r, a) {
                      return r.updatedAt > a.updatedAt ? r : a;
                    }).updatedAt
                  ).toLocaleString("en-UK")}
                </Badge>
              </>
            ) : null}
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
        </Card>
      </Skeleton>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        size="55%"
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Manufacturer"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Region"
                    placeholder="Select Region"
                    data={region}
                    searchable
                    required
                    clearable
                    {...form.getInputProps("regionId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Manufacturer Name"
                    withAsterisk
                    value={form.values.label}
                    placeholder="Manufacturer Name"
                    {...form.getInputProps("label")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Email"
                    withAsterisk
                    value={form.values.email}
                    placeholder="Email"
                    {...form.getInputProps("email")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Phone Number"
                    withAsterisk
                    maxLength={10}
                    value={form.values.phoneNumber}
                    placeholder="Phone Number"
                    {...form.getInputProps("phoneNumber")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="license Number"
                    withAsterisk
                    value={form.values.licenseNumber}
                    placeholder="license Number"
                    {...form.getInputProps("licenseNumber")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="ISO"
                    withAsterisk
                    value={form.values.iso}
                    placeholder="ISO"
                    {...form.getInputProps("iso")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Country Name"
                    withAsterisk
                    value={form.values.countryName}
                    placeholder="Country Name"
                    {...form.getInputProps("countryName")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Dial Code"
                    withAsterisk
                    value={form.values.dialCode}
                    placeholder="Dial Code"
                    {...form.getInputProps("dialCode")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Latitude"
                    withAsterisk
                    value={form.values.latitude}
                    placeholder="Latitude"
                    {...form.getInputProps("latitude")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Longitude"
                    withAsterisk
                    value={form.values.longitude}
                    placeholder="Longitude"
                    {...form.getInputProps("longitude")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Textarea
                    placeholder="Address"
                    label="Address"
                    withAsterisk
                    value={form.values.address}
                    {...form.getInputProps("address")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="#A61E4D"
                  >
                    Add Manufacturer
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        size="55%"
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Manufacturer Details"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="Select Region"
                  placeholder="Select Region"
                  data={region}
                  searchable
                  required
                  clearable
                  {...formEdit.getInputProps("regionId")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Manufacturer Name"
                  withAsterisk
                  value={formEdit.values.label}
                  placeholder="Manufacturer Name"
                  {...formEdit.getInputProps("label")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Email"
                  withAsterisk
                  value={formEdit.values.email}
                  placeholder="Email"
                  {...formEdit.getInputProps("email")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Phone Number"
                  withAsterisk
                  maxLength={10}
                  value={formEdit.values.phoneNumber}
                  placeholder="Phone Number"
                  {...formEdit.getInputProps("phoneNumber")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="license Number"
                  withAsterisk
                  value={formEdit.values.licenseNumber}
                  placeholder="license Number"
                  {...formEdit.getInputProps("licenseNumber")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="ISO"
                  withAsterisk
                  value={formEdit.values.iso}
                  placeholder="ISO"
                  {...formEdit.getInputProps("iso")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Country Name"
                  withAsterisk
                  value={formEdit.values.countryName}
                  placeholder="Country Name"
                  {...formEdit.getInputProps("countryName")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Dial Code"
                  withAsterisk
                  value={formEdit.values.dialCode}
                  placeholder="Dial Code"
                  {...formEdit.getInputProps("dialCode")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Latitude"
                  withAsterisk
                  value={formEdit.values.latitude}
                  placeholder="Latitude"
                  {...formEdit.getInputProps("latitude")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Longitude"
                  withAsterisk
                  value={formEdit.values.longitude}
                  placeholder="Longitude"
                  {...formEdit.getInputProps("longitude")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Textarea
                  placeholder="Address"
                  label="Address"
                  withAsterisk
                  value={formEdit.values.address}
                  {...formEdit.getInputProps("address")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="#A61E4D"
                >
                  Update Manufacturer
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={manufacturer}
        overlayProps={{
          color: "grey",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image croper */}
              {/* For croper */}

              <div
                style={{
                  marginTop: 15,
                  position: "relative",
                  marginBottom: 30,
                }}
              >
                <Paper
                  p="lg"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
                  })}
                >
                  {!image ? (
                    <Avatar
                      src={
                        IMAGE_URL_CONSTANTS.MANUFACTURER_IMAGE +
                        manufacturerImage
                      }
                      size={150}
                      radius={100}
                      mx="auto"
                    />
                  ) : (
                    <Avatar src={image} size={150} radius={150} mx="auto" />
                  )}

                  <Text ta="center" fz="lg" weight={500} mt="md">
                    {manufacturer}
                  </Text>

                  <Group position="center">
                    <FileButton
                      resetRef={ref}
                      onChange={changeHandler}
                      accept={[
                        "image/png",
                        "image/jpeg",
                        "image/sgv+xml",
                        "image/gif",
                      ]}
                    >
                      {(props) => (
                        <Button variant="outline" mt={20} {...props}>
                          Select Image
                        </Button>
                      )}
                    </FileButton>
                  </Group>
                </Paper>
              </div>
            </Grid.Col>
            <Button
              loading={variables.submitLoading}
              fullWidth
              type="submit"
              size="md"
              variant="filled"
            >
              Upload Manufacturer Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </>
  );
}

export default Manufacturer;
