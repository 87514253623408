/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React from "react";
import { Grid, Skeleton } from "@mantine/core";
import BreadCrumbs from "../breadcrumbs/Breadcrumbs";
import SettingLinks from "./settingsLinks";
import { Outlet } from "react-router-dom";

function SettingsComponent() {
  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Settings" />
      </Skeleton>
      <Skeleton radius="md" mt={10} visible={false}>
        <Grid>
          <Grid.Col md={2} lg={2}>
            <SettingLinks />
          </Grid.Col>
          <Grid.Col md={10} lg={10}>
            <Outlet />
          </Grid.Col>
        </Grid>
      </Skeleton>
    </>
  );
}

export default SettingsComponent;
