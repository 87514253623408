/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoutes({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const location = useLocation();

  if (isAuthenticated === null) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  } else {
    if (isAuthenticated === "true") {
      return children;
    } else {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  }
}

export default ProtectedRoutes;
