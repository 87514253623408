/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React, { useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Button,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const HEADER_HEIGHT = rem(80);

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(6)} ${rem(8)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#333333",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("md")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

function HeaderResponsive() {
  const links = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "About",
      link: "/about",
    },
    {
      label: "Products",
      link: "/region",
    },
    {
      label: "Contact",
      link: "/contact",
    },
  ];
  let navigate = useNavigate();
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(0);
  const { classes, cx } = useStyles();
  const [localState, setLocalState] = useState(false);
  const items = links.map((link) => (
    <Text
      className={cx(classes.link, {
        [classes.linkActive]: (link.label === active) === 1,
      })}
      href={link.link}
      onClick={() => handlePage(link.link)}
      key={link.label}
    >
      {link.label}
    </Text>
  ));

  const handlePage = (e) => {
    if (typeof e != "undefined") {
      navigate(e);
      setActive(e);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userIsAuthenticated = localStorage.getItem("isAuthenticated");
      if (userIsAuthenticated) {
        setLocalState(true);
      }
    } else {
      setLocalState(false);
    }
  }, []);

  return (
    <>
      <Header height={HEADER_HEIGHT} className={classes.root}>
        <Container style={{ maxWidth: "80rem" }} className={classes.header}>
          <Group spacing={5} className={classes.links}>
            <img src={logo} alt="Logo" width={200} />
          </Group>
          <Group spacing={5} className={classes.links}>
            {items}
            {localState === true ? (
              <Button
                onClick={() => navigate("/admin/dashboard")}
                style={{ backgroundColor: "#A61E4D" }}
              >
                Go to Dashboard
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/auth/login")}
                style={{ backgroundColor: "#A61E4D" }}
              >
                Login
              </Button>
            )}
          </Group>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />

          <Transition
            transition="pop-top-right"
            duration={200}
            mounted={opened}
          >
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items}
              </Paper>
            )}
          </Transition>
        </Container>
      </Header>
    </>
  );
}

export default HeaderResponsive;
