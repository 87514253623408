/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  FileInput,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  Radio,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Tabs,
  Text,
  Title,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  handleGetRegion,
  handleAddProductCertificate,
  handleEditProductCertificate,
  handleSearchProductCertificate,
  handleDuplicateProductCertificate,
  handleGetCertificates,
  handleGetOneProductCertificate,
  handleDeleteProductCertificate,
} from "../../../../utils/helpers/apiFunctions";
import notificationHelper from "../../../../utils/common/notificationHelper";
import { IconTrash, IconPhoto } from "@tabler/icons-react";
import { MIME_TYPES } from "@mantine/dropzone";
import { IMAGE_URL_CONSTANTS } from "../../../../utils/constants/constant";
import { useParams } from "react-router-dom";
import { dataSlice } from "../../../../utils/common/common";
import { modals } from "@mantine/modals";

function ProductCertificates() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    confirmDrawer: false,
    pdfDrawer: false,
  });
  const params = useParams();
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [certificate, setCertificate] = useState([]);
  const [defaultValue, setDefaultValue] = useState("1");
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const [updatedAt, setUpdatedAt] = useState();
  const [available, setAvailable] = useState(true);

  // For form validation
  const form = useForm({
    initialValues: {
      productId: params.id,
      regionId: "",
      type: "",
      certificateId: "1",
      file: "",
    },
  });

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      productId: params.id,
      regionId: "",
      type: "",
      certificateId: "",
      file: "",
    },
  });

  // For form validation
  const formDuplicate = useForm({
    initialValues: {
      productId: params.id,
      regionIdFrom: "",
      regionIdTo: "",
    },
  });

  // Fetching data from brands table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
        });
        const response = await handleGetOneProductCertificate(params.id);
        // On Response setting the data to variable
        if (response.status === 200) {
          if (
            response.data.data !== "" &&
            response.data.data !== null &&
            typeof response.data.data !== "undefined"
          ) {
            setVariables({
              ...variables,
              submitLoading: false,
            });
            // Assign documents here
            if (response.data.data.length > 0) {
              setCount(response.data.data.length);
              setUpdatedAt(response.data.data[0].updatedAt);
              setVariables({
                ...variables,
                data: response.data.data.reverse(),
                submitLoading: false,
              });
              const datas = dataSlice({
                data: response.data.data,
                page: 1,
                total: 10,
              });
              setData(datas);
              formEdit.setFieldValue("regionId", 1);
            }
          } else {
            setVariables({
              ...variables,
              submitLoading: false,
            });
          }
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }

        const response2 = await handleGetCertificates();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setCertificate(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const [filename, setFilename] = useState("");
  const [fileArray, setFileArray] = useState([]);
  const ref = useRef();

  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        form.setFieldValue("file", reader.result)
      );
      reader.addEventListener("load", () =>
        formEdit.setFieldValue("file", reader.result)
      );
      reader.readAsDataURL(e);
      setFilename(e.name);
      ref.current.value = "";
    }
  };

  const fields = data.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.type}</td>
      <td>{row.certificate.label}</td>
      <td>
        <Anchor target="_blank" href={row.path}>
          {row.file}
        </Anchor>
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle add product certificate function
  const handleCreateProductCertificate = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      filename: filename,
    };
    e = { ...e, ...req };
    const response = await handleAddProductCertificate(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product certificate created successfully",
      });
      setCount(1);
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle edit product certificate function
  const handleUpdateProductCertificate = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    for (let i = 0; i < fileArray.length; i++) {
      e.productCertificate[i].file = fileArray[i];
    }
    const req = {
      filename: filename,
    };
    e = { ...e, ...req };
    setVariables({ ...variables, submitLoading: true, skeletonLoading: true });
    const response = await handleEditProductCertificate(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product certificate updated successfully",
      });
      formEdit.reset();
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
        submitLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle search product certificate by regionId & productId function
  const handleFetchRegionProductCertificate = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      regionId: e,
      productId: params.id,
    };
    setDefaultValue(e);
    formEdit.setFieldValue("regionId", e);
    const response = await handleSearchProductCertificate(req);
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        setCount(response.data.data.length);
        setUpdatedAt(response.data.data[0].updatedAt);
        setVariables({
          ...variables,
          data: response.data.data.reverse(),
          skeletonLoading: false,
        });
        const datas = dataSlice({
          data: response.data.data,
          page: 1,
          total: 10,
        });
        setData(datas);
      } else {
        setVariables({
          ...variables,
          data: response.data.data.reverse(),
          skeletonLoading: false,
        });
        const datas = dataSlice({
          data: response.data.data,
          page: 1,
          total: 10,
        });
        setData(datas);
      }
    }
  };

  const handleChangeRadio = (e) => {
    formDuplicate.setFieldValue("regionIdTo", e);
    formDuplicate.setFieldValue("regionIdFrom", defaultValue);
  };

  // Handle create duplicate product certificate function
  const handleConfirmDuplicateProductCertificate = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleDuplicateProductCertificate(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product certificate duplicated successfully",
      });
      setCount(1);
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Handle pop delete confirmation modal
  const openConfirmModalDelete = (e) => {
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">Are you sure you want to delete the certificate ?</Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle delete function
  const handleConfirmDelete = async (e) => {
    setVariables({ ...variables, submitLoading: true, skeletonLoading: true });
    const req = {
      regionId: defaultValue,
      productId: params.id,
      rowId: e,
    };
    const response = await handleDeleteProductCertificate(req);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product certificate deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        skeletonLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <>
      <Skeleton radius="md" mt={10} visible={variables.skeletonLoading}>
        <Card padding="sm" radius="md">
          {count > 0 ? (
            <Tabs defaultValue={defaultValue} mb={10}>
              <Tabs.List position="apart">
                {region.map((item, index) => (
                  <Tabs.Tab
                    key={index}
                    value={item.value}
                    icon={
                      <Image
                        alt="Image"
                        width={20}
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                      />
                    }
                    onClick={() =>
                      handleFetchRegionProductCertificate(item.value)
                    }
                  >
                    {item.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>

              <Tabs.Panel value={defaultValue} pt="xs">
                <div style={{ padding: "10px" }}>
                  <form
                    onSubmit={formEdit.onSubmit((values) =>
                      handleUpdateProductCertificate(values)
                    )}
                  >
                    <Grid>
                      <Grid.Col md={12} lg={12}>
                        <Title order={5}>Product Certificate</Title>
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Select Type"
                          placeholder="Select Type"
                          searchable
                          data={[
                            "Manufacturing Facility",
                            "Medicinal Ingredients",
                            "Non Medicinal Ingredients",
                          ]}
                          value={formEdit.values.type}
                          {...formEdit.getInputProps("type")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <Select
                          label="Select Certificate"
                          placeholder="Select Certificate"
                          searchable
                          data={certificate}
                          value={formEdit.values.certificateId}
                          {...formEdit.getInputProps("certificateId")}
                        />
                      </Grid.Col>
                      <Grid.Col md={4} lg={4}>
                        <FileInput
                          ref={ref}
                          label="Certificate PDF"
                          placeholder="Certificate PDF"
                          accept={[MIME_TYPES.pdf]}
                          icon={<IconPhoto size={rem(14)} />}
                          onChange={changeHandler}
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={12}>
                        <Group position="right">
                          <Button
                            radius="sm"
                            type="submit"
                            size="sm"
                            color="#A61E4D"
                          >
                            Add More Product Certificate
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </form>
                  <Grid mt={10}>
                    <Grid.Col md={12} lg={12}>
                      <ScrollArea scrollbarSize={10}>
                        <>
                          <Card withBorder>
                            <Table
                              striped
                              highlightOnHover
                              verticalSpacing="sm"
                            >
                              {/* Table header defines */}
                              <thead>
                                <tr>
                                  <th>Sl.No</th>
                                  <th>Type</th>
                                  <th>Certificate</th>
                                  <th>Certificate PDF</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              {fields.length > 0 ? (
                                <tbody>{fields}</tbody>
                              ) : (
                                <>
                                  {variables.submitLoading === true ? (
                                    <tbody>
                                      <tr>
                                        <td colSpan={8}>
                                          <div style={{ textAlign: "center" }}>
                                            <Loader
                                              size="md"
                                              color="#A61E4D"
                                              variant="dots"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td colSpan={8}>
                                          <div style={{ textAlign: "center" }}>
                                            <Text weight={500} align="center">
                                              No data available!
                                            </Text>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              )}
                            </Table>
                          </Card>
                        </>
                      </ScrollArea>
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="left">
                        <Badge color="#FCC2D7">
                          Last updated on:&nbsp;
                          {new Date(updatedAt).toLocaleString("en-UK")}
                        </Badge>
                      </Group>
                      <Group position="right">
                        <Button
                          loading={variables.skeletonLoading}
                          disabled={variables.skeletonLoading}
                          radius="sm"
                          size="sm"
                          color="#A61E4D"
                          onClick={() =>
                            setVariables({
                              ...variables,
                              confirmDrawer: true,
                            })
                          }
                        >
                          Duplicate
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </div>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <>
              <div style={{ padding: "10px" }}>
                <form
                  onSubmit={form.onSubmit((values) =>
                    handleCreateProductCertificate(values)
                  )}
                >
                  <Grid>
                    <Grid.Col md={12} lg={12}>
                      <Title order={5}>Product Certificate</Title>
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <Select
                        label="Select Type"
                        placeholder="Select Type"
                        searchable
                        data={[
                          "Manufacturing Facility",
                          "Medicinal Ingredients",
                          "Non Medicinal Ingredients",
                        ]}
                        value={form.values.type}
                        {...form.getInputProps("type")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <Select
                        label="Select Certificate"
                        placeholder="Select Certificate"
                        searchable
                        data={certificate}
                        value={form.values.certificate}
                        {...form.getInputProps("certificate")}
                      />
                    </Grid.Col>
                    <Grid.Col md={4} lg={4}>
                      <FileInput
                        ref={ref}
                        label="Certificate PDF"
                        placeholder="Certificate PDF"
                        accept={[MIME_TYPES.pdf]}
                        icon={<IconPhoto size={rem(14)} />}
                        onChange={changeHandler}
                      />
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <Group position="right">
                        <Button
                          radius="sm"
                          type="submit"
                          size="sm"
                          color="#A61E4D"
                        >
                          Add Product Certificate
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </form>
                <Grid mt={10}>
                  <Grid.Col md={12} lg={12}>
                    <ScrollArea scrollbarSize={10}>
                      <>
                        <Card withBorder>
                          <Table striped highlightOnHover verticalSpacing="sm">
                            {/* Table header defines */}
                            <thead>
                              <tr>
                                <th>Sl.No</th>
                                <th>Type</th>
                                <th>Certificate</th>
                                <th>Certificate PDF</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            {fields.length > 0 ? (
                              <tbody>{fields}</tbody>
                            ) : (
                              <>
                                {variables.submitLoading === true ? (
                                  <tbody>
                                    <tr>
                                      <td colSpan={8}>
                                        <div style={{ textAlign: "center" }}>
                                          <Loader
                                            size="md"
                                            color="#A61E4D"
                                            variant="dots"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td colSpan={8}>
                                        <div style={{ textAlign: "center" }}>
                                          <Text weight={500} align="center">
                                            No data available!
                                          </Text>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </>
                            )}
                          </Table>
                        </Card>
                      </>
                    </ScrollArea>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          )}
        </Card>
      </Skeleton>

      <Modal
        opened={variables.confirmDrawer}
        onClose={() => setVariables({ ...variables, confirmDrawer: false })}
        title={"Duplicate Product Certificate"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formDuplicate.onSubmit((values) =>
                handleConfirmDuplicateProductCertificate(values)
              )}
            >
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Text size="sm">
                    This action is so important that you are required to confirm
                    it with a modal. Please click one of these buttons to
                    proceed.
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Radio.Group withAsterisk onChange={handleChangeRadio}>
                    <Group mt="md">
                      {region.map((item, index) => (
                        <>
                          {item.value !== defaultValue ? (
                            <Radio value={item.value} label={item.label} />
                          ) : null}
                        </>
                      ))}
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Group position="right">
                    <Button
                      loading={variables.skeletonLoading}
                      disabled={variables.skeletonLoading}
                      radius="sm"
                      type="submit"
                      size="sm"
                      color="#A61E4D"
                    >
                      Confirm
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        opened={variables.pdfDrawer}
        onClose={() => setVariables({ ...variables, pdfDrawer: false })}
        title={"Certificate PDF"}
        size="lg"
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <Text>Document will be loaded here</Text>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductCertificates;
