/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  FileButton,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  MultiSelect,
  NativeSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useForm } from "@mantine/form";
import {
  handleAddProduct,
  handleDeleteProduct,
  handleGetProduct,
  handleGetBrand,
  handleGetCategory,
  handleGetFlavours,
  handleGetVariant,
  handleGetRegion,
  handleExportAmazonSpecification,
  handleExportWalmartSpecification,
  handleGetWalmartSpecification,
  handleUploadProductFeaturedImage,
} from "../../../utils/helpers/apiFunctions";
import { dataSearch, dataSlice } from "../../../utils/common/common";
import {
  IconEdit,
  IconFileSpreadsheet,
  IconLoader,
  IconPlus,
  IconSearch,
  IconTrash,
  IconPhoto,
  IconWindmillFilled,
} from "@tabler/icons-react";
import notificationHelper from "../../../utils/common/notificationHelper";
import { useModals } from "@mantine/modals";
import {
  IMAGE_URL_CONSTANTS,
  amazonHeaders,
  walmartHeaders,
} from "../../../utils/constants/constant";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

function Products() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    exportDrawer: false,
  });

  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);
  const [flavour, setFlavour] = useState([]);
  const [variant, setVariant] = useState([]);
  const [region, setRegion] = useState([]);
  const [exportHeader, setExportHeader] = useState();
  const [exportData, setExportData] = useState([]);
  const [exportStatus, setExportStatus] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [refreshTable, setRefreshTable] = useState(Date.now());
  const modals = useModals();

  // For form validation
  const form = useForm({
    initialValues: {
      label: "",
      sku: "",
      brandId: "",
      variantId: "",
      flavourId: "",
      categoryId: "",
      regionId: [],
    },
    validate: {
      label: (value) =>
        value.length < 2 ? "Product name must have at least 2 letters" : null,
      sku: (value) =>
        value.length < 2 ? "Product sku must have at least 2 letters" : null,
    },
  });

  // For form validation
  const formExport = useForm({
    initialValues: {
      type: "",
      regionIdTo: "",
    },
  });

  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  // Fetching data from products table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const response = await handleGetProduct();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data,
            skeletonLoading: false,
            submitLoading: false,
          });
          const productArray = response.data.data.reverse();
          const datas = dataSlice({
            data: response.data.data.reverse(),
            page: 1,
            total: 10,
          });
          setLoadingData(false);
          setData(datas);
        }

        const response1 = await handleGetBrand();

        if (response1.status === 200) {
          const data = response1.data.data.reverse();
          const clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setBrand(clean);
        }

        const response2 = await handleGetCategory();

        if (response2.status === 200) {
          const data = response2.data.data.reverse();
          const clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setCategory(clean);
        }

        const response3 = await handleGetFlavours();

        if (response3.status === 200) {
          const data = response3.data.data.reverse();
          const clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setFlavour(clean);
        }

        const response4 = await handleGetVariant();

        if (response4.status === 200) {
          const data = response4.data.data.reverse();
          const clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setVariant(clean);
        }

        const response5 = await handleGetRegion();

        if (response5.status === 200) {
          const data = response5.data.data.reverse();
          const clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data to be displayed in the tbody
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.featuredImage !== "" ? (
            <Avatar
              color="#A61E4D"
              src={IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + row.featuredImage}
              radius={30}
              size={50}
            />
          ) : (
            <Avatar color="#A61E4D" radius={30} size={50} />
          )}
          <div>
            <Text fz="sm" fw={500}>
              <Anchor color="#A61E4D" component="button" size="sm">
                {row.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              Brand: {row.brand.label}
            </Text>
            <Text c="dimmed" fz="xs">
              Category: {row.category.label}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.sku}</td>
      <td>{row.category.label}</td>
      <td>{row.flavour !== null ? row.flavour.label : "--"}</td>
      <td>{row.variant.label}</td>
      <td>--</td>
      <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.id)}>
            <IconPhoto size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            color="gray"
            onClick={() =>
              navigate(`/admin/products/${row.id}/productInformation`)
            }
          >
            <IconEdit size={18} />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.id)}
            color="red"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Handle product add function
  const handleAdd = async (e) => {
    setVariables({
      ...variables,
      submitLoading: true,
    });
    const response = await handleAddProduct(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const [product, setProduct] = useState("Product");
  const [productImage, setProductImage] = useState(null);
  const [value, setValue] = useState();
  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.id === e);
    setProduct(datas.label);
    setProductImage(datas.image);
    setValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // Uploading certificate image with values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: value,
    };
    e = { ...e, ...req };
    const response = await handleUploadProductFeaturedImage(e);
    // Check for response from upload actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product featured image upload successfully",
      });
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, imageDrawer: false });
    }
  };

  // Crop Image
  const ref = useRef();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };

  // Handle product delete confirmation modal
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.id === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the product <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  // Handle product delete function
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProduct(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleConfirmExportExcelData = async (e) => {
    if (e.type === "Amazon") {
      setVariables({ ...variables, submitLoading: true });
      const response = await handleExportAmazonSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        setVariables({ ...variables, submitLoading: false });
        setExportStatus(true);
        setExportHeader(amazonHeaders);
        setExportData(response.data.data);
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    } else {
      setVariables({ ...variables, submitLoading: true });
      const response = await handleExportWalmartSpecification(e);
      // Check for response for actions
      if (response.status === 200) {
        setVariables({ ...variables, submitLoading: false });
        setExportStatus(true);
        setExportHeader(walmartHeaders);
        setExportData(response.data.data);
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
        setVariables({ ...variables, submitLoading: false });
      }
    }
  };

  return (
    <>
      <Skeleton radius="md" visible={false}>
        <BreadCrumbs Text="Products" />
      </Skeleton>
      <Skeleton radius="md" mt={20} visible={variables.skeletonLoading}>
        <Grid>
          <Grid.Col md={12} lg={12}>
            <Card shadow="xs" padding="sm" radius="md">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <TextInput
                  variant="filled"
                  placeholder="Search by any field"
                  mb="md"
                  size="xs"
                  value={search}
                  icon={<IconSearch size={14} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Group spacing="xs">
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    onClick={() =>
                      setVariables({
                        ...variables,
                        exportDrawer: true,
                      })
                    }
                    leftIcon={<IconWindmillFilled size={12} />}
                  >
                    Generate Export
                  </Button>
                  {/* <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    leftIcon={<IconWindmillFilled size={12} />}
                  >
                    <CSVLink
                      data={variables.data}
                      headers={walmartHeaders}
                      filename="products.csv"
                    >
                      <Text>Data Export</Text>
                    </CSVLink>
                  </Button> */}
                  <Button
                    variant="outline"
                    size="xs"
                    mr={10}
                    onClick={() =>
                      setVariables({
                        ...variables,
                        addDrawer: true,
                      })
                    }
                    leftIcon={<IconPlus size={12} />}
                  >
                    Add Product
                  </Button>
                </Group>
              </div>
              <ScrollArea>
                <Table striped highlightOnHover verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Product</th>
                      <th>SKU</th>
                      <th>Category</th>
                      <th>Flavour</th>
                      <th>Variant</th>
                      <th>Created By</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === false ? (
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#A61E4D"
                                  variant="dots"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  No data available!
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </ScrollArea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 15,
                }}
              >
                {/* For number of rows display in table */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text size="sm" className="zc-pr-3 zc-pt-1">
                    Rows per page
                  </Text>
                  <NativeSelect
                    ml={5}
                    size="xs"
                    onChange={async (e) => {
                      setTotal(Number(e.currentTarget.value));
                      setPage(1);
                      const datas = await dataSlice({
                        data: variables.data,
                        page: 1,
                        total: Number(e.currentTarget.value),
                      });
                      // setSortedData(datas);
                      setRefreshTable(new Date());
                    }}
                    data={["10", "20", "50", "100"]}
                    rightSectionWidth={20}
                    sx={{ width: 70 }}
                  />
                </div>
                {variables.data.length > 0 ? (
                  <>
                    <Badge color="#FCC2D7" ml={5} mt={3}>
                      Last updated on:&nbsp;
                      {new Date(
                        variables.data.reduce(function (r, a) {
                          return r.updatedAt > a.updatedAt ? r : a;
                        }).updatedAt
                      ).toLocaleString("en-UK")}
                    </Badge>
                  </>
                ) : null}
                {/* For pagination */}
                <Pagination
                  size="xs"
                  page={activePage}
                  onChange={async (e) => {
                    setPage(Number(e));
                    const datas = await dataSlice({
                      data: variables.data,
                      page: Number(e),
                      total: total,
                    });
                    setData(datas);
                    setRefreshTable(new Date());
                  }}
                  total={Math.ceil(variables.data.length / total)}
                  color="zevcore"
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid>
      </Skeleton>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        size="65%"
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Product"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Product Name"
                    withAsterisk
                    value={form.values.label}
                    placeholder="Product Name"
                    {...form.getInputProps("label")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Product SKU"
                    withAsterisk
                    value={form.values.sku}
                    placeholder="Product SKU"
                    {...form.getInputProps("sku")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Brand"
                    placeholder="Select Brand"
                    searchable
                    data={brand}
                    value={form.values.brandId}
                    {...form.getInputProps("brandId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Category"
                    placeholder="Select Category"
                    searchable
                    data={category}
                    value={form.values.categoryId}
                    {...form.getInputProps("categoryId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Flavour"
                    placeholder="Select Flavour"
                    searchable
                    data={flavour}
                    value={form.values.flavourId}
                    {...form.getInputProps("flavourId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Variant"
                    placeholder="Select Variant"
                    searchable
                    data={variant}
                    value={form.values.variantId}
                    {...form.getInputProps("variantId")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <MultiSelect
                    label="Select Regions"
                    placeholder="Select Regions"
                    searchable
                    data={region}
                    value={form.values.regionId}
                    {...form.getInputProps("regionId")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="sm"
                    type="submit"
                    size="sm"
                    color="#A61E4D"
                  >
                    Add Product
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={product}
        overlayProps={{
          color: "grey",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image croper */}
              {/* For croper */}

              <div
                style={{
                  marginTop: 15,
                  position: "relative",
                  marginBottom: 30,
                }}
              >
                <Paper
                  p="lg"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
                  })}
                >
                  {!image ? (
                    <Avatar
                      src={IMAGE_URL_CONSTANTS.PRODUCT_IMAGE + productImage}
                      size={150}
                      radius={100}
                      mx="auto"
                    />
                  ) : (
                    <Avatar src={image} size={150} radius={150} mx="auto" />
                  )}

                  <Text ta="center" fz="lg" weight={500} mt="md">
                    {product}
                  </Text>

                  <Group position="center">
                    <FileButton
                      resetRef={ref}
                      onChange={changeHandler}
                      accept={[
                        "image/png",
                        "image/jpeg",
                        "image/sgv+xml",
                        "image/gif",
                      ]}
                    >
                      {(props) => (
                        <Button variant="outline" mt={20} {...props}>
                          Select Image
                        </Button>
                      )}
                    </FileButton>
                  </Group>
                </Paper>
              </div>
            </Grid.Col>
            <Button
              loading={variables.submitLoading}
              fullWidth
              type="submit"
              size="md"
              variant="filled"
            >
              Upload Product Featured Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}

      {/* Export Drawer */}
      <Modal
        opened={variables.exportDrawer}
        size="md"
        onClose={() => setVariables({ ...variables, exportDrawer: false })}
        title={"Please confirm your action"}
        overlayProps={{
          color: "darkgray",
        }}
      >
        <div className="zvcr-scroll">
          {exportStatus === false ? (
            <div style={{ padding: "10px" }}>
              <form
                onSubmit={formExport.onSubmit((values) =>
                  handleConfirmExportExcelData(values)
                )}
              >
                <Grid>
                  <Grid.Col md={12} lg={12}>
                    <Select
                      label="Select Platform"
                      value={formExport.values.type}
                      searchable
                      clearable
                      data={["Amazon", "Walmart"]}
                      placeholder="Select Platform"
                      {...formExport.getInputProps("type")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Select
                      label="Select Region"
                      value={formExport.values.regionIdTo}
                      searchable
                      clearable
                      data={region}
                      placeholder="Select Region"
                      {...formExport.getInputProps("regionIdTo")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Group position="right">
                      <Button radius="sm" type="submit" size="sm" color="dark">
                        Cancel
                      </Button>
                      <Button
                        loading={variables.submitLoading}
                        radius="sm"
                        type="submit"
                        size="sm"
                        color="#A61E4D"
                      >
                        Generate Export
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
          ) : (
            <Group position="center" my={20}>
              <CSVLink
                data={exportData}
                headers={walmartHeaders}
                filename="products.csv"
              >
                <Text align="center" my={5}>
                  <IconLoader size={50} color="#A61E4D" />
                </Text>
                <Text align="center" my={5}>
                  Please wait excel file generating...
                </Text>
                <Text align="center" my={5}>
                  <Button
                    variant="outline"
                    size="xs"
                    leftIcon={<IconFileSpreadsheet size={16} />}
                  >
                    Download
                  </Button>
                </Text>
              </CSVLink>
            </Group>
          )}
        </div>
      </Modal>
      {/* Export Drawer */}
    </>
  );
}

export default Products;
