/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/
import React from "react";
import {
  IconBrandPagekit,
  IconCategory,
  IconCategory2,
  IconCertificate2,
  IconUser,
  IconForms,
  IconCone,
  IconUsers,
  IconMap,
  IconUserCircle,
  IconHammer,
  IconPills,
  IconArrowForwardUpDouble,
  IconPrescription,
} from "@tabler/icons-react";
import { Box, NavLink, Paper } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

const data = [
  {
    icon: IconBrandPagekit,
    label: "Brands",
    link: "/admin/settings/brands",
  },
  {
    icon: IconCategory2,
    label: "Category Types",
    link: "/admin/settings/categoryType",
  },
  {
    icon: IconCategory,
    label: "Category",
    link: "/admin/settings/category",
  },
  {
    icon: IconCertificate2,
    label: "Certificates",
    link: "/admin/settings/certificates",
  },
  {
    icon: IconForms,
    label: "Delivery Format",
    link: "/admin/settings/deliveryFormats",
  },
  {
    icon: IconUser,
    label: "Distributors",
    link: "/admin/settings/distributors",
  },
  {
    icon: IconPrescription,
    label: "Dosage Form",
    link: "/admin/settings/dosageForm",
  },
  {
    icon: IconCone,
    label: "Flavour",
    link: "/admin/settings/flavours",
  },
  {
    icon: IconUsers,
    label: "Marketed By",
    link: "/admin/settings/marketedBy",
  },
  {
    icon: IconUsers,
    label: "Manufacturer",
    link: "/admin/settings/manufacturers",
  },
  {
    icon: IconPills,
    label: "Product Types",
    link: "/admin/settings/productType",
  },
  {
    icon: IconMap,
    label: "Region",
    link: "/admin/settings/region",
  },
  {
    icon: IconHammer,
    label: "Regulatory Body",
    link: "/admin/settings/regulatory",
  },
  {
    icon: IconUserCircle,
    label: "Retailers",
    link: "/admin/settings/retailers",
  },
  {
    icon: IconArrowForwardUpDouble,
    label: "Variant",
    link: "/admin/settings/variant",
  },
];

export default function SettingLinks() {
  let navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  // Handle click if e empty or undefined it wont do any thing
  const handleClick = (e) => {
    if (typeof e != "undefined") {
      navigate(e.link.toString());
    }
  };

  const items = data.map((item, index) => (
    <NavLink
      key={index}
      active
      variant={pathname === item.link ? "light" : "filled"}
      label={item.label}
      description={item.description}
      rightSection={item.rightSection}
      icon={<item.icon size="1rem" stroke={1.5} />}
      onClick={() => handleClick(item)}
    />
  ));

  return (
    <Paper shadow="xs" radius="sm" p={1}>
      <Box>{items}</Box>
    </Paper>
  );
}
