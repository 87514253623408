/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import { useEffect, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Group,
  Image,
  Modal,
  Paper,
  Skeleton,
  Text,
} from "@mantine/core";
import {
  handleGetAllProductWebsite,
  handleGetRegion,
} from "../../utils/helpers/apiFunctions";
import { IMAGE_URL_CONSTANTS } from "../../utils/constants/constant";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import HeaderResponsive from "../../components/mainComponents/HeaderResponsive";
import FooterSocial from "../../components/mainComponents/FooterSocial";

export default function Products() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    regionModal: false,
    selectedProduct: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const [region, setRegion] = useState([]);

  // Fetching data from products table
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        setVariables({
          ...variables,
          submitLoading: true,
          skeletonLoading: true,
        });
        const response = await handleGetAllProductWebsite();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            // data: response.data.data.reverse(),
            skeletonLoading: false,
            submitLoading: false,
            data: response.data.data,
          });
        }

        const response1 = await handleGetRegion();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.id.toString(),
            label: data.label.toString(),
            image: data.image,
          }));
          setRegion(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleProductDetails = (e) => {
    const sku = e.sku;
    const regionId = params.regionId;
    if (typeof e != "undefined") {
      navigate(`/${regionId}/product/${sku}`);
    }
  };

  return (
    <>
      {/* Header starts */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
        }}
      >
        <HeaderResponsive />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Skeleton visible={variables.skeletonLoading}>
          {/* Banner Section */}
          <Container
            fluid
            style={{
              height: "auto",
            }}
          >
            <Grid mt={40}>
              <Grid.Col md={2} lg={2}></Grid.Col>
              <Grid.Col md={8} lg={8}>
                <Paper withBorder shadow="xs" radius="sm" p={20}>
                  <Text align="left" color="#545454" fw={700} size={22}>
                    Products
                  </Text>
                  <Text color="#545454" size={14}>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using &apos;Content here, content here&apos;, making it look
                    like readable English. Many desktop publishing packages and
                    web page editors now use Lorem Ipsum as their default model
                    text, and a search for &apos;lorem ipsum&apos; will uncover
                    many web sites still in their infancy.
                  </Text>
                </Paper>
              </Grid.Col>
              <Grid.Col md={2} lg={2}></Grid.Col>
            </Grid>
          </Container>
          {/* Banner Section */}
          {/* Second Section */}
          <Container
            fluid
            style={{
              height: "auto",
            }}
          >
            <Grid my={20}>
              <Grid.Col md={2} lg={2}></Grid.Col>
              <Grid.Col md={8} lg={8}>
                <Grid>
                  {variables.data.map((item, index) => (
                    <Grid.Col key={index} md={3} lg={3}>
                      <Card shadow="sm" radius="md">
                        <Group position="center">
                          <Image
                            src={
                              IMAGE_URL_CONSTANTS.PRODUCT_IMAGE +
                              item.featuredImage
                            }
                            width={200}
                            height={200}
                            alt="Product image"
                          />
                        </Group>
                        <Group position="center">
                          <Text color="#545454">{item.label}</Text>
                        </Group>
                        <Group position="center">
                          <Button
                            style={{ backgroundColor: "#A61E4D" }}
                            type="submit"
                            mt="sm"
                            size="xs"
                            onClick={() => handleProductDetails(item)}
                            // onClick={() =>
                            //   setVariables({
                            //     ...variables,
                            //     regionModal: true,
                            //     selectedProduct: item,
                            //   })
                            // }
                          >
                            More Details
                          </Button>
                        </Group>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </Grid.Col>
              <Grid.Col md={2} lg="2"></Grid.Col>
            </Grid>
          </Container>
          {/* Second Section */}
        </Skeleton>
        <Modal
          opened={variables.regionModal}
          onClose={() => setVariables({ ...variables, regionModal: false })}
          fullScreen
          centered
          transitionProps={{ transition: "slide-right", duration: 600 }}
        >
          <Container
            style={{
              width: "50%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid justify="center" align="center">
              <Grid.Col md={12} lg={12}>
                <Text fw={700} ta="center">
                  Please select the region for view {""}
                  {variables.selectedProduct.label}
                </Text>
              </Grid.Col>
              {region.map((item, index) => (
                <Grid.Col key={index} md={4} lg={4}>
                  <div
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      handleProductDetails(item.value);
                    }}
                  >
                    <Box withBorder>
                      <Image
                        maw={200}
                        mx="auto"
                        radius="md"
                        src={IMAGE_URL_CONSTANTS.REGION_IMAGE + item.image}
                        alt="Random image"
                      />
                      <Anchor color="zevcore" align="center">
                        {item.label}
                      </Anchor>
                    </Box>
                  </div>
                </Grid.Col>
              ))}
            </Grid>
          </Container>
        </Modal>
      </div>
      <FooterSocial />
    </>
  );
}
