/**

    * Application Name: Activira Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 08.09.2023
    * Last Modified Date: 08.09.2023
    * Developer Name: Anand_Krishna

**/

import { Image } from "@mantine/core";
import { openModal } from "@mantine/modals";
import React from "react";
// For slice the data acording to the pagination and perpage drop down change
export const dataSlice = ({ data, page, total }) => {
  const datas = data.slice((page - 1) * total, (page - 1) * total + total);
  return datas;
};

// For slice the data according to the pagination and per page drop down change
const filterData = (data, search) => {
  const keys = Object.keys(data[0]);
  const query = search.toString().toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => {
      if (key !== "id") {
        if (item[key] !== null && item[key] !== "") {
          return item[key].toString().toLowerCase().includes(query);
        }
      }
      return null;
    })
  );
};

const sortData = (data, payload) => {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }
  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[payload.sortBy].localeCompare(a[payload.sortBy]);
      }
      return a[payload.sortBy].localeCompare(b[payload.sortBy]);
    }),
    payload.search
  );
};

export const dataSearch = ({ data, value, activePage, total }) => {
  var datas2 = sortData(data, {
    sortBy: null,
    reversed: false,
    search: value,
  });
  const datas = datas2.slice(
    (activePage - 1) * total,
    (activePage - 1) * total + total
  );
  return datas;
};

export const imageModal = async ({ data, title, size }) => {
  if (typeof size !== "undefined" && size !== null && size !== "") {
    return openModal({
      title: title,
      size: size,
      children: (
        <>
          <Image src={data} alt="" width="100%" />
        </>
      ),
    });
  } else {
    return openModal({
      title: title,
      size: false,
      children: (
        <>
          <Image src={data} alt="" width="100%" />
        </>
      ),
    });
  }
};
